import { Security_FindPeers } from '@rabbit/bizproc/client';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import {
  DTRepairer_Public,
  FBD_Repairer_Public,
  Permissions,
} from '@rabbit/data/types';
import { useEffect, useState } from 'react';

export interface OptionShape {
  id: string;
  value: string;
  label: string;
}

export function useGetRepairerPeers() {
  const { repairerPersona, warrantorPersona } = useGetMySagePersonas() || {};
  const [repairerPeerLoading, setRepairerPeerLoading] = useState(true);
  const [repairerPeer, setRepairerPeer] = useState<DTRepairer_Public[]>([]);

  useEffect(() => {
    const filterPeers = async (idArr: string[]) => {
      //Kick this off with the user's own id
      const promises: Promise<DTRepairer_Public | null>[] = [
        FBD_Repairer_Public.get(repairerPersona?.personaId ?? ''),
      ];
      idArr.forEach((id) => {
        promises.push(FBD_Repairer_Public.get(id));
      });

      const peerData = await Promise.all(promises);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const environment = import.meta.env.VITE_FIREBASE_MODE;
      const result =
        environment === 'LIVE'
          ? peerData.map((item) => {
              // If email ends in @iwarranty.co or @studiographene.com, don't include it
              if (
                item?.email &&
                !item?.email?.includes('@iwarranty.co') &&
                !item?.email?.includes('@studiographene.com')
              ) {
                return item;
              } else return null;
            })
          : peerData;

      return result;
    };
    const fetchPeers = async () => {
      let ids: string[] = [];

      // Determine if both repairerPersona and warrantorPersona exist
      const hasBothPersonas =
        repairerPersona?.personaId &&
        repairerPersona.repairer_private?.principals?.[0] &&
        warrantorPersona?.personaId &&
        warrantorPersona.warrantor_private?.principals?.[0];

      if (hasBothPersonas && repairerPersona?.repairer_private?.delegates) {
        // If both personas exist, fetch IDs from delegates who have 'cases_edit' permission
        const delegateIds = Object.keys(
          repairerPersona.repairer_private.delegates
        );
        ids = delegateIds.filter((id) =>
          repairerPersona.repairer_private?.delegates[id]?.includes(
            Permissions.CasesEdit
          )
        );
      } else if (repairerPersona?.personaId) {
        // If only repairerPersona exists, fetch peers using Security_FindPeers
        const peers = await Security_FindPeers(repairerPersona.personaId, [
          Permissions.CasesEdit,
        ]);
        // Include the main repairerPersona ID and remove duplicates
        ids = [...new Set([repairerPersona.personaId, ...peers])];
      }

      // Fetch peer data based on collected IDs and filter results
      const filteredPeers = await filterPeers(ids);
      setRepairerPeer(
        filteredPeers.filter((item) => item !== null) as DTRepairer_Public[]
      );
      setRepairerPeerLoading(false);
    };

    // Trigger fetchPeers if repairerPersona exists
    if (repairerPersona?.personaId) {
      fetchPeers().catch(console.error);
    } else {
      setRepairerPeerLoading(false);
    }
  }, [
    repairerPersona?.repairer_private?.principals?.[0],
    repairerPersona?.personaId,
    warrantorPersona?.personaId,
  ]);

  return { repairerPeer, repairerPeerLoading };
}
