import {
  formatAddress,
  formatPhoneNumber,
  formatUnixTime,
} from '@rabbit/elements/shared-components';
import {
  Address,
  DTConsumer_Private,
  DTHoldingProxy,
  SELF_REGISTERED_VENDABLE,
} from '@rabbit/data/types';
import { calculateTotalSpent } from '@rabbit/sage/utils/helpers';
import { useTranslation } from 'react-i18next';
import {
  getFirstName,
  getLastName,
} from 'libs/bizproc/react/src/shared/helpers';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerDetailsContentSectionProps {
  consumer_private: DTConsumer_Private;
  holdingsList: DTHoldingProxy[];
  favouriteStore?: string;
}

export function CustomerDetailsContentSection(
  props: CustomerDetailsContentSectionProps
) {
  const { t } = useTranslation();
  const { consumer_private, holdingsList, favouriteStore } = props || {};

  const firstName =
    consumer_private.splitname?.first ??
    (consumer_private.fullname && consumer_private.fullname.indexOf('@') === -1)
      ? getFirstName(consumer_private.fullname)
      : '';

  const lastName =
    consumer_private.splitname?.last ??
    (consumer_private.fullname && consumer_private.fullname.indexOf('@') === -1)
      ? getLastName(consumer_private.fullname)
      : '';

  //if (!consumer_private || isLoading) return <LoadingSpinner size="xs" />;

  const mostRecentPurchaseDate = getMostRecentPurchaseDate(holdingsList);
  const carTenants =
    t('tenantLink') === 'WARRANTYIRELAND' ||
    t('tenantLink') === 'PINNACLEWARRANTIES' ||
    t('tenantLink') === 'NUCOVER';

  const totalSpent = carTenants ? 0 : calculateTotalSpent(holdingsList);
  const preferredMethodOfCommunication = !consumer_private
    ?.preferred_contact?.[0]
    ? '-'
    : consumer_private?.preferred_contact.join(', ');

  const numberOfPurchases = holdingsList
    ?.map((holding: DTHoldingProxy) => holding?.vendable)
    .filter((holding) => holding !== SELF_REGISTERED_VENDABLE).length;

  return (
    <div className="font-nunito mb-[30px] grid grid-cols-5 gap-8">
      {consumer_private && (
        <>
          {renderData('First name', `${firstName}`)}
          {renderData('Last name', `${lastName}`)}
          {renderData('Email', consumer_private.email)}
          {renderData(
            'Phone number',
            formatPhoneNumber(consumer_private.phone)
          )}
          {renderAddressData('Address', consumer_private.address as Address[])}
          {!carTenants && (
            <>
              {renderData('Number of purchases', numberOfPurchases)}
              {renderData('Favourite store', favouriteStore)}
              {renderData('Most recent purchase date', mostRecentPurchaseDate)}
              {renderData(
                'Preferred method of communication',
                preferredMethodOfCommunication
              )}
              {renderData('Total spent', totalSpent)}
              {renderData(
                'Commercial user',
                consumer_private.isCommercialUser ? 'Yes' : 'No'
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default CustomerDetailsContentSection;

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

/* ----------------------------- Render helpers ----------------------------- */

const renderData = (label: string, value: any) => {
  let renderValue = value;
  if (value === 'undefined') renderValue = '-';
  return (
    <div>
      <div className="mb-[10px] text-sm text-gray-500">{label}</div>
      <div
        className="overflow-hidden text-ellipsis font-medium"
        title={renderValue ?? '-'}
      >
        {renderValue ?? '-'}{' '}
      </div>
    </div>
  );
};

const renderAddressData = (label: string, value: Address[]) => {
  const address =
    value?.length > 1
      ? value?.filter((i) => i.isDefault).length > 0
        ? value?.filter((i) => i.isDefault)
        : value
      : value;
  return (
    <div>
      <div className="mb-[10px] text-sm text-gray-500">{label}</div>
      <div className="font-medium">
        {address?.[0] ? formatAddress(address[0]) : '-'}
      </div>
    </div>
  );
};

const getMostRecentPurchaseDate = (holdingsList: DTHoldingProxy[]) => {
  let mostRecentDate = 0;
  holdingsList.forEach((holding: DTHoldingProxy) => {
    if (holding?.purchase_time > mostRecentDate)
      mostRecentDate = holding?.purchase_time;
  });
  if (mostRecentDate <= 0) return '-';
  return formatUnixTime(mostRecentDate, 'dd/MM/yyyy');
};
