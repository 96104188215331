import { ReactCaseFlowCase } from '@rabbit/bizproc/react';
import { MessageWithLabelAndTag } from '@rabbit/elements/shared-components';
import {
  GEK_FactUpdate,
  Gazette,
  GazetteEntry,
  Address,
  GazetteEmailParams,
  AppInfoShape,
} from '@rabbit/data/types';
import classNames from 'classnames';
import { fromUnixTime, formatRelative, Locale } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import Skeleton from 'react-loading-skeleton';

export type TimelineUpdateShape = {
  a: string;
  k: number;
  link: string;
  who: string;
  t: number;
  newState?: string;
  oldState?: string;
  kv?: any; //TODO
} & GazetteEmailParams;

export interface TimelineUpdatesProps {
  updates: Gazette; //TimelineUpdateShape[];
  customer?: boolean;
  appInfo?: AppInfoShape;
  state: 'InternalCommentsOnly' | 'EmailCorrespondence' | 'All';
  logPostageAction?: () => void;
  caseFlowCase?: ReactCaseFlowCase;
  operatingPersona?: string;
}

const dynamicStyles = {
  base: 'overflow-y-scroll max-h-[640px] rounded-lg py-4  lg:px-4 lg:py-8',
  noBorderOnTop: 'border-0',
  border: 'lg:border-1 lg:border lg:border-gray-200',
};

export function TimelineUpdates({
  updates,
  customer = false,
  appInfo = {} as AppInfoShape,
  state = 'All',
  logPostageAction,
  caseFlowCase,
  operatingPersona,
}: TimelineUpdatesProps) {
  if (!updates) return <Skeleton count={1} />;

  // Caseflow data/functions
  const caseFacts = caseFlowCase?.GetAllFacts();
  const caseActionsStation = caseFlowCase?.GetPossibleActionsInStation();

  const executeAction = async (
    action: string,
    params?: { [key: string]: any }
  ) => {
    await caseFlowCase?.Alter_WithAction(action, params);
    await caseFlowCase?.Commit();
  };

  const alterCaseSpotlight = async (opt: { clear: boolean }) => {
    caseFlowCase?.Alter_CaseSpotlight(opt);
    await caseFlowCase?.Commit();
  };

  const alterCaseFacts = async (facts: { [key: string]: any }) => {
    await caseFlowCase?.Alter_Facts(facts);
    await caseFlowCase?.Commit();
  };

  const cfHandlers =
    caseFlowCase && caseFacts
      ? {
          caseFacts,
          caseActionsStation,
          executeAction,
          alterCaseFacts,
          alterCaseSpotlight,
        }
      : undefined;

  // Merge the updates to have same timestamp for same action gazette entries
  const mergedUpdates = updates.reduce((acc: any, currentValue: any) => {
    const date = new Date(currentValue.t); // Convert timestamp to Date object
    date.setSeconds(0); // Set seconds to 0
    date.setMilliseconds(0); // Set milliseconds to 0
    const timestamp = date.getTime(); // Get timestamp

    if (currentValue.template) {
      acc[timestamp + 'email'] = currentValue;
      return acc;
    }

    // if (currentValue?.newState && currentValue?.oldState && !currentValue.kv) {
    //   acc[timestamp + currentValue.newState] = currentValue;
    // } else {
    //   if (!acc[timestamp]) {
    //     acc[timestamp] = { ...currentValue };
    //   } else {
    //     // Merge all properties except kv
    //     for (const key in currentValue) {
    //       if (key !== 'kv') {
    //         acc[timestamp][key] = currentValue[key];
    //       }
    //     }
    //     // Merge kv property
    //     acc[timestamp].kv = { ...acc[timestamp].kv, ...currentValue.kv };
    //   }
    // }

    if (!acc[timestamp]) {
      acc[timestamp] = currentValue;
    } else {
      // Merge all properties except kv
      const { kv, newState, oldState, ...rest } = currentValue;
      acc[timestamp] = {
        ...acc[timestamp],
        ...rest,
        kv: { ...(acc[timestamp].kv || {}), ...(kv || {}) },
        newState: newState || acc[timestamp].newState,
        oldState: oldState || acc[timestamp].oldState,
      };
    }
    return Object.values(acc).sort((a: any, b: any) => a.t - b.t);
  }, {});

  const timelineFilterOptionsToRender = Object.values(mergedUpdates)?.filter(
    (item: any) => {
      if (state === 'InternalCommentsOnly') {
        if (
          item.k <= 4 &&
          (item.kv?.internal_comment ||
            item.kv?.internal_case_files?.length > 0)
        )
          return item;
      }
      if (item.k === 8 && state === 'EmailCorrespondence')
        // 8 == Email correspondense
        return item;

      if (state === 'All') return item;
    }
  ) as GazetteEntry[];

  const getDaysArray = (
    timelineFilterOptionsToRender: GazetteEntry[] | undefined
  ) => {
    const daysMap = new Map();

    timelineFilterOptionsToRender &&
      timelineFilterOptionsToRender.forEach((obj) => {
        let timestamp = obj.t / 1000;
        // NOW! That's what I call a dirty hack!
        if (timestamp < 505572647) {
          timestamp = Date.now() / 1000;
        }
        const date = fromUnixTime(timestamp);
        const day = date.getDate();

        if (!daysMap.has(day)) {
          daysMap.set(day, {
            day: new Date(date.getFullYear(), date.getMonth(), day),
            messages: [],
          });
        }

        daysMap.get(day).messages.push({ ...obj, t: timestamp });
      });
    return Array.from(daysMap.values());
  };

  const messagesPerDays = getDaysArray(timelineFilterOptionsToRender);

  //Keep for debug
  // console.log('GAZETTE', messagesPerDays);

  const formatRelativeLocale: Record<string, string> = {
    lastWeek: "'Last' eeee",
    yesterday: "'Yesterday'",
    today: "'Today'",
    tomorrow: "'Tomorrow'",
    nextWeek: "'Next' eeee",
    other: 'dd.MM.yyyy',
  };

  const locale: Locale = {
    ...enGB,
    formatRelative: (token: string) => formatRelativeLocale[token],
  };

  // TODO: FORMAT FE TEXT ACCORDINGLY WHAT WE NEED TO SHOW
  return (
    <div
      className={classNames(`${dynamicStyles.base}`, {
        [`${dynamicStyles.noBorderOnTop}`]: customer === false,
        [`${dynamicStyles.border}`]: customer === true,
      })}
    >
      {messagesPerDays.reverse().map((day, dayIndex) => {
        //reverse to respect date from new to old
        //concat all messages to be able to show the stored messages -VP
        const concatStoredMessages: TimelineUpdateShape[] = [];
        messagesPerDays.forEach((day) => {
          concatStoredMessages.push(...day.messages);
        });
        const reversedMessages = day.messages?.reverse();
        return (
          <div key={dayIndex}>
            {/* <div className="pb-4">{format(day.day, 'dd/MM/yyyy')}</div> */}
            <div className="pb-4" key={dayIndex + 'date'}>
              {formatRelative(new Date(day.day), new Date(), { locale })}
            </div>
            <div className="pb-2 lg:pb-4" key={dayIndex + 'outer'}>
              {reversedMessages.map(
                (message: TimelineUpdateShape, index: number) => {
                  //reverse to respect date from new to old
                  // todo: getting duplicate key errors here.
                  //Changes made it better but still haven't fixed it completely, but I've already spent too long on this so moving on for now - dc
                  return (
                    <div className="" key={`${message.t}${index}outerdiv`}>
                      <MessageWithLabelAndTag
                        data={message}
                        store={concatStoredMessages}
                        customer={customer}
                        appInfo={appInfo}
                        logPostageAction={logPostageAction}
                        cfHandlers={cfHandlers}
                        operatingPersona={operatingPersona}
                        key={`${message.t}${index}`}
                      />
                    </div>
                  );
                }
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default TimelineUpdates;
