import { useContext } from 'react';
import ClaimActionsType1 from './variations/ClaimActionsType1';
import ClaimActionsType2 from './variations/ClaimActionsType2';
import { ConfigContext } from '@rabbit/config/context';
import { CaseflowContext } from '@rabbit/sage/context/CaseflowContext';
import {
  CardWrapperWithHeader,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { t } from 'i18next';

interface ActionObj {
  [key: string]: string | number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClaimActionsProps {}

export function ClaimActions(props: ClaimActionsProps) {
  const { config } = useContext(ConfigContext);
  const { caseActionsStation, operatingPersona } =
    useContext(CaseflowContext) || {};

  if (!operatingPersona || !caseActionsStation)
    return (
      <CardWrapperWithHeader title={t('Claim actions')}>
        <LoadingSpinner size="xs" />
      </CardWrapperWithHeader>
    );
  /**
   * A temporary (hahah) measure to filter out actions that are not available
   * to the user until this functionality is integrated with Caseflow
   *
   * @param caseActions
   * @param station A string with the name of the station to be filtered
   * @param condition A string with the condition's name. See CASEFLOW_CONDITIONAL_ACTIONS for the available conditions
   */
  const filterAvailableCFCActions = (
    station: string,
    condition: string,
    CASEFLOW_CONDITIONAL_ACTIONS: any
  ) => {
    const selectedActions = CASEFLOW_CONDITIONAL_ACTIONS[station][condition];
    if (caseActionsStation === undefined) return null;
    let filteredActions = Object.keys(caseActionsStation)
      .filter((key) => selectedActions.includes(key))
      .reduce((obj: ActionObj, key: string) => {
        //@ts-ignore
        obj[key] = caseActionsStation[key];
        return obj;
      }, {});
    return filteredActions;
  };

  if (config.CLAIMS.CLAIM_DETAIL_VIEW.CLAIM_ACTIONS.TYPE === 'TYPE1') {
    return (
      <ClaimActionsType1
        filterAvailableCFCActions={filterAvailableCFCActions}
      />
    );
  }

  if (config.CLAIMS.CLAIM_DETAIL_VIEW.CLAIM_ACTIONS.TYPE === 'TYPE2') {
    return (
      <ClaimActionsType2
        filterAvailableCFCActions={filterAvailableCFCActions}
      />
    );
  }
}

export default ClaimActions;
