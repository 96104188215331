import {
  LoadingSpinner,
  Modal,
  TabsGeneric,
} from '@rabbit/elements/shared-components';
import UpdateClaimDetailsForm, {
  ClaimDetailsFormShape,
} from '../forms/UpdateClaimDetailsForm/UpdateClaimDetailsForm';
import { ModalSettingsShape } from '@rabbit/elements/shared-components';
import { CaseflowContext } from '../../../context/CaseflowContext';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileStorageContext } from '@rabbit/bizproc/react';
import ClaimAttachmentsUpload from '../ClaimAttachmentsUpload/ClaimAttachmentsUpload';
import UpdateCustomerDetailsForm from '../forms/UpdateCustomerDetailsForm/UpdateCustomerDetailsForm';
import {
  UpdateClaimDetails_AttachmentsFormType,
  UpdateClaimDetails_ClaimDetailsFormType,
  UpdateClaimDetails_CustomerFormType,
} from '@rabbit/config/base';
import { ConfigContext } from '@rabbit/config/context';

export interface ModalEditClaimDetailsProps {
  modalSettings: ModalSettingsShape;
  claimDetails: any;
  handleClose: () => void;
}

export interface SharedValuesShape {
  claimDetails: ClaimDetailsFormShape;
}

function ModalEditClaimDetails({
  claimDetails,
  handleClose,
}: ModalEditClaimDetailsProps) {
  const { config } = useContext(ConfigContext);
  const { caseFlowCase } = useContext(CaseflowContext) || {};
  const [sharedValues, setSharedValues] = useState<SharedValuesShape>(
    {} as any
  );
  const { unusedCompletedCleanup } = useContext(FileStorageContext) || {};

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      unusedCompletedCleanup?.();
    };
  }, []);

  if (!caseFlowCase) return <LoadingSpinner size="xs" />;

  const renderCustomerDetails = (type: UpdateClaimDetails_CustomerFormType) => {
    if (type === 'NONE') return null;

    if (type === 'DEFAULT')
      return (
        <UpdateCustomerDetailsForm
          claimDetails={claimDetails}
          handleClose={handleClose}
          caseFlowCase={caseFlowCase}
          setLoading={setLoading}
        />
      );
  };

  const renderClaimDetails = (
    type: UpdateClaimDetails_ClaimDetailsFormType
  ) => {
    if (type === 'NONE') return null;

    return (
      <UpdateClaimDetailsForm
        claimDetails={claimDetails}
        handleClose={handleClose}
        caseFlowCase={caseFlowCase}
        setLoading={setLoading}
        sharedValues={sharedValues}
        setSharedValues={setSharedValues}
        type={type}
      />
    );
  };

  const renderAttachements = (type: UpdateClaimDetails_AttachmentsFormType) => {
    if (type === 'NONE') return null;
    if (type === 'DEFAULT')
      return <ClaimAttachmentsUpload handleClose={handleClose} />;
  };

  function generateTabs() {
    const tabs = [];

    if (
      config?.CLAIMS?.CLAIM_DETAIL_VIEW.UPDATE_CLAIM_DETAILS
        .CUSTOMER_DETAILS !== 'NONE'
    ) {
      tabs.push({
        label: t('Customer details'),
        children: renderCustomerDetails(
          config?.CLAIMS?.CLAIM_DETAIL_VIEW.UPDATE_CLAIM_DETAILS
            .CUSTOMER_DETAILS
        ),
      });
    }

    if (
      config?.CLAIMS?.CLAIM_DETAIL_VIEW.UPDATE_CLAIM_DETAILS.CLAIM_DETAILS !==
      'NONE'
    ) {
      tabs.push({
        label: t('Claim details'),
        children: renderClaimDetails(
          config?.CLAIMS?.CLAIM_DETAIL_VIEW.UPDATE_CLAIM_DETAILS.CLAIM_DETAILS
        ),
      });
    }

    // if (
    //   config?.CLAIMS?.CLAIM_DETAIL_VIEW.UPDATE_CLAIM_DETAILS.ATTACHMENTS !==
    //   'NONE'
    // ) {
    //   tabs.push({
    //     label: t('Attachments'),
    //     children: renderAttachements(
    //       config?.CLAIMS?.CLAIM_DETAIL_VIEW.UPDATE_CLAIM_DETAILS.ATTACHMENTS
    //     ),
    //   });
    // }

    // todo, make this dynamic. No need to do it right now so leaving it for later
    tabs.push({
      label: t('Attachments'),
      children: renderAttachements('DEFAULT'),
    });

    return tabs;
  }

  return (
    <Modal
      kind="generic"
      settings={{
        title: t('Update claim details'),
        handleClose: handleClose,
      }}
      isLoading={loading}
      className="m-auto w-[724px] rounded-md border bg-white"
    >
      <div className="p-5">
        <TabsGeneric items={generateTabs()} query={false}></TabsGeneric>
      </div>
    </Modal>
  );
}

export default ModalEditClaimDetails;
