// AUTO-GENERATED FILE - DO NOT EDIT
// AUTO-GENERATED FILE - DO NOT EDIT
// AUTO-GENERATED FILE - DO NOT EDIT
// AUTO-GENERATED FILE - DO NOT EDIT
// AUTO-GENERATED FILE - DO NOT EDIT

import { SetupGreaseGateway } from '@rabbit/grease/gateway';
import { GreaseCodex } from '@rabbit/grease/elements';

const BakedCodex: GreaseCodex = [
  {
    domain: 'localhost',
    app: 'sage',
    deployment: 'us',
    manufacturer: 'manufacturer_pinnaclewarranties',
  },
  {
    domain: 'hot-sage.web.app',
    app: 'sage',
    deployment: 'us',
    manufacturer: 'manufacturer_shelta',
  },
  {
    domain: 'launchpad-sage.web.app',
    app: 'sage',
    deployment: 'aus',
    manufacturer: 'manufacturer_shelta',
  },
  {
    domain: 'eulaunchpad-sage.web.app',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_shelta',
  },
  {
    domain: 'eudemo-sage.web.app',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_demo',
  },
  {
    domain: 'eusandbox-sage.web.app',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_shelta',
  },
  {
    domain: 'admin-charlieoven.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_charlieoven',
  },
  {
    domain: 'admin-warrantyireland.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_warrantyireland',
  },
  {
    domain: 'admin-pinnaclewarranties.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_pinnaclewarranties',
  },
  {
    domain: 'admin-nucover.iwarranty.co',
    app: 'sage',
    deployment: 'aus',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'admin-nu-cover.iwarranty.co',
    app: 'sage',
    deployment: 'aus',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'admin-saez.iwarranty.co',
    app: 'sage',
    deployment: 'us',
    manufacturer: 'manufacturer_saez',
  },
  {
    domain: 'admin-unionaudio.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_unionaudio',
  },
  {
    domain: 'admin-fatbikes.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_fatbikes',
  },
  {
    domain: 'hot-admin-charlieoven.iwarranty.co',
    app: 'sage',
    deployment: 'us',
    manufacturer: 'manufacturer_charlieoven',
  },
  {
    domain: 'hot-admin-warrantyireland.iwarranty.co',
    app: 'sage',
    deployment: 'us',
    manufacturer: 'manufacturer_warrantyireland',
  },
  {
    domain: 'hot-admin-pinnaclewarranties.iwarranty.co',
    app: 'sage',
    deployment: 'us',
    manufacturer: 'manufacturer_pinnaclewarranties',
  },
  {
    domain: 'hot-admin-nucover.iwarranty.co',
    app: 'sage',
    deployment: 'us',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'hot-admin-nu-cover.iwarranty.co',
    app: 'sage',
    deployment: 'us',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'hot-admin-saez.iwarranty.co',
    app: 'sage',
    deployment: 'us',
    manufacturer: 'manufacturer_saez',
  },
  {
    domain: 'hot-admin-unionaudio.iwarranty.co',
    app: 'sage',
    deployment: 'us',
    manufacturer: 'manufacturer_unionaudio',
  },
  {
    domain: 'hot-admin-fatbikes.iwarranty.co',
    app: 'sage',
    deployment: 'us',
    manufacturer: 'manufacturer_fatbikes',
  },
  {
    domain: 'launchpad-admin-charlieoven.iwarranty.co',
    app: 'sage',
    deployment: 'aus',
    manufacturer: 'manufacturer_charlieoven',
  },
  {
    domain: 'launchpad-admin-warrantyireland.iwarranty.co',
    app: 'sage',
    deployment: 'aus',
    manufacturer: 'manufacturer_warrantyireland',
  },
  {
    domain: 'launchpad-admin-pinnaclewarranties.iwarranty.co',
    app: 'sage',
    deployment: 'aus',
    manufacturer: 'manufacturer_pinnaclewarranties',
  },
  {
    domain: 'launchpad-admin-nucover.iwarranty.co',
    app: 'sage',
    deployment: 'aus',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'launchpad-admin-nu-cover.iwarranty.co',
    app: 'sage',
    deployment: 'aus',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'launchpad-admin-unionaudio.iwarranty.co',
    app: 'sage',
    deployment: 'aus',
    manufacturer: 'manufacturer_unionaudio',
  },
  {
    domain: 'eulaunchpad-admin-charlieoven.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_charlieoven',
  },
  {
    domain: 'eulaunchpad-admin-warrantyireland.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_warrantyireland',
  },
  {
    domain: 'eulaunchpad-admin-pinnaclewarranties.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_pinnaclewarranties',
  },
  {
    domain: 'eulaunchpad-admin-nucover.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'eulaunchpad-admin-nu-cover.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'uslaunchpad-admin-saez.iwarranty.co',
    app: 'sage',
    deployment: 'us',
    manufacturer: 'manufacturer_saez',
  },
  {
    domain: 'eulaunchpad-admin-unionaudio.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_unionaudio',
  },
  {
    domain: 'eulaunchpad-admin-fatbikes.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_fatbikes',
  },
  {
    domain: 'eusandbox-admin-charlieoven.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_charlieoven',
  },
  {
    domain: 'eusandbox-admin-warrantyireland.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_warrantyireland',
  },
  {
    domain: 'eusandbox-admin-pinnaclewarranties.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_pinnaclewarranties',
  },
  {
    domain: 'eusandbox-admin-nucover.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'eusandbox-admin-nu-cover.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_nucover',
  },
  {
    domain: 'ussandbox-admin-saez.iwarranty.co',
    app: 'sage',
    deployment: 'us',
    manufacturer: 'manufacturer_saez',
  },
  {
    domain: 'eusandbox-admin-unionaudio.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_unionaudio',
  },
  {
    domain: 'eusandbox-admin-fatbikes.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_fatbikes',
  },
  {
    domain: 'eusandbox-admin-transporter.iwarranty.co',
    app: 'sage',
    deployment: 'eu',
    manufacturer: 'manufacturer_transporter',
  },
  {
    domain: 'admin-shelta.iwarranty.co',
    app: 'sage',
    deployment: 'aus',
    manufacturer: 'manufacturer_shelta',
  },
];

SetupGreaseGateway(BakedCodex);
