import {
  forceUpperCaseFirstLetter,
  handleKeyPress,
} from '@rabbit/elements/shared-components';
import classNames from 'classnames';
import { Field } from 'formik';
import { InputSettingsShape } from '@rabbit/elements/shared-types';

type InputTypeTextProps = {
  name: string;
  settings: InputSettingsShape;
};

const dynamicStyles = {
  base: 'font-nunito bg-gray-50 text-base text-gray-500 border-[1px] rounded-lg border-gray-300 focus:border-primary-600 w-full px-4 py-3 placeholder-gray-400 !shadow-none !outline-none !ring-0',
  withAppend:
    'font-nunito border-[1px] border-gray-300 rounded-none rounded-tr-lg rounded-br-lg',
  withPrepend:
    'font-nunito border-[1px] border-gray-300 rounded-none rounded-tl-lg rounded-bl-lg',
  error: 'bg-red-50 border-red-500 text-red-700 rounded-lg',
  disabled: 'border-gray-300 text-gray-400 opacity-60 rounded-lg',
  chat: '!bg-white h-[42px] rounded-lg',
};

function StyledInput({ field, form: { errors }, ...props }: any) {
  let errorField = false;
  if (errors[field.name] || props.errors) errorField = true;
  if (field.name.indexOf('.') > -1) {
    const nameSplit = field.name.split('.');
    if (errors[nameSplit[0]] && errors[nameSplit[0]][nameSplit[1]])
      errorField = true;
  }
  const { append, prepend } = props;

  return (
    <div className="flex">
      {append && (
        <span className="px-4 py-3 bg-gray-100 border border-r-0 border-gray-300 rounded-tl-lg rounded-bl-lg">
          {append}
        </span>
      )}
      <input
        type={'text'}
        id={props.id}
        className={classNames(dynamicStyles.base, {
          [`${dynamicStyles.chat}`]: props.id === 'chat_input',
          [`${dynamicStyles.error}`]: errorField,
          [`${dynamicStyles.disabled}`]: props.disabled,
        })}
        placeholder={props.placeholder}
        autocomplete="off"
        {...props}
        {...field}
      ></input>
      {prepend && (
        <span className="px-4 py-3 bg-gray-100 border border-l-0 border-gray-300 rounded-tr-lg rounded-br-lg">
          {prepend}
        </span>
      )}
    </div>
  );
}

//TODO: investigate Errors prop in formik - does not change styles
export function InputTypeText({ name, settings }: InputTypeTextProps) {
  const {
    placeholder,
    disabled,
    id,
    allowSpecialCharacter = false,
    onFocus,
    forceUpperCaseFirstLetter: forceUCFirst,
    errors,
    append,
    prepend,
    ...rest
  } = settings;

  return (
    <Field
      name={name}
      component={StyledInput}
      // note: something made the component lose formatting and re-adding the classes here is a dirty hack but a necessary one given time constraints
      // todo: fix this on a later date. If we wrap the input above inside Field (check out Input Type Password) it works, so check that one out for a possible fix - dc

      className={classNames(dynamicStyles.base, {
        [`${dynamicStyles.chat}`]: id === 'chat_input',
        [`${dynamicStyles.withPrepend}`]: prepend,
        [`${dynamicStyles.withAppend}`]: append,
        [`${dynamicStyles.error}`]: errors,
        [`${dynamicStyles.disabled}`]: disabled,
      })}
      placeholder={placeholder}
      type={'text'}
      id={id}
      disabled={disabled}
      onKeyDown={!allowSpecialCharacter ? handleKeyPress : undefined}
      onKeyUp={forceUCFirst ? forceUpperCaseFirstLetter : undefined}
      onFocus={onFocus}
      append={append}
      prepend={prepend}
      {...rest}
    />
  );
}
