/* eslint-disable @nx/enforce-module-boundaries */
import DragFileUpload from '../DragFileUpload/DragFileUpload';
//import { Input } from '../../atoms/inputs/Input/Input';
import UploadedFileCard from '../../molecules/UploadedFileCard/UploadedFileCardv2';
import Modal from '../../molecules/Modals/Modal/Modal';
import {
  DraggedFileShape,
  DeleteFilePropsShape,
  DocTypeShape,
  UploadQueueStateShape,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { UserUploadedDocument, CaseFlowFacts } from '@rabbit/data/types';
import InputSelectFile from '../../atoms/inputs/InputSelectFile/InputSelectFile';

interface UploadIdentifiersShape {
  personaId: string;
  docType: DocTypeShape | null; // todo: review if we need both docType and category
  category: UploadedFileCategories;
}

interface UploadActionsShape {
  uploadFiles: (
    filesToUpload: File[],
    personaId: string,
    fileCategory: UploadedFileCategories,
    docType?: DocTypeShape
  ) => Promise<void>;
  deleteFile: (urlOrPath: string, props: DeleteFilePropsShape) => Promise<void>;
  alterCaseFacts?: (facts: CaseFlowFacts) => Promise<void>;
  setShouldRefetch?: (value: boolean) => void;
}

interface UploadFilesFormProps {
  label?: string;
  labelClasses?: string;
  accepts?: string[];
  identifiers: UploadIdentifiersShape;
  currentFiles?: UserUploadedDocument[];
  isUpdating: boolean;
  queue: UploadQueueStateShape;
  actions: UploadActionsShape;
  onUploadCompleted?: (...args: any) => void;
  onDeleteFile?: (...args: any) => void;
  maxFiles?: number;
  disabled?: boolean;
}

export function UploadFilesFormv2(props: UploadFilesFormProps) {
  const {
    label = 'Proof of purchase',
    labelClasses,
    accepts = ['image/*', '.pdf'],
    currentFiles = [],
    isUpdating,
    queue,
    actions,
    identifiers: { personaId, docType, category },
    onDeleteFile,
    onUploadCompleted,
    disabled,
    maxFiles,
  } = props;

  const { docid: docId, type } = docType || {};
  const { ongoing, completed, attached } = queue;

  const { uploadFiles, deleteFile, alterCaseFacts, setShouldRefetch } =
    actions || {};

  const [isUploading, setUploading] = useState(false);
  const [showDragDropUploadError, setShowDragDropUploadError] = useState(true);

  const completedFilesOfCategory = completed.filter(
    (upload) => upload.category === category
  );

  const attachedFilesOfCategory = attached.filter(
    (upload) => upload.category === category
  );

  const displayUploader = maxFiles
    ? currentFiles.length +
        completedFilesOfCategory.length +
        attachedFilesOfCategory.length <
      maxFiles
    : true;

  const handleUploadFiles = async (files: File[]) => {
    if (!uploadFiles || !category || !type || !docId)
      throw new Error('Missing required data for upload');

    try {
      await uploadFiles(files, personaId, category, {
        type: type,
        docid: docId,
      });
      toast.success(`File uploaded successfully!`);
      setUploading(true);
      setShowDragDropUploadError(false);
    } catch (e) {
      setUploading(false);
      setShowDragDropUploadError(true);
    } finally {
      setUploading(false);
    }
  };

  const handleFileEvent = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowDragDropUploadError(false);
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    await handleUploadFiles(chosenFiles);
  };

  const handleDragFileEvent = async (filesArr: DraggedFileShape[]) => {
    setShowDragDropUploadError(false);
    const chosenFiles = Array.prototype.slice.call(filesArr);
    await handleUploadFiles(chosenFiles);
  };

  const [deleteModal, setDeleteModal] = useState<{
    open: boolean;
    path: string;
    name?: string;
    uploadCategory?: UploadedFileCategories;
    docid?: string;
  }>({
    open: false,
    path: '',
    name: undefined,
    uploadCategory: undefined,
    docid: undefined,
  });

  const handleDeleteFile = async (
    fullPath: string,
    name: string,
    uploadCategory: UploadedFileCategories,
    docid: string
  ) => {
    setDeleteModal({ open: true, path: fullPath, name, uploadCategory, docid });
    setShowDragDropUploadError(false);
  };

  const modalSettings = {
    text: `Are you sure you want to delete ${deleteModal.name}?`,
    primaryButtonText: 'Yes',
    outlineButtonText: 'No, cancel',
    loading: isUpdating,
    handlePrimaryClick: async () => {
      const { path, uploadCategory, docid } = deleteModal;
      if (!deleteFile || !uploadCategory) return;
      setDeleteModal({
        open: false,
        path: '',
        uploadCategory: undefined,
        docid: undefined,
      });
      try {
        await deleteFile(path, {
          category: uploadCategory,
          holdingId:
            (uploadCategory === UploadedFileCategories.ConsumerProofPurchase ||
              uploadCategory === UploadedFileCategories.SerialNumberProof) &&
            docid
              ? docid
              : undefined,
          alterCaseFacts,
          currentFiles,
        });
        onDeleteFile?.();
        setShouldRefetch?.(true);
        toast.success('File deleted successfully');
      } catch (err) {
        toast.error("Couldn't delete file, please try again later.");
        console.log(err);
      }
    },
    handleOutlineClick: () => setDeleteModal({ open: false, path: '' }),
  };

  // Might delete this in the future if it's not used at all. - dc
  useEffect(() => {
    if (onUploadCompleted) onUploadCompleted(completed);
  }, [completed.length]);

  return (
    <div className="font-nunito mt-4">
      <div className="flex flex-col gap-3">
        {label && (
          <label
            className={
              labelClasses
                ? labelClasses
                : 'text-base font-medium text-gray-900'
            }
          >
            {label}
          </label>
        )}
        {currentFiles &&
          Array.isArray(currentFiles) &&
          currentFiles?.map((item: UserUploadedDocument, i) => {
            return (
              <div className="" key={item.url + i}>
                <UploadedFileCard
                  data={item}
                  kind="existing"
                  onDeleteFile={handleDeleteFile}
                  docid={docId ?? ''}
                  uploadCategory={category}
                />
              </div>
            );
          })}
        {attachedFilesOfCategory
          .filter(
            (upload) =>
              !currentFiles.some(
                (file) => file.url === upload.uploadedFile?.url
              )
          )
          .map((upload, i) => {
            return (
              <div className="mb-5" key={upload.key + i}>
                <UploadedFileCard
                  kind="completed"
                  data={upload}
                  onDeleteFile={handleDeleteFile}
                  docid={docId ?? ''}
                  uploadCategory={category}
                />
              </div>
            );
          })}
        {completedFilesOfCategory
          .filter(
            (upload) =>
              !currentFiles.some(
                (file) => file.url === upload.uploadedFile?.url
              )
          )
          .map((upload, i) => {
            return (
              <div className="mb-5" key={upload.key + i}>
                <UploadedFileCard
                  kind="completed"
                  data={upload}
                  onDeleteFile={handleDeleteFile}
                  docid={docId ?? ''}
                  uploadCategory={category}
                />
              </div>
            );
          })}
        {ongoing.map((upload, i) => {
          if (upload.category === category)
            return (
              <div className="mb-5" key={upload.key + i}>
                <UploadedFileCard
                  kind="ongoing"
                  data={upload}
                  docid={docId ?? ''}
                  uploadCategory={category}
                />
              </div>
            );
          else return null;
        })}
        <div>
          <div className="md:hidden">
            <InputSelectFile
              name="input_files"
              settings={{ accepts }}
              onChange={handleFileEvent}
              disabled={isUploading || isUpdating || disabled}
            />
          </div>
          {displayUploader && (
            <div className="hidden md:block">
              <DragFileUpload
                name="input_files"
                accepts={accepts}
                label={'Upload ' + label.toLowerCase()}
                showError={showDragDropUploadError}
                loading={(isUploading || isUpdating) && !deleteModal.open}
                onChange={handleDragFileEvent}
                disabled={isUploading || isUpdating || disabled}
              />
            </div>
          )}
        </div>
      </div>
      {deleteModal.open && <Modal kind={'pop-up'} settings={modalSettings} />}
    </div>
  );
}

export default UploadFilesFormv2;
