import { useContext, useEffect, useState } from 'react';
import {
  Button,
  ButtonIcon,
  CardWrapperWithHeader,
  ExpandableText,
  LabelledItem,
  LabelledItemShape,
  Modal,
  ModalSettingsShape,
} from '@rabbit/elements/shared-components';
import {
  ArrowTopRightOnSquareIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';
import {
  CaseFlow_Utils_RegisterFileUpload,
  FileStorageContext,
} from '@rabbit/bizproc/react';
import ModalEditClaimDetails from '../../../organisms/ModalEditClaimDetails/ModalEditClaimDetails';
import { CaseflowContext } from '../../../../context/CaseflowContext';
import { getFaultLabels, stripTags } from '../../../../utils/helpers';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import ClaimAttachmentsUpload from '@rabbit/sage/components/organisms/ClaimAttachmentsUpload/ClaimAttachmentsUpload';
import { SageFileUploader } from '@rabbit/sage/components/organisms/upload-wrapper/SageFileUploader';
import {
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { ConfigContext } from '@rabbit/config/context';
import ClaimDetailsSectionDefault from './variations/ClaimDetailsSectionDefault';
import ClaimDetailsSectionType1 from './variations/ClaimDetailsSectionType1';
import ClaimDetailsSectionType2 from './variations/ClaimDetailsSectionType2';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ClaimDetailsSectionProps {}

export function ClaimDetailsSection(props: ClaimDetailsSectionProps) {
  const { t } = useTranslation();
  const { config } = useContext(ConfigContext);
  const {
    caseFacts,
    operatingPersonaSingleKey,
    holdingData,
    holdingProxyData,
    caseActors,
    alterCaseFacts,
    caseFlowCase,
  } = useContext(CaseflowContext) || {};

  const {
    moveCompletedUploadsToAttached,
    uploadQueueState,
    updateHoldingWithFiles,
    setShouldRefetch,
  } = useContext(FileStorageContext) || {};

  const [showModal, setShowModal] = useState(false);
  const [claimFilesModal, setClaimFilesModal] = useState(false);
  const [serialProofModal, setSerialProofModal] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [faultLabels, setFaultLabels] = useState<string[]>([]);

  useEffect(() => {
    const { holding_faults } = caseFacts || {};
    if (holding_faults && holding_faults?.length > 0) {
      (async () => {
        const res = await getFaultLabels(holding_faults);
        if (res) setFaultLabels(res);
      })().catch((err) => console.log(err));
    }
  }, [caseFacts?.holding_faults]);

  const modalSettingsClaimFiles: ModalSettingsShape = {
    title: t('Proof of purchase / Supporting materials'),
    handleClose: () => setClaimFilesModal(false),
  };

  const handleOpenProofOfPurchaseModal = () => {
    setClaimFilesModal(!claimFilesModal);
  };

  /* ---------------------------- Item renderer Fns --------------------------- */
  const renderCaseFiles = () => {
    let totalFiles = caseFacts?.consumer_proof_of_purchase?.length ?? 0;
    totalFiles += caseFacts?.consumer_claim_evidence?.length ?? 0;
    return (
      <div
        className={
          'flex cursor-pointer items-center gap-2 text-sm ' +
          (totalFiles === 0 ? 'text-red-500' : 'text-black')
        }
        onClick={handleOpenProofOfPurchaseModal}
      >
        {totalFiles} {t('file(s) uploaded')}
        <div>
          <ArrowTopRightOnSquareIcon
            className={
              'h-4 w-4' + (totalFiles === 0 ? 'text-red-500' : 'text-black')
            }
          />
        </div>
      </div>
    );
  };

  const renderSerialProof = () => {
    let totalFiles = caseFacts?.serial_number_proof?.length ?? 0;

    return (
      <div
        className={
          'flex cursor-pointer items-center gap-2 text-sm ' +
          (totalFiles === 0 ? 'text-red-500' : 'text-black')
        }
        onClick={() => setSerialProofModal(true)}
      >
        {totalFiles} {t('file(s) uploaded')}
        <div>
          <ArrowTopRightOnSquareIcon
            className={
              'h-4 w-4' + (totalFiles === 0 ? 'text-red-500' : 'text-black')
            }
          />
        </div>
      </div>
    );
  };

  const renderShopifyOrderLink = () => {
    const orderUrl = `https://shopify.com/${holdingProxyData?.shopifyLinks?.shopId}/account/orders/${holdingProxyData?.shopifyLinks?.orderId}`;

    return (
      <a
        className="flex cursor-pointer items-center text-black"
        href={orderUrl}
        target="_blank"
        rel="noreferrer"
      >
        {t('View')}
        <div>
          <ArrowTopRightOnSquareIcon className="h-5 w-5 pl-1 text-black" />
        </div>
      </a>
    );
  };

  const renderConsumerIssueDesc = () => {
    const description = caseFacts?.consumer_issue_description
      ? caseFacts.consumer_issue_description
      : '';

    return (
      <>
        {description.length > 0 && (
          <div className="mb-[30px]">
            <div className="font-nunito">
              <p className="mb-[10px] text-sm text-gray-500">
                {t('Customer description of problem')}
              </p>
              <div>
                <ExpandableText
                  text={stripTags(description) ?? ''}
                  maxLength={110}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  // Todo: This can be refined to include the size of the line, how many spaces each item can occupy, etc etc
  // But that seems overkill at this point - DC
  function renderClaimDetailItemsLine(
    claimDetailItems: LabelledItemShape[],
    keys: string[],
    extraClasses?: string
  ) {
    const itemsToRender = claimDetailItems.filter((item) =>
      keys.includes(item.key)
    );

    return (
      <div
        className={`font-nunito mb-[30px] grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-5 ${extraClasses}`}
      >
        {itemsToRender.map((item) => (
          <LabelledItem
            key={item.label}
            label={item.label}
            value={item.value}
          />
        ))}
      </div>
    );
  }
  /* ---------------- Skeleton Loader if data is not available ---------------- */
  if (
    !caseFlowCase ||
    !caseFacts ||
    !holdingData ||
    !holdingProxyData ||
    !moveCompletedUploadsToAttached ||
    !uploadQueueState ||
    !updateHoldingWithFiles ||
    !setShouldRefetch
  ) {
    return (
      <div className="font-nunito mb-[30px] grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-5">
        <Skeleton count={3} className="mb-10" />
        <Skeleton count={3} className="mb-10" />
        <Skeleton count={3} className="mb-10" />
        <Skeleton count={3} className="mb-10" />
        <Skeleton count={2} className="mb-10" />
      </div>
    );
  }

  /* ----------------------------------- TSX ---------------------------------- */
  return (
    <CardWrapperWithHeader
      title={t('Claim details')}
      headerRight={
        operatingPersonaSingleKey &&
        config?.CLAIMS.CLAIM_DETAIL_VIEW.UPDATE_CLAIM_DETAILS.ENABLED.includes(
          operatingPersonaSingleKey
        ) && (
          <ButtonIcon
            type="primary"
            label={t(`Update claim details`)}
            Icon={PencilIcon}
            iconLeft
            onClick={() => setShowModal(true)}
          />
        )
      }
    >
      {config.CLAIMS.CLAIM_DETAIL_VIEW.CLAIM_DETAILS === 'DEFAULT' && (
        <ClaimDetailsSectionDefault
          renderFns={{
            renderCaseFiles,
            renderShopifyOrderLink,
            renderConsumerIssueDesc,
            renderClaimDetailItemsLine,
          }}
          faultLabels={faultLabels}
        />
      )}
      {config.CLAIMS.CLAIM_DETAIL_VIEW.CLAIM_DETAILS === 'TYPE1' && (
        <ClaimDetailsSectionType1
          renderFns={{
            renderCaseFiles,
            renderConsumerIssueDesc,
            renderClaimDetailItemsLine,
          }}
          faultLabels={faultLabels}
        />
      )}
      {config.CLAIMS.CLAIM_DETAIL_VIEW.CLAIM_DETAILS === 'TYPE2' && (
        <ClaimDetailsSectionType2
          renderFns={{
            renderCaseFiles,
            renderSerialProof,
            renderShopifyOrderLink,
            renderConsumerIssueDesc,
            renderClaimDetailItemsLine,
          }}
          faultLabels={faultLabels}
        />
      )}
      {showModal && (
        <ModalEditClaimDetails
          modalSettings={{
            title: t('Edit or update claim details'),
            handleClose: () => setShowModal(false),
          }}
          claimDetails={caseFacts}
          handleClose={() => setShowModal(false)}
        />
      )}
      {claimFilesModal && (
        <Modal
          kind="generic"
          settings={modalSettingsClaimFiles}
          className="m-auto w-[724px] rounded-md border bg-white"
        >
          <div className="px-5">
            <ClaimAttachmentsUpload
              handleClose={() => setClaimFilesModal(false)}
              // this modal only does one thing, so we should cleanup on unmount directly on the component
              shouldCleanupOnUnmount={true}
            />
          </div>
        </Modal>
      )}
      {serialProofModal && (
        <Modal
          kind="generic"
          settings={{
            title: t('Serial number proof'),
            handleClose: () => setSerialProofModal(false),
          }}
          isLoading={uploadLoading}
          className="m-auto w-[724px] rounded-md border bg-white"
        >
          <div className="px-5">
            <SageFileUploader
              label={t('Serial number proof')}
              identifiers={{
                category: UploadedFileCategories.SerialNumberProof,
                docType: {
                  docid: caseFacts?.consumer_holding || '',
                  type: DocTypeShapeTypes.Case,
                },
                personaId: caseActors?.consumer ?? '',
              }}
              alterCaseFacts={alterCaseFacts}
              accepts={['image/*', '.pdf']}
              shouldAutoUpdateDocs={false}
              maxFiles={1}
              currentFiles={caseFacts?.serial_number_proof ?? []}
            />
          </div>
          <div className="mt-4 flex w-full gap-2 px-4">
            <Button
              kind="primary"
              onClick={async () => {
                setUploadLoading(true);
                await CaseFlow_Utils_RegisterFileUpload(caseFlowCase, {
                  uploadQueueState,
                  moveCompletedUploadsToAttached,
                  setShouldRefetch,
                  updateHoldingWithFiles,
                });
                setSerialProofModal(false);
                setUploadLoading(false);
              }}
            >
              {t('Save')}
            </Button>
            <Button kind="red" onClick={() => setSerialProofModal(false)}>
              {t('Close')}
            </Button>
          </div>
        </Modal>
      )}
    </CardWrapperWithHeader>
  );
}

export default ClaimDetailsSection;
