import {
  CardWrapperWithHeader,
  getWarrantyTerm,
  isWarrantyExpired,
  LabelledItemList,
  LabelledItemShape,
} from '@rabbit/elements/shared-components';
import { RegistrationsWarrantyDetailsSectionVariationProps } from './RegistrationsWarrantyDetailsSection';
import { WarrantyStatus } from '@rabbit/data/types';
import {
  formatDate,
  renderWarrantyRemaining,
} from '@rabbit/sage/utils/helpers';
import { t } from 'i18next';

export function RegistrationsWarrantyDetailsSectionDefault({
  holding_proxy,
  title,
  warrantyInfo,
}: RegistrationsWarrantyDetailsSectionVariationProps) {
  const items: LabelledItemShape[] = [
    {
      key: 'ID',
      label: t('ID'),
      value: warrantyInfo?.warrantyLink?.slice(0, 5).toUpperCase(),
    },
    {
      key: 'Warranty plan',
      label: t('Warranty plan'),
      value: warrantyInfo?.templateTitle ? warrantyInfo?.templateTitle : '-',
    },
    {
      key: 'Warranty term',
      label: t('Warranty term'),
      value: getWarrantyTerm(holding_proxy) || '-',
    },
    {
      key: 'Start date',
      label: t('Start date'),
      value: warrantyInfo?.startDate
        ? formatDate(new Date(warrantyInfo.startDate))
        : '-',
    },
    {
      key: 'Expiry date',
      label: t('Expiry date'),
      value:
        warrantyInfo?.status === WarrantyStatus.VOIDED
          ? 'Voided'
          : warrantyInfo?.endDate
          ? formatDate(new Date(warrantyInfo.endDate))
          : '-',
    },
    {
      key: 'Warranty remaining',
      label: t('Warranty remaining'),
      value: holding_proxy
        ? renderWarrantyRemaining(holding_proxy, false)
        : '-',
    },
    {
      key: 'Warranty expired',
      label: t('Warranty expired'),
      value: warrantyInfo?.endDate
        ? isWarrantyExpired(warrantyInfo?.endDate)
        : '-',
    },
  ];

  return (
    <CardWrapperWithHeader title={title}>
      <LabelledItemList items={items} />
    </CardWrapperWithHeader>
  );
}

export default RegistrationsWarrantyDetailsSectionDefault;
