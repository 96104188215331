import {
  Address,
  Money,
  PersonaLink,
  TenantLink,
  UserUploadedDocument,
  Z_Address,
  Z_UserUploadedDocument,
} from '@rabbit/data/types';
import { z } from 'zod';
import { CaseFlowStations_Lite } from '../_lite/types';
import { CaseFlowStations_Shelta } from '../_shelta/types';
import { CaseFlowStations_Fatbikes } from '../_fatbikes/types';

/* -------------------------------------------------------------------------- */
/*                              General use enums                             */
/* -------------------------------------------------------------------------- */

export enum CaseflowActorTypes {
  CONSUMER = 'consumer',
  REPAIRER = 'repairer',
  EXTERNAL_REPAIRER = 'external_repairer',
  WARRANTOR = 'warrantor',
}

export const Z_CaseflowActorTypes = z.nativeEnum(CaseflowActorTypes);

export enum CaseflowCaseTypes {
  // would like to change this from 'repair' to 'shelta', but I'm afraid to do so right now since it's so embedded in the codebase - dc
  SHELTA = 'repair',
  LITE = 'lite',
  FATBIKES = 'fatbikes',
}

export const Z_CaseflowCaseTypes = z.nativeEnum(CaseflowCaseTypes);

export const CaseFlowCaseStations = {
  ...CaseFlowStations_Shelta,
  ...CaseFlowStations_Fatbikes,
  ...CaseFlowStations_Lite,
};

export type CaseFlowCaseStationsShape =
  | CaseFlowStations_Shelta
  | CaseFlowStations_Fatbikes
  | CaseFlowStations_Lite;

export enum CaseflowCaseStatus {
  WAITING_FOR_REVIEW = 'Waiting for review',
  ASSESSMENT = 'Assessment',
  REPAIR_REPLACE = 'Repair / Replace',
  COMPLETE = 'Complete',
}

/* -------------------------------------------------------------------------- */
/*                         Form data for case creation                        */
/* -------------------------------------------------------------------------- */

// todo move to shelta types file
/* --------------------------------- Shelta --------------------------------- */
export interface Caseflow_CaseCreation_Sage_Shelta_FormDataShape {
  consumer_first_name: string;
  consumer_last_name: string;
  consumer_address: Address;
  consumer_email: string;
  consumer_telephone: string;
  consumer_preferred_contact: Array<'phone' | 'email'>;
  purchase_date: number;
  consumer_issue_type: string;
  consumer_issue_type_ref: string;
  consumer_issue_description: string;
  consumer_holding_purchase_location: string;
  consumer_holding_purchase_location_other?: string | null;
  consumer_proof_of_purchase: UserUploadedDocument[];
  consumer_claim_evidence: UserUploadedDocument[];
  holding_vendable_id: string;
  internal_comment: string;
}

export const Z_Caseflow_CaseCreation_Sage_Shelta_FormDataShape = z.object({
  consumer_address: Z_Address.partial(),
  consumer_email: z.string(),
  consumer_first_name: z.string(),
  consumer_last_name: z.string(),
  consumer_telephone: z.string(),
  consumer_preferred_contact: z.array(
    z.union([z.literal('phone'), z.literal('email')])
  ),
  purchase_date: z.number(),
  consumer_issue_type: z.string(),
  consumer_issue_type_ref: z.string(),
  consumer_issue_description: z.string(),
  consumer_holding_purchase_location: z.string(),
  consumer_holding_purchase_location_other: z.string().nullable(),
  consumer_proof_of_purchase: z.array(Z_UserUploadedDocument),
  consumer_claim_evidence: z.array(Z_UserUploadedDocument),
  holding_vendable_id: z.string(),
  internal_comment: z.string(),
});

// TODO: move to claims lite types file
/* ------------------------------- Claims-lite ------------------------------ */
export interface Caseflow_CaseCreation_Sage_Lite_FormDataShape {
  consumer_issue_type: string;
  consumer_issue_type_ref: string;
  consumer_issue_description?: string;
  internal_comment?: string;
}

export const Z_Caseflow_CaseCreation_Sage_Lite_FormDataShape = z.object({
  consumer_issue_type: z.string(),
  consumer_issue_type_ref: z.string(),
  consumer_issue_description: z.string().optional(),
  internal_comment: z.string().optional(),
});

/* -------------------------------- Fatbikes -------------------------------- */
// TODO move to fatbikes types file

export interface Caseflow_CaseCreation_Sage_Fatbikes_FormDataShape
  extends Caseflow_CaseCreation_Sage_Lite_FormDataShape {
  consumer_claim_evidence: UserUploadedDocument[];
  serial_number: string;
  serial_number_proof: UserUploadedDocument[];
}

export const Z_Caseflow_CaseCreation_Sage_Fatbikes_FormDataShape = z.object({
  ...Z_Caseflow_CaseCreation_Sage_Lite_FormDataShape.shape,
  consumer_claim_evidence: z.array(Z_UserUploadedDocument),
  serial_number: z.string(),
  serial_number_proof: z.array(Z_UserUploadedDocument),
});

/* ---------------------------------- Olive --------------------------------- */
export interface Caseflow_CaseCreation_Olive_FormDataShape {
  consumer_issue_type: string;
  consumer_issue_type_ref: string;
  consumer_proof_of_purchase: UserUploadedDocument[];
  consumer_issue_description: string;
  consumer_claim_evidence: UserUploadedDocument[];
  serial_number?: string;
  serial_number_proof?: UserUploadedDocument[];
}

export const Z_Caseflow_CaseCreation_Olive_FormDataShape = z.object({
  consumer_issue_type: z.string(),
  consumer_issue_type_ref: z.string(),
  consumer_proof_of_purchase: z.array(Z_UserUploadedDocument),
  consumer_issue_description: z.string(),
  consumer_claim_evidence: z.array(Z_UserUploadedDocument),
  serial_number: z.string().optional(),
  serial_number_proof: z.array(Z_UserUploadedDocument).optional(),
});

/* -------------------------------------------------------------------------- */
/*                                    Facts                                   */
/* -------------------------------------------------------------------------- */

export interface CFCF_InternalComment {
  comment: string;
  author: string;
}

export enum CFCF_FileUpdateEventAction {
  UPLOAD = 'upload',
  DELETION = 'deletion',
}

export enum CFCF_FileUpdateEventCategory {
  PROOF_OF_PURCHASE = 'consumer_proof_of_purchase',
  CLAIM_EVIDENCE = 'consumer_claim_evidence',
  SHIPPING_LABEL = 'item_shipping_label',
  SERIAL_NUMBER_PROOF = 'serial_number_proof',
}

export interface CFCF_FileUpdateEvent {
  file: UserUploadedDocument;
  action: CFCF_FileUpdateEventAction;
  category: CFCF_FileUpdateEventCategory;
}

export interface CFCF_PartnerTenantData {
  partner_tenant_id: TenantLink;
  partner_tenant_label: string;
  partner_tenant_name?: string;
  partner_tenant_address?: Address;
  partner_tenant_email?: string;
}

/* -------------------------------------------------------------------------- */
/*                                Cost logging                                */
/* -------------------------------------------------------------------------- */
export interface TeamMember {
  name: string;
  id?: PersonaLink; // TODO: Confirm if it should be mandatory right away
}

interface ShippingCarrier {
  name: string;
  id?: string; // TODO: one day we might have a unique shipping carrier identifier
}

interface PartManufacturer {
  name: string;
  id?: string; // TODO: one day we might have a part manufacturer persona or similar
}

/* -------------------------- Administrative costs -------------------------- */

export interface CFCF_AdministrativeCostLog {
  team_member: TeamMember;
  rate: Money;
  time_spent: string; // ISO8601 duration
  date?: number; // Unix timestamp
  VAT: number; // %
}

/* ------------------------------- Parts used ------------------------------- */

export interface ClaimPartUsed {
  manufacturer: PartManufacturer;
  name: string;
  id: string;
  cost: Money;
  quantity: number;
  VAT: number; // %
}

export type CFCF_PartsUsed = ClaimPartUsed[];

/* ------------------------------- Repair time ------------------------------ */
export interface CFCF_RepairTimeLog {
  team_member: TeamMember;
  time_spent: string; // ISO8601 duration
  date?: number; // Unix timestamp
  rate: Money;
  VAT: number; // %
}

/* -------------------------------- Shipping -------------------------------- */
export interface CFCF_ShippingCostLog {
  carrier: ShippingCarrier;
  date?: number; // Unix timestamp
  VAT: number; // %
  tracking_number?: string;
  amount: Money;
  documents?: UserUploadedDocument[];
}

/* --------------------------------- travel --------------------------------- */
export interface CFCF_TravelCostLog {
  description: string;
  amount: Money;
  date?: number; // Unix timestamp
  distance: number; // km
  documents?: UserUploadedDocument[];
  VAT: number; // %
}

/* -------------------------------------------------------------------------- */
/*                                 Other costs                                */
/* -------------------------------------------------------------------------- */
export interface CFCF_OtherCostLog {
  description: string;
  amount: Money;
  date?: number; // Unix timestamp
  VAT: number; // %
  documents?: UserUploadedDocument[];
}
