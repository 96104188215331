import {
  CFC_GetVechicleInfo,
  CFC_OliveRegisterConsumerHolding,
  CFC_SageRegisterConsumerHolding,
  OCF_OliveRegisterConsumerHolding,
  OliveRegisterConsumerHolding_Payload,
  ICF_SageRegisterConsumerHolding,
  OCF_SageRegisterConsumerHolding
} from '@rabbit/bizproc/core';
import { VehicleInfo } from '@rabbit/data/types';
import {
  APICallParamsBase,
  APICallResultBase,
  GetVehicleInfoParams,
} from '@rabbit/elements/shared-types';
import { z } from 'zod';

export async function Holding_Register_Olive({
  requestId,
  apiVersion,
  vendableDocid,
  formData,
}: OliveRegisterConsumerHolding_Payload & APICallParamsBase): Promise<
  { data: z.infer<typeof OCF_OliveRegisterConsumerHolding> } & APICallResultBase
> {
  const result = await CFC_OliveRegisterConsumerHolding.call({
    requestId,
    apiVersion,
    vendableDocid,
    formData,
  });
  if (!result.ok) throw new Error(result.error);

  return result;
}

export async function Holding_GetVehicleInfo({
  requestId,
  apiVersion,
  registrationNo,
  tenant,
}: GetVehicleInfoParams & APICallParamsBase): Promise<
  { data: VehicleInfo } & APICallResultBase
> {
  const result = await CFC_GetVechicleInfo.call({
    requestId,
    apiVersion,
    registrationNo,
    tenant,
  });
  if (!result.ok) throw new Error(result.error);
  return result as { data: VehicleInfo } & APICallResultBase;
}



export async function Holding_Register_Sage({
  requestId,
  apiVersion,
  tenantLink,
  holdingLink,
  consumerLink,
  warrantorLink,
  formData,
}: z.infer<typeof ICF_SageRegisterConsumerHolding>): Promise<
  { data: z.infer<typeof OCF_SageRegisterConsumerHolding> } & APICallResultBase
> {
  const result = await CFC_SageRegisterConsumerHolding.call({
    requestId,
    apiVersion,
    tenantLink,
    holdingLink,
    consumerLink,
    warrantorLink,
    formData,
  });
  if (!result.ok) throw new Error(result.error);
  return result;
}
