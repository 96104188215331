import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Input,
  asyncValidateSchema,
} from '@rabbit/elements/shared-components';
import ROUTE_NAME from '../../../utils/url-constants';
import { useSendEmail } from '@rabbit/bizproc/react';
import { toast } from 'react-toastify';
import { useAppInfo } from '@rabbit/sage/utils/helpers';

export interface ForgotPasswordFormValuesShape {
  email: string;
}

const initialValues: ForgotPasswordFormValuesShape = {
  email: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter an email address'),
});

export interface ForgotPasswordFormProps {
  authErrorMsg: string;
  setAuthErrorMsg: React.Dispatch<React.SetStateAction<string>>;
}

export function ForgotPasswordForm(props: ForgotPasswordFormProps) {
  const appInfo = useAppInfo();
  const [buttonLoading, setButtonLoading] = useState(false);
  const { SE_Sage_ResetPassword } = useSendEmail();
  const navigate = useNavigate();

  const onSubmit = async (values: ForgotPasswordFormValuesShape) => {
    setButtonLoading(true);
    try {
      if (!window.location.origin) return;
      await SE_Sage_ResetPassword(
        values.email ?? '',
        appInfo.email_sender,
        appInfo.email_main_template,
        appInfo.name,
        window.location.origin,
        appInfo.templateLanguage
      );
      navigate(ROUTE_NAME.CONFIRM_EMAIL, { state: { data: values.email } });
      toast.success('Password reset email sent!');
    } catch (error) {
      console.log(error);
    }
    setButtonLoading(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={asyncValidateSchema(validationSchema)}
      validateOnBlur
      onSubmit={onSubmit}
    >
      {({ errors, values }) => (
        <Form className="font-nunito flex flex-col gap-6">
          <Input
            type="email"
            name="email"
            label="Please enter your email address*"
            settings={{
              placeholder: 'Your email address',
              hint: '*required',
            }}
          />
          <Button
            kind="primary"
            type="submit"
            disabled={
              Object.keys(errors).length > 0 ||
              values.email.length === 0 ||
              buttonLoading
            }
            loading={buttonLoading}
          >
            Send link
          </Button>
          {/* {authErrorMsg && (
            <p className="font-nunito text-sm text-red-500">{authErrorMsg}</p>
          )} */}
        </Form>
      )}
    </Formik>
  );
}

export default ForgotPasswordForm;
