import { CaseflowCaseTypes } from '@rabbit/bizproc/core';
import { PersonaTypeSingleLetter } from '@rabbit/data/types';

// Keeping it JSON only here. Generators derived from this (for forms, tables, etc) will have to be their own thing.
// Also I hate gigantic JSON objects all in one file so we import each bit from a separate file.
//
// Please read documentation/config.md for more info

export type ClaimsHoldingForms = 'BASE' | 'TYPE1';
export type ClaimsTableType = 'SIMPLE' | 'BASIC' | 'ADVANCED';
export type ClaimDetailViewType = 'DEFAULT' | 'CAR_DETAILS';
export type ClaimDetailsSectionType = 'DEFAULT' | 'TYPE1' | 'TYPE2';
export type UpdateClaimDetails_CustomerFormType = 'NONE' | 'DEFAULT';
export type UpdateClaimDetails_ClaimDetailsFormType =
  | 'NONE'
  | 'DEFAULT'
  | 'TYPE1';
export type UpdateClaimDetails_AttachmentsFormType =
  | 'NONE'
  | 'DEFAULT'
  | 'TYPE1';
export type ClaimActionsType = 'TYPE1' | 'TYPE2';
export type RegistrationDetailsView_WarrantyDetailsSectionType =
  | 'DEFAULT'
  | 'CAR_WARRANTY'
  | 'STONE_WARRANTY';

type RestrictedUserPathsType = {
  Warrantor: string[];
  ExternalRepairer: string[];
};

const BaseConfig = {
  NAVIGATION: {
    RESTRICTED: false,
    RESTRICTED_PATHS: [] as string[],
    RESTRICTED_USER_PATHS: {
      Warrantor: [],
      ExternalRepairer: [],
    } as RestrictedUserPathsType,
  },
  DASHBOARD: {},
  CLAIMS: {
    CASEFLOW_TYPE: CaseflowCaseTypes.LITE,
    PROXY_OWNER_PERSONA: PersonaTypeSingleLetter.Warrantor,
    INTERNAL_REPAIRERS_ENABLED: false,
    CLAIMS_LIST_TABLE: {
      ENABLED: [
        PersonaTypeSingleLetter.Warrantor,
        PersonaTypeSingleLetter.Repairer,
      ] as PersonaTypeSingleLetter[],
      CAN_VIEW_ALL_CLAIMS: [
        PersonaTypeSingleLetter.Warrantor,
      ] as PersonaTypeSingleLetter[],
      ASSIGNED_UNASSIGNED_TABS: false,
    },
    OPERATING_PERSONAS: [
      PersonaTypeSingleLetter.Repairer,
      PersonaTypeSingleLetter.Warrantor,
    ],
    CLAIMS_FLOW: {
      CAN_ASSIGN_REPAIRER: [] as PersonaTypeSingleLetter[],
      SERIAL_NUMBER: false,
      SERIAL_NUMBER_PROOF: false,
      CREATE_WITH_WARRANTY_INFO: false,
      CREATE_HOLDING_FORM: 'BASE' as ClaimsHoldingForms,
      CLAIMS_TABLE_TYPE: 'TYPE1' as ClaimsTableType,
      NEW_CLAIM: {
        NEW_USER_BTN: true,
        NEW_PRODUCT_BTN: true,
      },
      INVOICE: {
        ENABLED: false,
      },
    },
    CHAT: {
      IS_READONLY: false,
    },
    CLAIM_DETAIL_VIEW: {
      CLAIM_DETAILS: 'DEFAULT' as ClaimDetailsSectionType,
      UPDATE_CLAIM_DETAILS: {
        ENABLED: [PersonaTypeSingleLetter.Warrantor],
        CUSTOMER_DETAILS: 'NONE' as UpdateClaimDetails_CustomerFormType,
        CLAIM_DETAILS: 'DEFAULT' as UpdateClaimDetails_ClaimDetailsFormType,
        //ATTACHMENTS: 'DEFAULT' as UpdateClaimDetails_AttachmentsFormType,
      },
      CLAIM_ACTIONS: {
        ENABLED: [] as PersonaTypeSingleLetter[],
        TYPE: 'TYPE1' as ClaimActionsType,
      },
      REPAIRER_DETAILS_SECTION: [] as PersonaTypeSingleLetter[],
      TYPE: 'DEFAULT' as ClaimDetailViewType,
    },
  },
  AUTHENTICATION: {},
  ACCOUNT: {},
  HOLDINGS: {
    NEW_REGISTRATION_FLOW: {
      NEW_USER_BTN: true,
    },
    REGISTRATION_DETAILS_VIEW: {
      WARRANTY_DETAILS_SECTION:
        'DEFAULT' as RegistrationDetailsView_WarrantyDetailsSectionType,
    },
  },
  VENDABLES: {},
  WARRANTIES: {
    MANAGEMENT: {
      SHOW_WARRANTY_OPTIONS_BTN: false,
      CAN_VOID_WARRANTIES: false,
      CAN_EDIT_WARRANTIES: false,
      CAN_EDIT_PRICE_PLANS: false,
    },
  },
  MARKETING: {
    EMAIL_TEMPLATE_LANGUAGE: 'EN',
  },
  SHOPIFY: {
    ENABLED: false,
  },
};

export default BaseConfig;
