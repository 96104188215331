import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BreadCrumbs from '../../atoms/BreadCrumbs/BreadCrumbs';
import NavBar from '../../molecules/NavBar/NavBar';
import ROUTE_NAME from '../../../utils/url-constants';
import { UserContext } from '../../../context/UserContext';

const isDemoEnv = import.meta.env.DEMO;

type LayoutProps = {
  children: React.ReactNode;
};

export function Layout({ children }: LayoutProps) {
  const location = useLocation();
  const { state } = location;
  const contextValues = useContext(UserContext);

  //TODO: remove Link and set back claimId
  const { docid, productName, link, title, warrantyId } = state || {};

  const [breadCrumbLabel, setBreadCrumbLabel] = useState('');

  const titleMap = [
    { path: ROUTE_NAME.DASHBOARD, title: 'Dashboard' },
    { path: ROUTE_NAME.CLAIMS, title: 'Claims' },
    { path: ROUTE_NAME.MY_CLAIMS, title: 'My Claims' },
    { path: ROUTE_NAME.FAULTS, title: 'Faults' },
    { path: ROUTE_NAME.MANAGE, title: 'Manage' },
    {
      path: ROUTE_NAME.PRODUCTS,
      title: isDemoEnv ? 'Products and parts' : 'Products',
    },
    {
      path: ROUTE_NAME.PRODUCTS + '/' + docid,
      title: isDemoEnv
        ? `Products and parts ${productName ? `> ${productName}` : ''}`
        : `Products ${productName ? `> ${productName}` : ''}`,
    },
    { path: ROUTE_NAME.REGISTRATIONS, title: 'Registrations' },
    {
      path: ROUTE_NAME.REGISTRATIONS + '/' + link, //check this value
      title: `Registrations > ${warrantyId} - ${title}`,
    },
    { path: ROUTE_NAME.WARRANTY_OVERVIEW, title: 'Warranties summary' },
    {
      path: ROUTE_NAME.WARRANTY_OVERVIEW + '/' + link,
      title: `Warranty summary > ${title}`,
    },
    {
      //TODO: remove Link and set back claimId
      path: ROUTE_NAME.CLAIMS + '/' + link,
      title: `Claims > ${
        docid && productName ? `${productName} (${docid})` : ''
      }`,
    },
    {
      path: ROUTE_NAME.MANAGE + '/customer/' + docid,
      title: `Manage > Customers > ${productName}`,
    },
    { path: ROUTE_NAME.MANAGE_ACCOUNT, title: 'Manage Account' },
    { path: ROUTE_NAME.MY_ORGANIZATION, title: 'My Organization' },
    { path: ROUTE_NAME.USER_GROUP, title: 'User Group' },
    // { path: ROUTE_NAME.TERMS_AND_CONDITIONS, title: 'Terms & Conditions' },
    // { path: ROUTE_NAME.PRIVACY_POLICY, title: 'Privacy Policy' },
    {
      path: ROUTE_NAME.PLATFORM_SERVICES_AGREEMENT,
      title: 'Platform Services Agreement',
    },
  ];

  useEffect(() => {
    const currTitle = titleMap.find((item) => item.path === location.pathname);
    if (currTitle && currTitle.title) {
      //const pageTitle = 'iWarranty Admin - ' + appInfo.name + ' - ' + currTitle.title;
      const pageTitle = 'iWarranty Admin - ' + currTitle.title;
      setBreadCrumbLabel(currTitle.title);
      document.title = pageTitle;
    }
  }, [location]);

  return (
    <div className="flex w-full flex-col">
      <NavBar />
      {contextValues?.user && breadCrumbLabel && (
        <BreadCrumbs title={breadCrumbLabel} />
      )}
      <div className="flex w-full flex-grow flex-col px-8 py-8 md:m-[0_auto] md:max-w-[1280px] md:scroll-px-28">
        {children}
      </div>
    </div>
  );
}

export default Layout;
