import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { OptionShape, useGetRepairerPeers } from '@rabbit/bizproc/react';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { toast } from 'react-toastify';
import { MANDRILL_TEMPLATES } from '@rabbit/bizproc/react';
import { getConsumerURL, useAppInfo } from 'apps/sage/src/utils/helpers';
import { useTranslation } from 'react-i18next';
import { PersonaTypeSingleLetter } from '@rabbit/data/types';
import { ConfigContext } from '@rabbit/config/context';
import { CaseflowCaseTypes } from '@rabbit/bizproc/core';

//export interface AssignCaseInternalModalProps {}
interface FormValuesShape {
  repairer_id: string;
  internal_comment: string;
}

const initialValues = {
  repairer_id: '',
  internal_comment: '',
};

const validationSchema = Yup.object().shape({
  repairer_id: Yup.string()
    .trim()
    .required('An user must be assigned in order to continue'),
  internal_comment: Yup.string().trim(),
});

export function AssignCaseInternalModal() {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { repairerPeer, repairerPeerLoading } = useGetRepairerPeers();
  const [showInternalComment, setShowInternalComment] = useState(false);

  const {
    caseFacts,
    alterCaseFacts,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    operatingPersonaSingleKey,
    caseId,
    alterCasePublicEmail,
    caseActors,
  } = useContext(CaseflowContext) || {};

  const { config } = useContext(ConfigContext);
  
  const repairerUsers: OptionShape[] = repairerPeerLoading
    ? [
        {
          id: '-1',
          label: 'Loading...',
          value: 'Loading...',
        },
      ]
    : (repairerPeer
        .map((repairer, index) => {
          if (config.CLAIMS.CASEFLOW_TYPE !== CaseflowCaseTypes.FATBIKES || (
            caseActors.repairer !== repairer?.docid &&
            caseActors.external_repairer !== repairer?.docid)
          ) {
            const label = repairer?.name || '';
            const value = repairer?.docid || '';
            if (label && value) {
              return {
                id: `${index}`,
                label,
                value,
              };
            }
          }
          return null;
        })
        .filter(Boolean) as OptionShape[]);

  const [usersForAssignment, setUsersForAssignment] = useState(repairerUsers);

  useEffect(() => {
    if (usersForAssignment !== repairerUsers)
      setUsersForAssignment(repairerUsers);
  }, [repairerPeer]);

  if (
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !moveSpotlight ||
    !operatingPersonaSingleKey ||
    !caseFacts ||
    !caseId ||
    !alterCasePublicEmail ||
    !caseActors
  )
    return <LoadingSpinner size={'xs'} />;

  const onSubmit = async (values: FormValuesShape) => {
    const { internal_comment, repairer_id } = values;

    setIsSubmitting(true);
    const nameForId = usersForAssignment.filter(
      (user) => user.value === values.repairer_id
    );
    // This only assigns repairers right now, so we should move the spotlight to repairer, not to the operatingPersona
    // Since the operatingPersona might be the Warrantor
    await moveSpotlight(PersonaTypeSingleLetter.Repairer);
    executeAction('assign_case', {
      delegate_repairer_id: values.repairer_id,
      delegate_repairer_name: nameForId[0].label,
    });

    try {
      const factsToAlter = {
        ...(internal_comment && {
          internal_comment: {
            comment: internal_comment,
            author: operatingPersona,
          },
        }),
        delegate_repairer_id: repairer_id,
        delegate_repairer_name: nameForId[0].label,
      };

      const to = caseFacts?.consumer_email ?? '',
        from = appInfo.email_sender,
        template = MANDRILL_TEMPLATES.BODY_ASSIGN_CASE_V2,
        business_name = appInfo.name ?? '',
        claim_id = caseId,
        first_name = caseFacts?.consumer_name ?? '',
        product_name = caseFacts.consumer_holding_name ?? '',
        subject = t('email.subject.claimAssigned', {business_name, product_name}),
        link_to_claim = `${getConsumerURL()}/repairs/${caseId}`;

      if (Object.keys(factsToAlter).length > 0) {
        await alterCaseFacts(factsToAlter);
      }

      await alterCasePublicEmail({
        context: 'assigned_for_repair',
        to,
        from,
        subject,
        template,
        substitutions: {
          subject,
          business_name,
          claim_id,
          first_name,
          product_name,
          link_to_claim,
        },
        shallBeSentViaBackend: true,
      });

      setShowModal(false);
      toast.success(t('Claim updated successfully.'));
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong, please try again'));
    }
  };

  return (
    <Modal
      settings={{
        title: t('Assign case'),
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      kind="generic"
      isLoading={isSubmitting}
      className="max-h-[768px] w-full max-w-[1024px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors }) => (
          <Form className="mt-5 flex flex-col gap-3 px-4">
            <Input
              type="select"
              label={t('Assign to*')}
              name="repairer_id"
              settings={{
                options: usersForAssignment,
                id: 'repairer_id',
                placeholder: t('Select'),
                hint: '*required',
              }}
            />
            {!showInternalComment && (
              <div className="mt-4">
                <Button
                  kind="outline"
                  type="button"
                  className="w-full"
                  onClick={() => setShowInternalComment(true)}
                >
                  {t('Add internal comment')}
                </Button>
              </div>
            )}
            {showInternalComment && (
              <div>
                <Input
                  type="rich-text"
                  label={t('Internal comment')}
                  name="internal_comment"
                  settings={{
                    id: 'internal_comment',
                    allowSpecialCharacter: true,
                  }}
                />
              </div>
            )}
            <div className="mt-8 flex gap-8">
              <Button
                kind="primary"
                type="submit"
                loading={isSubmitting}
                disabled={values.repairer_id === 'Loading...' ? true : false}
              >
                {t('Assign case')}
              </Button>
              <Button kind="outline_red" onClick={() => setShowModal(false)}>
                {t('Cancel')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default AssignCaseInternalModal;
