import { z } from 'zod';
import {
  OneEachOptionalPersonaLink,
  PersonaTypeSingleLetter,
  Z_OneEachOptionalPersonaLink,
  Z_PersonaTypeFullKey,
} from '../persona';
import { PersonaLink, Z_PersonaLink } from '../persona/persona';

/** A base gazette entry, every entry contains the following. */
type BaseGazetteEntry = {
  /** WHO? - a for "author" - who wrote the entry in the form P:xxxxx where P (single letter) indicates type of persona */
  a: string;
  /** WHAT? - k for "Kind" - identify what kind of gazette entry we are talking about */
  k: number;
  /** WHEN? - t for "timestamp" - time the entry was written */
  t: number;
};

/** Fact Update - An update to the facts belonging to the Case.
 *
 * Creates the fact if it doesn't exist.
 * Overwrites it if it does.
 * Set to null to delete the fact.
 *
 * The permitted facts are defined in the configuration.
 * */
export interface GEFactUpdate extends BaseGazetteEntry {
  k: typeof GEK_FactUpdate;

  /** A key:value collection of what has been added or altered since the last entry. */
  kv: {
    [key: string]: any;
  };
}
export const GEK_FactUpdate = 1;

/** State Change - record changes in the state.
 *
 * We need to know who was changed - was it an actor, or the case itself.
 */
export interface GEStateChange extends BaseGazetteEntry {
  k: typeof GEK_StateChange;

  /** Who is changing. This is either a single letter identifying the persona, or "CASE" for the case change. */
  who: string;

  /** The state we are going to */
  newState: string;

  /** The state we came from */
  oldState: string;
}
export const GEK_StateChange = 2;

/** Actor Change - an actor has joined or changed
 *
 * In our design actors don't really change during a case, but they may be allocated as the case progresses:
 * * The repairer is selected later once the case has opened
 * * A courier comes into the fold to handle some delivery
 *
 * The reason we don't want to change actors is because they define the configuration, and if a new actor turns up the configuration may be left hanging in a weird state. Maybe we will look at that one day.
 *
 * Things we don't really think about here:
 * * The case being passed to different staff members. It is still assigned to the master owner object, and the staff can access it anyway
 * * Consumer changing repairer - I think in practice this is a new case
 * * Repairer sending a case to a different repairer (outsource) - in this situation they should open a new case to keep things simple
 */
export interface GEActorChange extends BaseGazetteEntry {
  k: typeof GEK_ActorChange;

  /** Role that is being set to be this persona */
  role: string;

  /** The new actor's PersonaLink ID */
  link: PersonaLink;
}
export const GEK_ActorChange = 3;

/** Spotlight Change - the spotlight has changed.
 *
 * Spotlight is who needs to take action, so it will change often as communications bounces around.
 */
export interface GECaseSpotlightChange extends BaseGazetteEntry {
  k: typeof GEK_CaseSpotlightChange;

  /** If someone is being added, they are placed here. */
  add?: string[];

  /** If someone is being deleted, they are placed here. */
  del?: string[];

  /** The new spotlight */
  spotlight: string[];
}
export const GEK_CaseSpotlightChange = 4;

/** Attachment of "something" to the case. Image uploads and the like. These are similar to facts but whereas fact changes overwrite each other, attachments are additive.
 *
 * they have a "kind" to explain the purpose of the attachment. For example:
 * * Receipt
 * * Photo of damage
 * * Proof of warranty
 * * Courier label
 *
 * I haven't added a delete feature yet, but this would have to be a new entry referring back to the attachment to mark it "deleted". You'd probably delete it from the cloud too.
 * */
export interface GEAttachment extends BaseGazetteEntry {
  k: typeof GEK_Attachment;

  /** All attachments are uploaded, we don't want any chunkiness here. */
  url: string;

  /** The kind of attachment. */
  kind: string;
}
export const GEK_Attachment = 5;

export interface GEChat extends BaseGazetteEntry {
  k: typeof GEK_Chat;

  /** What is being said in the chat message */
  body: string;

  /** Attachments sent (uploaded url) */
  attachment?: string;
}

export const GEK_Chat = 6;

export interface GEAcknowledge extends BaseGazetteEntry {
  k: typeof GEK_Acknowledge;

  /** The actor that is acknowledging */
  actor: keyof OneEachOptionalPersonaLink;

  /** The time of acknowledgment */
  time: number;
}

export const GEK_Acknowledge = 7;

/* -------------------------------------------------------------------------- */
/*                                    Email                                   */
/* -------------------------------------------------------------------------- */

export type GazetteEmailContext = z.infer<typeof Z_GazetteEmailContext>;

export const Z_GazetteEmailContext = z.union([
  z.literal('waiting_for_review'),
  z.literal('request_send_item'),
  z.literal('replacement'),
  z.literal('message_customer'),
  z.literal('message_external_partner'),
  z.literal('repairer_assigned'),
  z.literal('local_repairer_assigned'),
  z.literal('quote'),
  z.literal('claim_rejected'),
  z.literal('authorised_repair_complete'),
  z.literal('post_received'),
  z.literal('generic_close_case'),
  z.literal('assessments_differ'),
  z.literal('approved_for_repair'),
  z.literal('return_item_to_customer'),
  z.literal('repair_complete'),
  z.literal('postage_logged'),
  z.literal('ready_for_customer_collection'),
  z.literal('notify_authorized_repairer'),
  z.literal('initially_assessed'),
  z.literal('assigned_for_repair'),
]);

export type GazetteEmailParams = {
  context: GazetteEmailContext;

  /** Who is sending the email */
  from: string;

  /** Who is receiving the email */
  to: string;

  /** The subject of the email */
  subject: string;

  /** Template file used for the email */
  template: string;

  /** The substitutions inserted into the template */
  substitutions: { [key: string]: string };

  /** Mandrill tracking details */
  mandrill?: string;

  /** If true, the email will be sent via the backend. Remove when all emails migrated to BE... */
  shallBeSentViaBackend?: boolean;
};

export interface GEEmail extends BaseGazetteEntry, GazetteEmailParams {
  k: typeof GEK_Email;
}

export const GEK_Email = 8;

export type LinkCaseRelationship = 'master' | 'sub';
export const Z_LinkCaseRelationship = z.union([
  z.literal('master'),
  z.literal('sub'),
]);

export interface GELinkCase extends BaseGazetteEntry {
  k: typeof GEK_LinkCase;

  /** CaseID of the case we are linking to */
  case: string;

  /** The relationship between me and the other case. Fills in the blank: "This other case is my ____." - That's why it's called "is_my" */
  is_my: LinkCaseRelationship;
}

export const GEK_LinkCase = 9;

/** All the possible gazette entries. Add new ones to this ever-growing list. */
export type GazetteEntry =
  | GEFactUpdate
  | GEStateChange
  | GEActorChange
  | GECaseSpotlightChange
  | GEAttachment
  | GEChat
  | GEAcknowledge
  | GEEmail
  | GELinkCase;

/** A gazette - simply an array of entries. */
export type Gazette = GazetteEntry[];

/* -------------------------------------------------------------------------- */
/*                                  Zod Types                                 */
/* -------------------------------------------------------------------------- */

const Z_GazetteEntryBase = z.object({
  a: z.string(),
  k: z.number(),
  t: z.number(),
});

export const Z_GEFactUpdate = Z_GazetteEntryBase.merge(
  z.object({
    k: z.literal(GEK_FactUpdate),
    kv: z.record(z.any()),
  })
);

export const Z_GEStateChange = Z_GazetteEntryBase.merge(
  z.object({
    k: z.literal(GEK_StateChange),
    who: z.string(),
    newState: z.string(),
    oldState: z.string(),
  })
);

export const Z_GEActorChange = Z_GazetteEntryBase.merge(
  z.object({
    k: z.literal(GEK_ActorChange),
    role: z.string(),
    link: Z_PersonaLink,
  })
);

export const Z_GECaseSpotlightChange = Z_GazetteEntryBase.merge(
  z.object({
    k: z.literal(GEK_CaseSpotlightChange),
    add: z.array(z.string()).optional(),
    del: z.array(z.string()).optional(),
    spotlight: z.array(z.string()),
  })
);

export const Z_GEAttachment = Z_GazetteEntryBase.merge(
  z.object({
    k: z.literal(GEK_Attachment),
    url: z.string(),
    kind: z.string(),
  })
);

export const Z_GEChat = Z_GazetteEntryBase.merge(
  z.object({
    k: z.literal(GEK_Chat),
    body: z.string(),
    attachment: z.string().optional(),
  })
);

export const Z_GEAcknowledge = Z_GazetteEntryBase.merge(
  z.object({
    k: z.literal(GEK_Acknowledge),
    actor: Z_PersonaTypeFullKey,
    time: z.number(),
  })
);

export const Z_GELinkCase = Z_GazetteEntryBase.merge(
  z.object({
    k: z.literal(GEK_LinkCase),
    case: z.string(),
    is_my: Z_LinkCaseRelationship,
  })
);

export const Z_GEEmail = Z_GazetteEntryBase.merge(
  z.object({
    k: z.literal(GEK_Email),
    context: Z_GazetteEmailContext,
    from: z.string(),
    to: z.string(),
    subject: z.string(),
    template: z.string(),
    substitutions: z.record(z.string()),
    mandrill: z.string().optional(),
    shallBeSentViaBackend: z.boolean().optional(),
  })
);

export const Z_GazetteEntry = z.union([
  Z_GEFactUpdate,
  Z_GEStateChange,
  Z_GEActorChange,
  Z_GECaseSpotlightChange,
  Z_GEAttachment,
  Z_GEChat,
  Z_GEAcknowledge,
  Z_GELinkCase,
  Z_GEEmail,
]);

export const Z_Gazette = z.array(Z_GazetteEntry);
