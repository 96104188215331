import { ReactCaseFlowCase } from '@rabbit/bizproc/react';
import {
  usePortalMultipleDocumentEditor,
  DR_Consumer_Private,
  DR_Holding_Public,
  DR_Holding_Private,
  DR_Holding_Manufacturer,
} from '@rabbit/data/portal';
import {
  Address,
  FBD_Vendable,
  CaseFlowFacts,
  Z_GazetteEmailContext,
} from '@rabbit/data/types';
import { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { CaseflowContext } from '../context/CaseflowContext';
import { getConsumerURL, useAppInfo } from 'apps/sage/src/utils/helpers';
import { MANDRILL_TEMPLATES } from '@rabbit/bizproc/react';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { ClaimCaseEmailNotification } from 'apps/sage/src/utils/ClaimEmailNotification';

interface ClaimDetailEditorProps {
  caseFlowCase: ReactCaseFlowCase;
  target: 'customer_details' | 'claim_details';
  formData: any; //todo: type this
  setIsSubmitting: (state: boolean) => void;
  onComplete?: () => void;
}
export function ClaimDetailEditor({
  caseFlowCase,
  target,
  formData,
  setIsSubmitting,
  onComplete,
}: ClaimDetailEditorProps) {
  const context = useContext(CaseflowContext);
  const { t } = useTranslation();
  //todo: move everything to context?
  const { getHoldingData, alterCasePublicEmail } = context || {};
  const caseId = caseFlowCase.GetCaseId();
  const caseFacts = caseFlowCase?.GetAllFacts();
  const caseActors = caseFlowCase?.GetActors();

  const consumer_holding = caseFacts?.consumer_holding;
  const consumer = caseActors?.consumer;

  // TODO: as WS said below - repairer should not have access to private Consumer data
  // So we need to make a cloud function for edits here -DC
  const { body, isReady, commit, update } = usePortalMultipleDocumentEditor({
    // TODO: remove this, repairer should not have access to private Consumer data... -WS
    consumer_private: {
      type: DR_Consumer_Private,
      docid: consumer || '',
    },
    holding_public: {
      type: DR_Holding_Public,
      docid: consumer_holding || '',
    },
    // TODO: remove this, repairer should not have access to private Holding data... -WS
    holding_private: {
      type: DR_Holding_Private,
      docid: consumer_holding || '',
    },
    holding_manufacturer: {
      type: DR_Holding_Manufacturer,
      docid: consumer_holding || '',
    },
  });
  const appInfo = useAppInfo();

  /* -------------------------------------------------------------------------- */
  /*                            Update Claim Details                            */
  /* -------------------------------------------------------------------------- */
  async function updateClaimDetails() {
    const {
      consumer_private: prevConsumer,
      holding_public: prevHoldingPub,
      holding_private: prevHoldingPriv,
      holding_manufacturer: prevHoldingMan,
    } = body;

    if (
      !prevConsumer ||
      !prevHoldingPub ||
      !prevHoldingMan ||
      !prevHoldingPriv
    ) {
      setIsSubmitting(false);
      throw new Error('Unable to get previous data from documents!');
    }

    const {
      consumer_holding_purchase_location,
      consumer_holding_purchase_location_other,
      consumer_holding_purchase_country,
      store_not_listed,
      purchase_date,
      holding_vendable_id,
      holding_warranty_term,
      holding_faults,
      status,
      preliminary_assessment,
      consumer_issue_description,
      consumer_issue_type,
      consumer_issue_type_ref,
      consumer_holding_purchase_price,
    } = formData;

    // If values include a retailer from the database, then purchase_location_other should be cleared - and vice versa
    const newPurchaseLocation =
      consumer_holding_purchase_location_other && store_not_listed
        ? ''
        : consumer_holding_purchase_location?.docid ??
          caseFacts.consumer_holding_purchase_location ??
          '';

    const newPurchaseCountry =
      consumer_holding_purchase_location_other && store_not_listed
        ? consumer_holding_purchase_country
        : consumer_holding_purchase_location?.country ??
          prevHoldingPriv?.purchase_country ??
          '';

    const newPurchaseLocationOther = store_not_listed
      ? consumer_holding_purchase_location_other ??
        prevHoldingPriv?.purchase_location_other ??
        ''
      : null;

    // Let's prepare the data to update both the case and the consumer/holding documents

    let factUpdates: CaseFlowFacts = {
      holding_vendable_id,
      holding_warranty_term: {
        amount: holding_warranty_term,
        division: 'months',
      },
      consumer_holding_purchase_location: newPurchaseLocation,
      consumer_holding_purchase_location_other: newPurchaseLocationOther,
      consumer_holding_purchase_country: newPurchaseCountry,
      purchase_date: purchase_date,
      holding_faults,
      preliminary_assessment,
      consumer_issue_description,
      consumer_issue_type,
      consumer_issue_type_ref,
      consumer_holding_purchase_price,
    };

    // Updater below sometimes uses prev data from the 'wrong' collection as the safeguard
    // This is ok: If all goes well data for the same fields in related collections is the same
    // And if it isn't, then this brings it back to the proper, duplicated state

    const holdingPublicUpdates = {
      ...prevHoldingPub,
      vendable: holding_vendable_id ?? prevHoldingPub.vendable ?? '',
      purchase_time: purchase_date ?? prevHoldingPub.purchase_time ?? 0,
    };

    const holdingPrivateUpdates = {
      ...prevHoldingPriv,
      vendable: holding_vendable_id ?? prevHoldingPriv.vendable ?? '',
      purchase_location: newPurchaseLocation,
      purchase_country: newPurchaseCountry,
      purchase_location_other: newPurchaseLocationOther,
      purchase_price:
        consumer_holding_purchase_price ?? prevHoldingMan.purchase_price ?? {},
    };

    const holdingManufacturerUpdates = {
      ...prevHoldingMan,
      purchase_location: newPurchaseLocation,
      purchase_country: newPurchaseCountry,
      purchase_location_other: newPurchaseLocationOther,
      vendable: holding_vendable_id ?? prevHoldingPub.vendable ?? '',
      purchase_time: purchase_date ?? prevHoldingPub.purchase_time ?? 0,
      purchase_price:
        consumer_holding_purchase_price ?? prevHoldingMan.purchase_price ?? {},
    };

    // If the vendable is different, we need to fetch and update the relevant and holding data
    if (prevHoldingPriv.vendable !== holding_vendable_id) {
      const vendable = await FBD_Vendable.get(holding_vendable_id);

      if (!vendable) {
        setIsSubmitting(false);
        throw new Error('Unable to get new vendable data!');
      }
      factUpdates = {
        ...factUpdates,
        consumer_holding_name: vendable.title,
        consumer_holding_image: vendable.img?.[0] ?? '',
      };
    }

    // update case actions
    if (status && status !== caseFlowCase.GetCaseState()) {
      try {
        caseFlowCase.Alter_WithAction(`move_to_${status}`);
        // todo unassign repairer if moved to preliminary assessment
        // await sendEmailNotification(status);
        if (alterCasePublicEmail) {
          await ClaimCaseEmailNotification({
            status,
            caseFlowCase,
            appInfo,
            alterCasePublicEmail,
            t
          })
        }
        await caseFlowCase.Commit();
      } catch (err) {
        setIsSubmitting(false);
        toast.error(t('Failed to update claim details'));
        console.log(err);
      }
    }

    // first update the case
    try {
      caseFlowCase.Alter_Facts(factUpdates);
      await caseFlowCase.Commit();
    } catch (err) {
      setIsSubmitting(false);
      toast.error(t('Failed to update claim details'));
      console.log(err);
    }

    // Then update the holding documents
    try {
      update({
        consumer_private: prevConsumer,
        holding_public: holdingPublicUpdates,
        holding_private: holdingPrivateUpdates,
        holding_manufacturer: holdingManufacturerUpdates,
      });
      await commit();
    } catch (err) {
      toast.error(t('Failed to update claim details.'));
      console.log(err);
    } finally {
      setIsSubmitting(false);
      toast.success(t('Claim details updated sucessfully.'));
      onComplete && onComplete();
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                           Update Customer Details                          */
  /* -------------------------------------------------------------------------- */
  async function updateCustomerDetails() {
    const {
      consumer_private: prevConsumer,
      holding_public: prevHoldingPub,
      holding_private: prevHoldingPriv,
      holding_manufacturer: prevHoldingMan,
    } = body;

    if (
      !prevConsumer ||
      !prevHoldingPub ||
      !prevHoldingMan ||
      !prevHoldingPriv
    ) {
      setIsSubmitting(false);
      throw new Error('Unable to get previous data from documents!');
    }

    const {
      first_name,
      last_name,
      consumer_email,
      consumer_telephone,
      consumer_preferred_contact,
      line1,
      line2,
      town,
      state,
      postcode,
      country,
    } = formData;

    // Commented out untill address lists are fully implemented
    // Deep copy the address array to ensure no data is lost
    // const prevAddress = JSON.parse(JSON.stringify(prevConsumer.address ?? []));

    // let newAddressArray: Address[] = [];
    // if (Array.isArray(prevAddress)) {
    //   newAddressArray = [...prevAddress];
    // }

    const newAddressObject: Address = {
      id: '1', //hardcoded until address lists are fully implemented
      // id: String(newAddressArray.length + 1), // not perfect but should work for now
      line1: line1,
      line2: line2,
      town: town,
      state: state,
      postcode: postcode,
      country: country,
    };

    // newAddressArray.push(newAddressObject);
    // first update the case

    try {
      caseFlowCase.Alter_Facts({
        consumer_preferred_contact,
        consumer_telephone,
        consumer_email,
        consumer_name: `${first_name} ${last_name}`,
        outbound_address: newAddressObject,
      });
      await caseFlowCase.Commit();
    } catch (err) {
      setIsSubmitting(false);
      toast.error('Failed to update customer details');
      console.log(err);
    }

    // Then update the persona
    try {
      update({
        consumer_private: {
          ...prevConsumer,
          fullname: `${first_name ?? ''} ${last_name ?? ''}`,
          splitname: {
            first: first_name ?? prevConsumer.splitname?.first ?? '',
            last: last_name ?? prevConsumer.splitname?.last ?? '',
          },
          phone: consumer_telephone ?? prevConsumer.phone ?? '',
          preferred_contact:
            consumer_preferred_contact ?? prevConsumer.preferred_contact ?? [],
          address: [newAddressObject],
        },
        holding_manufacturer: prevHoldingMan,
        holding_private: prevHoldingPriv,
        holding_public: prevHoldingPub,
      });
      await commit();
    } catch (err) {
      toast.error('Failed to update customer details.');
      console.log(err);
    } finally {
      setIsSubmitting(false);
      toast.success('Customer details updated sucessfully.');
    }
  }

  useEffect(() => {
    (async () => {
      if (target === 'claim_details' && isReady) await updateClaimDetails();
      if (target === 'customer_details' && isReady)
        await updateCustomerDetails();
    })()
      .catch((err) => console.log(err))
      .finally(async () => {
        if (getHoldingData) await getHoldingData();
      });
  }, [isReady]);

  return null;
}

export default ClaimDetailEditor;
