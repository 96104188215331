import { z } from 'zod';
import { MakeCloudFunctionSpec } from '@rabbit/firebase/doctype';
import {
  Z_TenantLink,
  Z_Vendable,
  Z_Vendable_Private,
} from '@rabbit/data/types';
import { ICF_Base } from '../base';

export const ICF_CreateOrUpdateVendable = z
  .object({
    id: z.string().optional().nullable(),
    apiKey: z.string().optional(),
    tenantLink: Z_TenantLink,
    publicData: Z_Vendable.partial(),
    privateData: Z_Vendable_Private.partial(),
    isTest: z.boolean().optional(), // true = don't update Algolia
  })
  .merge(ICF_Base);

export const OCF_CreateOrUpdateVendable = z.object({
  message: z.string(),
  docid: z.string(),
});

export const CFC_CreateOrUpdateVendable = MakeCloudFunctionSpec(
  'api/legacy/cfccreateorupdatevendable',
  ICF_CreateOrUpdateVendable,
  OCF_CreateOrUpdateVendable
);
