import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { getRootPersonaFromLexicon } from '@rabbit/bizproc/client';
import { DeciderPerformDecision } from '@rabbit/bizproc/core';
import { VehicleInfo } from '@rabbit/data/types';
import {
  MANDRILL_TEMPLATES,
  useSageAPI,
  useSendEmail,
  VEHICLE_CATEGORY_OPTIONS,
} from '@rabbit/bizproc/react';
import {
  Button,
  formatUnixTime,
  getAgeFromDate,
  getCurrencyFormat,
  Heading,
  Input,
} from '@rabbit/elements/shared-components';
import {
  Address,
  DeciderOutput,
  MileageUnit,
  Money,
  PersonaTypeSingleLetter,
  PrincipalsFieldName,
  SRVInfo,
  SRVType,
} from '@rabbit/data/types';
import { useTranslation } from '@rabbit/mixmaster/react';
import {
  getConsumerURL,
  getWarranty,
  titleCase,
  toTitleCase,
  useAppInfo,
} from '@rabbit/sage/utils/helpers';
import { getUnixTime, formatDuration, format } from 'date-fns';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { CoverageOptions } from './PWCreateNewCoverageOptions';
import { ConsumerDetailsShape } from '../../forms/CreateNewConsumerDetails/CreateNewConsumerDetails';
import { CarRegistrationShape } from './PWCreateNewRegistration';
import productPlaceholder from '../../../../assets/images/car-placeholder.jpg';
import { parse } from 'tinyduration';
import { useNavigate } from 'react-router-dom';
import ROUTE_NAME from '@rabbit/sage/utils/url-constants';
import {
  BPIWarranty_WarrantyType,
  RegisterWarranty_ConsumerInfo,
} from '@rabbit/elements/shared-types';

export interface CreateNewRegistrationSummaryShape {
  salesRep: string;
}

export const validationSchema = Yup.object().shape({
  salesRep: Yup.string(),
});

interface CreateNewRegistrationSummaryProps {
  handleClose: () => void;
  onChange: any; //TODO
  data: CarRegistrationShape &
    ConsumerDetailsShape &
    CoverageOptions & {
      consumer_address: Address;
      mileage: string;
      mileageUnit: string;
      body: string;
      regDate: string;
      engineNo: string;
      colour: string;
    };
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}
export function PWCreateNewRegistrationSummary({
  handleClose,
  onChange,
  data,
  setIsLoading,
}: CreateNewRegistrationSummaryProps) {
  const { registerSRVHoldingWithWarranty } = useSageAPI();
  // console.log('data', data);

  const [expand, setExpand] = useState(false);
  const { t } = useTranslation();
  const appInfo = useAppInfo();
  const navigate = useNavigate();
  const { SE_Sage_VehicleRegistration } = useSendEmail();
  const [loading, setLoading] = useState(false);
  const [deciderOutput, setDeciderOutput] = useState<DeciderOutput | null>(
    null
  );

  const riskCategoryMap: Record<string, { label: string; value: string }> = {
    standard: { label: 'No risk', value: 'A' },
    'special-risk-b': { label: 'B', value: 'B' },
    'special-risk-c': { label: 'C', value: 'C' },
  };

  /* -------------------------- Perform the decision -------------------------- */
  useEffect(() => {
    // Hardcoded for testing and demo purposes
    const convertedMileage =
      data.mileageUnit && data.mileageUnit === 'mi'
        ? Number(data.mileage) * 1.609344
        : data.mileage;
    const duration = {
      label: data.warranty_duration.label
        .split(' ')
        .map((i: any) => toTitleCase(i))
        .join(' '),
      value: data.warranty_duration.value,
    };

    const claimLimit = {
      label: data.warranty_claim_limit.label,
      // slice to remove the currency symbol
      // Sorry for the hack job @Tolu @Vasco, had to do this for a quick fix - DC

      value:
        data.warranty_claim_limit.label !== 'PPOV'
          ? Number(data.warranty_claim_limit.value)
          : data.warranty_claim_limit.value,
    };
    const stipulated = {
      age: getAgeFromDate(data.regDate),
      mileage: convertedMileage,
      riskCategory: riskCategoryMap[data.vehicleCategory],
      duration,
      claimLimit,
      isCommercial: data.commercialVehicle === 'yes',
      is4x4: data.driveType === '4x4',
      isElectric: data.fuel === 'Electric',
      isHybrid: data.fuel === 'Hybrid',
      increasedLabourRate1: data.warranty_labour_rates.id === '1',
      increasedLabourRate2: data.warranty_labour_rates.id === '2',
      increasedLabourRate3: data.warranty_labour_rates.id === '3',
    };

    const output = DeciderPerformDecision(
      stipulated,
      data.warranty_template,
      data.warranty_offer
    );

    setDeciderOutput(output);
  }, [data.warranty_template, data.warranty_offer]);

  const initialValues: CreateNewRegistrationSummaryShape = {
    salesRep: '',
  };
  const defaultMileageUnit = t('CFG_COBRAND_MILEAGE_UNIT', 'km') as MileageUnit;

  const carRecord = [
    {
      label: 'Registration Plate',
      value: String(data.registrationNo).toUpperCase(),
    },
    { label: 'Make', value: data.make },
    { label: 'Model', value: data.model },
    { label: 'Version', value: data.version },
    { label: 'Body', value: data.body || '-' },
    {
      label: 'Registration date',
      value: data.regDate
        ? formatUnixTime(getUnixTime(new Date(data.regDate)), 'dd/MM/yyyy')
        : '-',
    },
    { label: 'Engine cc', value: data.engineCc || '-' },
    { label: 'Colour', value: data.colour || '-' },
    { label: 'Fuel', value: data.fuel || '-' },
    { label: 'Transmission', value: data.transmission || '-' },
    { label: 'Drive type', value: data.driveType || '-' },
    { label: 'Year of manufacture', value: data.yearOfManufacture || '-' },
    { label: 'VIN', value: data.chassisNo || '-' },
    { label: 'Engine number', value: data.engineNo || '-' },
    {
      label: 'Current mileage',
      value: data.mileage
        ? Number(data.mileage).toLocaleString(appInfo.country) +
            data.mileageUnit || defaultMileageUnit
        : '-',
    },
    {
      label: 'Last service date',
      value: data.last_service_date
        ? formatUnixTime(
            getUnixTime(new Date(data.last_service_date)),
            'dd/MM/yyyy'
          )
        : '-',
    },
    {
      label: 'MOT date expiration',
      value: data.tech_check_date
        ? formatUnixTime(
            getUnixTime(new Date(data.tech_check_date)),
            'dd/MM/yyyy'
          )
        : '-',
    },
    {
      label: 'Commercial vehicle',
      value: data.commercialVehicle ? titleCase(data.commercialVehicle) : '-',
    },
    {
      label: 'Vehicle Category',
      value: data.vehicleCategory
        ? VEHICLE_CATEGORY_OPTIONS.find((i) => i.value === data.vehicleCategory)
            ?.label
        : '-',
    },
    {
      label: 'Purchase price',
      value: data.purchasePrice
        ? getCurrencyFormat(
            data.purchasePrice.amount,
            data.purchasePrice.currency
          )
        : '-',
    },
  ];

  const handleSubmit = async (values: any) => {
    if (!deciderOutput) throw new Error('Decider output not found'); // todo: handle this better, e.g. show a message to the user or a loading state

    // TODO: Do vendable/holding registration
    setLoading(true);
    setIsLoading(true);

    const vehicleInfo: VehicleInfo = {
      ...data,
      brand: data.make,
      engineCc: Number(data.engineCc),
      yearOfManufacture: new Date(data.yearOfManufacture).getTime(),
      mileage: Number(data.mileage),
      mileageUnit:
        (data.mileageUnit as MileageUnit) ||
        (t('CFG_COBRAND_MILEAGE_UNIT', 'km') as MileageUnit), //MileageUnit.Kilometers,
      purchasePrice: {
        amount: Number(data.purchasePrice.amount),
        currency: data.purchasePrice.currency,
      } as Money,
      lastServiceDate:
        data.last_service_date && data.last_service_date !== '0000-00-00'
          ? formatUnixTime(
              new Date(data.last_service_date).getTime(),
              'dd/MM/yyyy'
            )
          : '',
      techCheckDate:
        data.tech_check_date && data.tech_check_date !== '0000-00-00'
          ? formatUnixTime(
              new Date(data.tech_check_date).getTime(),
              'dd/MM/yyyy'
            )
          : '',
      isCommercial: data.commercialVehicle === 'yes',
      riskCategory: data.vehicleCategory,
      // when things are manually filled these aren't being passed it seems? Adding them here but remove after testing if not needed
      version: data.version,
      body: data.body,
      regDate: data.regDate && data.regDate !== '0000-00-00'
      ? formatUnixTime(
          new Date(data.regDate).getTime(),
          'dd/MM/yyyy'
        )
      : '',
      colour: data.colour,
      isManuallyRegistered: data.manualInput,
    };
    console.log('vehicleInfo', vehicleInfo);
    
    const holding: SRVInfo = {
      type: SRVType.Vehicle,
      productInfo: vehicleInfo,
    };
    const warranty: BPIWarranty_WarrantyType = {
      startDate: data.warranty_start_date
        ? new Date(data.warranty_start_date)
        : null,
      options: [
        {
          key: 'duration',
          value: data.warranty_duration.value,
        },
        {
          key: 'claimLimit',
          value: data.warranty_claim_limit.value,
        },
        {
          key: 'increasedLabourRate',
          value: data.warranty_labour_rates.value,
        },
      ],
      salesRep: values.salesRep,
    };
    const consumerInfo: RegisterWarranty_ConsumerInfo = {
      firstName: data.first_name,
      lastName: data.last_name,
      consumerEmail: data.consumer_email,
      phoneNumber: data.phone_number,
    };
    if (data.consumer_address) consumerInfo.address = data.consumer_address;

    let regWarrantyLink: string | undefined;
    let regHoldingLink: string | undefined;

    try {
      const { warrantyLink, holdingLink } =
        await registerSRVHoldingWithWarranty({
          warrantor: getRootPersonaFromLexicon(
            t(PrincipalsFieldName),
            PersonaTypeSingleLetter.Warrantor
          ),
          holding,
          templateLink: data.warranty_template.docid,
          consumer: consumerInfo,
          warranty,
          deciderOutput,
          offerLink: data.warranty_offer?.docid,
        });
      regWarrantyLink = warrantyLink;
      regHoldingLink = holdingLink;
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      setLoading(false);
      setIsLoading(false);
      return;
    }

    if (regWarrantyLink) {
      const regWarranty = await getWarranty(regWarrantyLink);
      try {
        await SE_Sage_VehicleRegistration(
          data.consumer_email,
          appInfo.email_sender,
          appInfo.name,
          appInfo.email_main_template,
          MANDRILL_TEMPLATES.BODY_SAGE_VEHICLE_REGISTRATION_PW,
          data.first_name,
          formatUnixTime(
            getUnixTime(new Date(regWarranty?.startDate || 0)),
            'dd/MM/yyyy'
          ),
          formatUnixTime(
            getUnixTime(new Date(regWarranty?.endDate || 0)),
            'dd/MM/yyyy'
          ),
          formatUnixTime(getUnixTime(new Date()), 'dd/MM/yyyy'),
          String(data.registrationNo).toUpperCase(),
          data.make,
          data.model,
          Number(data.warranty_claim_limit.value)
            ? getCurrencyFormat(
                data.warranty_claim_limit.value,
                appInfo.currency
              )
            : data.warranty_claim_limit.value,
          data.mileage
            ? Number(data.mileage).toLocaleString(appInfo.country) +
                data.mileageUnit || defaultMileageUnit
            : '-',
          data.warranty_type ? data.warranty_template.title : '-',
          data.warranty_duration
            ? formatDuration(parse(data.warranty_duration.value as any))
            : '-',
          Number(data.warranty_labour_rates.value)
            ? getCurrencyFormat(
                data.warranty_labour_rates.value,
                appInfo.currency
              )
            : data.warranty_labour_rates.value || '-',
          `${getConsumerURL()}`,
          appInfo.templateLanguage
        );

        toast.success('Registration created successfully. Redirecting to detail page...');
        setTimeout(
          () => {
            navigate(`${ROUTE_NAME.REGISTRATIONS}/${regHoldingLink}`);
            handleClose();
          },
          5000
        );
      } catch (e: any) {
        console.error(e);
        toast.error(e.message);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {(props) => (
        <Form className="flex flex-col gap-4 px-5 pt-4">
          {/* Warranty info */}
          <div>
            <Heading kind="h4" className="mb-2">
              Warranty plan
            </Heading>
            <div className="flex flex-col gap-4">
              <div
                className={'font-nunito rounded-md border border-gray-300 p-4'}
                onClick={() => 0}
              >
                <div className="font-bold">{data.warranty_template.title}</div>
                <div className="mt-[10px] text-gray-700">
                  Start date:{' '}
                  {format(
                    new Date(data.warranty_start_date || Date.now()),
                    'dd/MM/yyyy'
                  )}
                </div>
                <div className="text-gray-700">
                  Term: {formatDuration(parse(data.warranty_duration.value))}
                </div>
                <div className="text-gray-600">
                  Claim limit:{' '}
                  {Number(data.warranty_claim_limit.value)
                    ? getCurrencyFormat(
                        data.warranty_claim_limit.value,
                        appInfo.currency
                      )
                    : data.warranty_claim_limit.value}
                </div>
                <div className="text-gray-600">
                  Labour rate:{' '}
                  {Number(data.warranty_labour_rates.value)
                    ? getCurrencyFormat(
                        data.warranty_labour_rates.value,
                        appInfo.currency
                      )
                    : data.warranty_labour_rates.value}
                </div>
              </div>
            </div>
          </div>

          {/* Selected car */}
          <div>
            <Heading kind="h4" className="mb-2">
              Vehicle details
            </Heading>
            <div className="flex flex-col gap-4">
              <div
                className={'font-nunito rounded-md border border-gray-300 p-4'}
                onClick={() => 0}
              >
                <div
                  className="grid grid-cols-[64px,1fr] items-center gap-4"
                  onClick={() => setExpand(!expand)}
                >
                  <div className="h-[64px] rounded-md bg-red-700">
                    <img
                      src={productPlaceholder}
                      className="h-[64px] w-[64px] object-cover"
                    />
                  </div>
                  <div className="flex h-fit cursor-pointer items-center justify-between">
                    <div>
                      <div className="font-bold text-black">
                        {data.make} {data.model} {data.version}
                      </div>
                      <div className="uppercase text-black">
                        {data.registrationNo}
                      </div>
                    </div>
                    <ChevronDownIcon
                      className={
                        'relative h-6 w-6 text-gray-900' +
                        (expand ? ' rotate-180' : '')
                      }
                    />
                  </div>
                </div>
                {expand && (
                  <div className="mt-6 grid grid-cols-3 gap-4">
                    {carRecord.map((record, index) => (
                      <div key={'record' + index} className="flex flex-col">
                        <span className="font-sm text-gray-500">
                          {record.label}
                        </span>
                        <div className="font-medium text-black">
                          {record.value}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Customer car */}
          <div>
            <Heading kind="h4" className="mb-2">
              Customer details
            </Heading>
            <div className="font-nunito flex flex-col rounded-md border border-gray-300 p-4">
              <div className="font-bold text-black">
                {data.first_name} {data.last_name}
              </div>
              <Heading kind="p">{data.consumer_email}</Heading>
              {data.consumer_address && (
                <Heading kind="p">
                  {data.consumer_address?.postcode}{' '}
                  {data.consumer_address?.line1}, {data.consumer_address?.line2}{' '}
                  {data.consumer_address?.town} {data.consumer_address?.state}{' '}
                  {data.consumer_address?.country}
                </Heading>
              )}
              <Heading kind="p">+{data.phone_number}</Heading>
            </div>
          </div>
          <div>
            <Input
              type="text"
              name="salesRep"
              label="Salesperson name"
              settings={{
                id: 'salesRep',
                placeholder: 'Enter salesperson name here',
              }}
            />
          </div>
          <div className="sticky bottom-0 -mb-2 flex w-full gap-4 bg-white py-4">
            <Button
              kind="primary"
              type="submit"
              loading={loading}
              disabled={loading}
            >
              Register warranty
            </Button>
            <Button kind="red" type="submit" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
