import { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {
  Button,
  ButtonIcon,
  LoadingSpinner,
  Modal,
  formatUnixTime,
} from '@rabbit/elements/shared-components';
import {
  SageConsumerDataShape,
  useGetConsumerData,
} from '@rabbit/bizproc/react';
import {
  FBD_Retailer_Public,
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
  Warranty,
  WarrantyStatus,
} from '@rabbit/data/types';
import RegistrationsOtherProductsSection from '../components/templates/registrations/other-products-table/RegistrationsOtherProductsSection';
import RegistrationsCustDetailsSection from '../components/templates/registrations/customer-details/RegistrationsCustDetailsSection';
import RegistrationsHoldingDetailsSection from '../components/templates/registrations/holding-details/RegistrationsHoldingDetailsSection';
import RegistrationsHoldingSummarySection from '../components/templates/registrations/holding-summary/RegistrationsHoldingSummarySection';
import { getFavouriteStore, getUserPermissions } from '../utils/helpers';
import { useTranslation } from 'react-i18next';
import { TenantType } from './RegistrationsView';
import CarRegistrationsHoldingSummarySection from '../components/templates/registrations/holding-summary/CarRegistrationsHoldingSummarySection';
import { UserContext } from '../context/UserContext';
import ROUTE_NAME from '../utils/url-constants';
import { Permissions } from '@rabbit/data/types';
import {
  Cog8ToothIcon,
  DocumentMinusIcon,
  PencilSquareIcon,
  PrinterIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { toast } from 'react-toastify';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useSageAPI } from '@rabbit/bizproc/react';
import { BL_Warranty } from '@rabbit/bizproc/core';
import ModalEditWarranty from '../components/organisms/ModalEditWarranty/ModalEditWarranty';
import { ConfigContext } from '@rabbit/config/context';
import RegistrationsWarrantyDetailsSection from '../components/templates/registrations/RegistrationsWarrantyDetailsSection/RegistrationsWarrantyDetailsSection';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RegistrationsDetailsViewProps {}

export function RegistrationsDetailsView(props: RegistrationsDetailsViewProps) {
  const { t } = useTranslation();
  const { config } = useContext(ConfigContext);
  const userContext = useContext(UserContext);
  const tenantLink = t('tenantLink');
  const userPermissions = getUserPermissions(tenantLink, userContext);
  const { warrantyVoid } = useSageAPI();

  const [storeAddress, setStoreAddress] = useState<string>('-');
  const [showWarrantyOptions, setShowWarrantyOptions] = useState(false);
  const [isVoiding, setIsVoiding] = useState(false);
  const [voidWarranty, setVoidWarranty] = useState<string>();
  const [editWarranty, setEditWarranty] = useState<string>();
  const [confirmation, setConfirmation] = useState('');
  const { pathname } = useLocation();
  const holdingId = pathname.split('/')[2];

  const data: SageConsumerDataShape | null = useGetConsumerData(holdingId);

  const { holding_proxy, allHoldings, consumer_private } = data ?? {};
  const latestWarranty: Warranty | undefined = BL_Warranty.getLatestWarranty(
    holding_proxy?.warranties
  );
  const [voided, setVoided] = useState(
    latestWarranty?.status === WarrantyStatus.VOIDED
  );

  const CFG_COBRAND_REGISTRATIONS_PRINT_SUMMARY_OPTION = t(
    'CFG_COBRAND_REGISTRATIONS_PRINT_SUMMARY_OPTION'
  );

  const isPremiumTenant =
    userContext?.accessToken?.[
      PersonaTypeSingleLetter.Warrantor + PersonaIdTypeSplitter + tenantLink
    ] === 'S' || userContext?.accessToken?.admin === true;

  let tenantType: TenantType = 'defaultTenants';
  if (tenantLink === 'WARRANTYIRELAND' || tenantLink === 'PINNACLEWARRANTIES')
    tenantType = 'carTenants';
  if (tenantLink === 'NUCOVER') tenantType = 'tableTenants';
  const warrantyInfo = BL_Warranty.getLatestWarranty(holding_proxy?.warranties);

  const voidWarrantyHandler = async () => {
    setIsVoiding(true);
    try {
      if (latestWarranty && latestWarranty?.warrantyLink) {
        await warrantyVoid({
          warrantyLink: latestWarranty.warrantyLink,
        });
        toast.success(t('Warranty voided successfully!'));
        setVoidWarranty(undefined);
        setVoided(true);
      }
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setIsVoiding(false);
    }
  };

  useEffect(() => {
    setVoided(
      BL_Warranty.getLatestWarranty(holding_proxy?.warranties)?.status ===
        WarrantyStatus.VOIDED
    );
  }, [holding_proxy?.warranties]);

  useEffect(() => {
    if (holding_proxy?.purchase_location) {
      getRetailerLabel(holding_proxy?.purchase_location)
        .then((res) => setStoreAddress(res ?? '-'))
        .catch((err) => console.log(err));
    } else if (
      holding_proxy?.purchase_location_other &&
      holding_proxy?.purchase_location_other !== ''
    ) {
      setStoreAddress(holding_proxy?.purchase_location_other);
    }
  }, [
    holding_proxy?.purchase_location,
    holding_proxy?.purchase_location_other,
  ]);

  const [favouriteStore, setFavouriteStore] = useState<string>('');

  useEffect(() => {
    if (allHoldings)
      (async () => {
        const res = await getFavouriteStore(allHoldings);
        setFavouriteStore(res);
      })().catch((err) => console.log(err));
  }, [allHoldings]);

  // Check if the path is from Warranty Overview and the user is not an owner/warrantor -VP
  if (
    pathname.includes(ROUTE_NAME.WARRANTY_OVERVIEW) &&
    !userPermissions?.includes(Permissions.Owner)
  )
    return <Navigate to={'/'} />;
  else if (
    !userPermissions?.includes(Permissions.RegistrationsEdit) &&
    !userPermissions?.includes(Permissions.RegistrationsView) &&
    !userPermissions?.includes(Permissions.Owner)
  )
    return <Navigate to={'/'} />;

  if (!holding_proxy || !consumer_private)
    return <LoadingSpinner size={'sm'} />;

  return (
    <div className="flex flex-col gap-5">
      {voided && (
        <div className="flex flex-col gap-2 rounded-md bg-red-100 p-4">
          <div className="font-nunito flex items-center gap-1 font-bold text-red-700">
            <InformationCircleIcon className="h-4 w-4 text-red-700" />{' '}
            {t('Warranty voided at')}{' '}
            {formatUnixTime(holding_proxy?.tupdate as number, 'dd/MM/yyyy')}
          </div>
          <span className="font-nunito text-red-700">
            {t('This warranty has been voided.')}
          </span>
        </div>
      )}
      {holding_proxy && (
        <div className="flex justify-end gap-2">
          {/* <div>
            <ButtonIcon
              kind="bgWhite"
              Icon={Cog8ToothIcon}
              onClick={() => setHistoryLog(true)}
              label="History log"
              iconLeft
            />
            { historyLog && latestWarranty?.warrantyLink && (
              <ModalHistoryLog
                handleClose={() => setHistoryLog(false)}
                warrantyLink={latestWarranty?.warrantyLink}
              />
            )}
          </div> */}
          <div className="relative">
            {config?.WARRANTIES.MANAGEMENT.SHOW_WARRANTY_OPTIONS_BTN && (
              <ButtonIcon
                kind="bgLightGreen"
                Icon={Cog8ToothIcon}
                onClick={() => setShowWarrantyOptions(true)}
                label={t('Warranty options')}
                iconLeft
              />
            )}
            {showWarrantyOptions && (
              <div className="absolute top-12 right-0 z-10">
                <div className="flex h-fit w-[448px] flex-col gap-4 rounded-lg bg-white p-5 shadow-lg">
                  <div className="font-nunito flex justify-between text-lg font-medium text-gray-500">
                    {t('Warranty options')}
                    <XMarkIcon
                      className="h-6 w-6 cursor-pointer"
                      onClick={() => setShowWarrantyOptions(false)}
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    {CFG_COBRAND_REGISTRATIONS_PRINT_SUMMARY_OPTION ===
                      'true' && (
                      <ButtonIcon
                        kind="bgLightGreen"
                        Icon={PrinterIcon}
                        onClick={() =>
                          window.open(`${pathname}/print`, '_blank')
                        }
                        label={t('Print warranty summary')}
                        iconLeft
                      />
                    )}
                    {isPremiumTenant &&
                      config?.WARRANTIES.MANAGEMENT.CAN_VOID_WARRANTIES && (
                        <ButtonIcon
                          kind="bgLightGreen"
                          Icon={DocumentMinusIcon}
                          onClick={() => {
                            setVoidWarranty(holding_proxy.docid);
                            setShowWarrantyOptions(false);
                          }}
                          label={t('Void warranty')}
                          iconLeft
                          disabled={voided}
                        />
                      )}
                    {
                      // Check if isPremiumTenant can edit warranty with the tenant config
                      isPremiumTenant &&
                        config?.WARRANTIES.MANAGEMENT.CAN_EDIT_WARRANTIES && (
                          <ButtonIcon
                            kind="bgLightGreen"
                            Icon={PencilSquareIcon}
                            onClick={() => {
                              setEditWarranty(holding_proxy.docid);
                              setShowWarrantyOptions(false);
                            }}
                            label={t('Edit warranty')}
                            iconLeft
                            disabled={voided}
                          />
                        )
                    }
                  </div>
                  <Button
                    kind="outline"
                    className="border-gray-200"
                    onClick={() => setShowWarrantyOptions(false)}
                  >
                    {t('Close')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {tenantType === 'carTenants' ? (
        <CarRegistrationsHoldingSummarySection
          allHoldingsList={allHoldings ?? undefined}
          holdingId={holdingId}
          holding_proxy={holding_proxy ?? undefined}
          storeAddress={storeAddress}
        />
      ) : (
        <RegistrationsHoldingSummarySection
          allHoldingsList={allHoldings ?? undefined}
          holdingId={holdingId}
          holding_proxy={holding_proxy ?? undefined}
          storeAddress={storeAddress}
        />
      )}
      <RegistrationsWarrantyDetailsSection holding_proxy={holding_proxy} />
      <RegistrationsCustDetailsSection
        allHoldingsList={allHoldings ?? undefined}
        consumer_private={consumer_private ?? undefined}
        favouriteStore={favouriteStore}
      />
      <RegistrationsHoldingDetailsSection
        holding_proxy={holding_proxy}
        allHoldingsList={allHoldings}
        consumer_private={consumer_private}
        storeAddress={storeAddress}
        holdingId={holdingId}
      />

      {t('tenantLink') === 'SHELTA' && (
        <RegistrationsOtherProductsSection
          allHoldingsList={allHoldings ?? null}
        />
      )}
      {voidWarranty && (
        <Modal
          isLoading={isVoiding}
          className="h-fit w-[350px] rounded-md bg-white"
          kind={'generic'}
          settings={{ handleClose: () => setVoidWarranty(undefined) }}
        >
          <div className="font-nunito flex flex-col gap-3 px-6 pt-6 pb-3">
            <div className="flex flex-col items-center gap-4">
              <InformationCircleIcon className="h-8 w-8 text-gray-400" />
              <div className="text-md text-center text-lg text-gray-500">
                {t(
                  'You are about to void this warranty. Are you sure you want to continue?'
                )}
              </div>
              <div className="text-medium text-gray-900">
                {t('To confirm this, type the')}
                <strong>{t('warranty ID')}</strong> {t('below')}.
              </div>
              <input
                type="text"
                className="font-nunito focus:border-primary-600 w-full rounded-lg border-[1px] border-gray-300 bg-gray-50 px-4 py-3 text-base text-gray-800 placeholder-gray-400 !shadow-none !outline-none !ring-0"
                placeholder="[WarrantyID]"
                onChange={(e) => setConfirmation(e.target.value)}
              />
            </div>
            <div className="flex gap-4">
              <Button
                kind="primary"
                type="submit"
                loading={isVoiding}
                disabled={
                  confirmation !==
                  warrantyInfo?.warrantyLink?.slice(0, 5).toUpperCase()
                }
                onClick={() => voidWarrantyHandler()}
              >
                {t('Void warranty')}
              </Button>
              <Button
                kind="outline"
                type="button"
                onClick={() => setVoidWarranty(undefined)}
              >
                {t('No, cancel')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {editWarranty && latestWarranty?.warrantyLink && (
        <ModalEditWarranty
          handleClose={() => setEditWarranty(undefined)}
          warrantyLink={latestWarranty?.warrantyLink}
        />
      )}
    </div>
  );
}

export default RegistrationsDetailsView;

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

const getRetailerLabel = async (locationId: string) => {
  if (!locationId) return undefined;
  const retailer = await FBD_Retailer_Public.get(locationId || '');
  return retailer?.name
    ? retailer?.name + ' - ' + retailer?.address?.town
    : undefined;
};
