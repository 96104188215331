import { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  ButtonLink,
  Input,
  Modal,
  ModalSettingsShape,
} from '@rabbit/elements/shared-components';
import {
  LIST_CURRENCIES,
  useSendEmail,
  useSageAPI,
} from '@rabbit/bizproc/react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  getDefaultAddress,
  getPartnerLabelByValue,
  getPartnerOptions,
  useAppInfo,
} from '@rabbit/sage/utils/helpers';
import { EditAddAddressModal } from '../../molecules/account-management/EditAddAddressModal/EditAddAddressModal';
import {
  Address,
  BusinessUserInviteType,
  DTTenant_Public,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';
import ROUTE_NAME from '@rabbit/sage/utils/url-constants';
import { SelectOptionShape } from '@rabbit/elements/shared-types';

interface FormValuesShape {
  partner_name: string;
  partner_email: string;
  partner_type: PersonaTypeSingleLetter[];
  partner_phone_number: string;
  partner_phone_number_e164?: string;
  partner_addresses?: Address[];
  increased_labour_rate: boolean;
  labour_rate?: { amount: string; currency: string } | null;
  labour_rate_tier_1?: { amount: string; currency: string };
  labour_rate_tier_2?: { amount: string; currency: string };
  labour_rate_tier_3?: { amount: string; currency: string };
}

export interface ModalNewEditPartnerProps {
  handleClose: () => void;
  handleDelete: () => void;
  partner?: DTTenant_Public & { personas?: any[] }; // TODO: Change to official
  modalSettings: ModalSettingsShape;
}

const inputTypeCurrencyOverrideOptions: Array<SelectOptionShape> =
  LIST_CURRENCIES.map((currency, index) => ({
    id: `${index}`,
    label: currency.code + '/h',
    value: currency.code,
  }));

export function ModalNewEditPartner({
  handleClose,
  handleDelete,
  partner,
  modalSettings,
}: ModalNewEditPartnerProps) {
  const {
    generateBusinessUserInvite,
    getPartnerTenantData,
    updatePartnerTenantData,
    isReady,
  } = useSageAPI();
  const appInfo = useAppInfo();
  const navigate = useNavigate();
  const formRef = useRef(null) as any;
  // const { createConsumerProfile, editConsumerProfile } = useConsumerProfile(
  //   partner?.docid ?? null
  // );

  const { SE_Sage_PartnerInvitation } = useSendEmail();
  
  const isNewPartner = partner?.docid ? false : true;
  const [isLoading, setIsLoading] = useState(!isNewPartner);
  const [submitAction, setSubmitAction] = useState<string | undefined>('save');
  //const [manageAddressesModal, setManageAddressesModal] = useState(false);
  const [addressModal, setAddressModal] = useState<
    'edit' | 'new' | undefined
  >();

  const { t } = useTranslation();
  const tenantLink = t('tenantLink');

  const [currentAddresses, setCurrentAddresses] = useState<any>(
    partner?.addresses || []
  );
  const [defaultAddress, setDefaultAddress] = useState<Address | undefined>(
    partner?.addresses ? getDefaultAddress(partner.addresses) : undefined
  );

  const onAddressAdded = async (
    address: Address,
    mode: 'edit' | 'new',
    kind: 'user' | 'tenant' | 'partner'
  ) => {
    if (kind === 'partner') {
      if (mode === 'new') {
        const newAddresses = [...currentAddresses, address];
        setCurrentAddresses(newAddresses);
      }
      if (mode === 'edit') {
        const newAddresses = currentAddresses.filter((item: any) => {
          return item.id !== address.id;
        });
        newAddresses.push(address);

        // Set isDefault to false for remaining items if address.isDefault is true
        if (address.isDefault) {
          newAddresses.forEach((item: any) => {
            if (item.id !== address.id) {
              item.isDefault = false;
            }
          });
        }
        setCurrentAddresses(newAddresses);
      }
    }
  };

  const PinnacleLabourRatesSchema = {
    labour_rate: Yup.object({
      amount: Yup.number()
        .required('Please enter labour rate')
        .min(1, 'Amount must be bigger than zero'),
      currency: Yup.string().required('Please select currency'),
    }),
    labour_rate_tier_1: Yup.object({
      amount: Yup.number().min(1, 'Amount must be bigger than zero'),
      currency: Yup.string(),
    }),
    labour_rate_tier_2: Yup.object({
      amount: Yup.number().min(1, 'Amount must be bigger than zero'),
      currency: Yup.string(),
    }),
    labour_rate_tier_3: Yup.object({
      amount: Yup.number().min(1, 'Amount must be bigger than zero'),
      currency: Yup.string(),
    }),
  };

  const schema = Yup.object({
    partner_name: Yup.string().required('Please enter a name'),
    partner_email: Yup.string()
      .trim()
      .required('Required')
      .email('Please enter a valid email address'),
    partner_phone_number: Yup.string()
      .trim()
      .required('Please enter a phone number'),
    partner_type: isNewPartner
      ? Yup.array().required('Please select a type')
      : Yup.array(),
    increased_labour_rate: Yup.boolean(),
    ...(tenantLink === 'PINNACLEWARRANTIES' && PinnacleLabourRatesSchema),
  });

  const PinnacleLabourRatesInitValues = {
    labour_rate: { amount: '', currency: appInfo.currency },
    labour_rate_tier_1: { amount: '', currency: appInfo.currency },
    labour_rate_tier_2: { amount: '', currency: appInfo.currency },
    labour_rate_tier_3: { amount: '', currency: appInfo.currency },
  };

  const [initialValues, setInitialValues] = useState<FormValuesShape>({
    partner_name: partner?.orgName ?? '',
    partner_email: partner?.email ? partner.email : '',
    partner_phone_number: partner?.phone ? partner.phone : '',
    partner_phone_number_e164: partner?.phone ? partner.phone : '',
    partner_type: (partner?.personas && partner?.personas[0]) || '',
    increased_labour_rate: false,
    labour_rate: null,
    ...(tenantLink === 'PINNACLEWARRANTIES' && PinnacleLabourRatesInitValues),
  });

  useEffect(() => {
    if (!currentAddresses) return;
    const defaultAddress = getDefaultAddress(currentAddresses);
    setDefaultAddress(defaultAddress);
  }, [currentAddresses]);

  useEffect(() => {
    if (!partner?.addresses) return;
    const currentAddresses = partner?.addresses;
    setCurrentAddresses(currentAddresses);
  }, [partner]);

  useEffect(() => {
    if (isReady) {
      if (partner?.docid) {
        setIsLoading(true);
        (async () => {
          const res = await getPartnerTenantData({
            partnerTenant: partner?.docid,
            userTenant: t('tenantLink'),
          });
          if (!res.private && !res.public) {
            toast.error(
              'Failed to get the data for this partner, please contact iWarranty.'
            );
            handleClose();
          } else {
            const partnerSettings =
              res.private?.partnerSettings?.filter(
                (i) => i.partner === t('tenantLink')
              ) ?? [];

            // TODO: this might be slightly more complicated in the future. It's not even currently needed, but ehh. Hopefully it'll be useful in the future
            const partnerType = res.private.rootPersonas.warrantyDealer
              ? [
                  PersonaTypeSingleLetter.WarrantyDealer,
                  PersonaTypeSingleLetter.Retailer,
                ]
              : [PersonaTypeSingleLetter.Repairer];

            if (formRef.current) {
              formRef.current.setFieldValue('partner_type', partnerType);
              formRef.current.setFieldValue(
                'increased_labour_rate',
                partnerSettings.length >= 1 &&
                  partnerSettings[0]?.labourRates &&
                  (partnerSettings[0]?.labourRates?.tier1 ||
                    partnerSettings[0]?.labourRates?.tier2 ||
                    partnerSettings[0]?.labourRates?.tier3)
                  ? true
                  : false
              );
              formRef.current.setFieldValue('labour_rate', null);
              if (tenantLink === 'PINNACLEWARRANTIES') {
                formRef.current.setFieldValue('labour_rate', {
                  amount:
                    partnerSettings[0]?.labourRates?.default?.toString() ?? '',
                  currency:
                    partnerSettings[0]?.labourRates?.currency ??
                    appInfo.currency,
                });
                formRef.current.setFieldValue('labour_rate_tier_1', {
                  amount:
                    partnerSettings[0]?.labourRates?.tier1?.toString() ?? '',
                  currency:
                    partnerSettings[0]?.labourRates?.currency ??
                    appInfo.currency,
                });
                formRef.current.setFieldValue('labour_rate_tier_2', {
                  amount:
                    partnerSettings[0]?.labourRates?.tier2?.toString() ?? '',
                  currency:
                    partnerSettings[0]?.labourRates?.currency ??
                    appInfo.currency,
                });
                formRef.current.setFieldValue('labour_rate_tier_3', {
                  amount:
                    partnerSettings[0]?.labourRates?.tier3?.toString() ?? '',
                  currency:
                    partnerSettings[0]?.labourRates?.currency ??
                    appInfo.currency,
                });
              }
            }
            setIsLoading(false);
          }
        })().catch((e) => {
          console.error(e);
          toast.error('Something went wrong, please contact iWarranty.');
          handleClose();
        });
      }
    }
  }, [partner?.docid, isReady]);

  const handleSubmit = async (values: FormValuesShape) => {
    setIsLoading(true);
    const partnerData = {
      email: values.partner_email,
      orgName: values.partner_name,
      phone: values.partner_phone_number_e164 ?? values.partner_phone_number,
      type: BusinessUserInviteType.Partner,
      personas: Array.isArray(values.partner_type)
        ? values.partner_type
        : [values.partner_type],
      addresses: currentAddresses,
      tenantLink: t('tenantLink'),
      firstName: '', //API expects this, but kept empty until FE includes this field
      lastName: '', //API expects this, but kept empty until FE includes this field
      settings: [
        {
          partner: t('tenantLink'),
          labourRates: {
            default: values?.labour_rate?.amount
              ? Number(values.labour_rate.amount)
              : null,
            tier1: values.labour_rate_tier_1?.amount
              ? Number(values.labour_rate_tier_1?.amount)
              : null,
            tier2: values.labour_rate_tier_2?.amount
              ? Number(values.labour_rate_tier_2?.amount)
              : null,
            tier3: values.labour_rate_tier_3?.amount
              ? Number(values.labour_rate_tier_3?.amount)
              : null,
            currency: appInfo.currency,
          },
        },
      ],
    };

    if (isNewPartner) {
      const res = await generateBusinessUserInvite({
        inviteData: partnerData,
        baseUrl: window.location.origin,
        emailParams: {
          from: appInfo.email_sender,
          business_name: appInfo.name ?? '',
          business_logo: appInfo.logo ?? '',
          main_template: appInfo.email_main_template,
          partner_type: getPartnerLabelByValue(partnerData.personas[0], t('tenantLink')) ??'',
        },
        tenantLink: tenantLink,
      });

      if (!res) {
        toast.error('Something went wrong, please try again. ');
        setIsLoading(false);
        return console.log('error', res);
      }
      toast.success('Partner invitation sent successfully.');
      if (res.doc.docid)
        navigate(ROUTE_NAME.MANAGE + '/partner/' + res.doc.docid);
    } else {
      // TODO: Calling function directly here for now until useSageApi hook is fully implemented - then it should be moved there

      if (partner?.docid) {
        const res = await updatePartnerTenantData({
          userTenant: t('tenantLink'),
          partnerTenant: partner?.docid,
          formData: {
            orgName: values.partner_name,
            phone: values.partner_phone_number,
            addresses: currentAddresses,
            settings: [
              {
                partner: t('tenantLink'),
                labourRates: {
                  default: values?.labour_rate?.amount
                    ? Number(values.labour_rate.amount)
                    : null,
                  tier1: values.labour_rate_tier_1?.amount
                    ? Number(values.labour_rate_tier_1?.amount)
                    : null,
                  tier2: values.labour_rate_tier_2?.amount
                    ? Number(values.labour_rate_tier_2?.amount)
                    : null,
                  tier3: values.labour_rate_tier_3?.amount
                    ? Number(values.labour_rate_tier_3?.amount)
                    : null,
                  currency: appInfo.currency,
                },
              },
            ],
          },
        });

        if (!res) {
          toast.error('Something went wrong, please try again.');
          setIsLoading(false);
          return console.log('error', res);
        }

        toast.success('Partner updated successfully.');
      }
    }

    setIsLoading(false);
    handleClose();
  };

  return (
    <Modal
      kind="generic"
      settings={modalSettings}
      className="m-auto w-[724px] rounded-md border bg-white"
      isLoading={isLoading}
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={true}
        validateOnBlur={false}
        innerRef={formRef}
      >
        {(props) => (
          <Form className="mt-2 w-full px-5">
            <div className="w-full py-2">
              <div className="flex w-full gap-4">
                <Input
                  type="text"
                  label="Company name*"
                  name="partner_name"
                  settings={{
                    id: 'name',
                    hint: '*required',
                  }}
                />
                <Input
                  type="email"
                  label="Email*"
                  name="partner_email"
                  settings={{
                    id: 'email',
                    disabled: !isNewPartner ? true : false,
                    hint: '*required',
                  }}
                />
              </div>
            </div>
            <div className="flex w-full gap-4 py-2">
              <Input
                type="phone"
                name="partner_phone_number"
                label="Phone number*"
                settings={{
                  placeholder: 'Phone number',
                  hint: '*required',
                }}
              />
              {isNewPartner && (
                <Input
                  type="select"
                  name="partner_type"
                  label="Type*"
                  settings={{
                    id: 'partner_type',
                    placeholder: 'Please select an option',
                    hint: '*required',
                    options: getPartnerOptions(t('tenantLink')),
                    disabled: !isNewPartner ? true : false,
                    isMulti: false,
                  }}
                />
              )}
            </div>
            <div>
              <ButtonLink
                label="Partner address"
                onClick={() =>
                  currentAddresses.length > 0
                    ? setAddressModal('edit')
                    : setAddressModal('new')
                }
              >
                {defaultAddress ? (
                  <div>
                    {defaultAddress?.postcode} {defaultAddress?.line1},{' '}
                    {defaultAddress?.line2} {defaultAddress?.town}{' '}
                    {defaultAddress?.state} {defaultAddress?.country}
                  </div>
                ) : (
                  <div>Add address</div>
                )}
              </ButtonLink>
            </div>
            {tenantLink === 'PINNACLEWARRANTIES' && (
              <div className="mt-4 flex flex-col gap-4">
                <div className="grid grid-cols-2 gap-4">
                  <Input
                    type="currency"
                    name="labour_rate"
                    label="Default labour rate*"
                    settings={{
                      id: 'labour_rate',
                      placeholder: 'Amount',
                      hint: '*required',
                      currency: appInfo.currency,
                      inputTypeCurrencyOverrideOptions,
                    }}
                  />
                </div>
                <div>
                  <Input
                    type="checkbox"
                    name="increased_labour_rate"
                    settings={{
                      checkboxLabel: 'Increase labour rate',
                    }}
                  />
                </div>
                {props.values.increased_labour_rate && (
                  <div className="grid grid-cols-3 gap-4">
                    <Input
                      type="currency"
                      name="labour_rate_tier_1"
                      label="Tier 1"
                      settings={{
                        id: 'labour_rate_tier_1',
                        placeholder: 'Amount',
                        currency: appInfo.currency,
                        inputTypeCurrencyOverrideOptions,
                      }}
                    />
                    <Input
                      type="currency"
                      name="labour_rate_tier_2"
                      label="Tier 2"
                      settings={{
                        id: 'labour_rate_tier_2',
                        placeholder: 'Amount',
                        currency: appInfo.currency,
                        inputTypeCurrencyOverrideOptions,
                      }}
                    />
                    <Input
                      type="currency"
                      name="labour_rate_tier_3"
                      label="Tier 3"
                      settings={{
                        id: 'labour_rate_tier_3',
                        placeholder: 'Amount',
                        currency: appInfo.currency,
                        inputTypeCurrencyOverrideOptions,
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {/* {
              !isNewPartner && manageAddressesModal && (
                <ModalManageAddresses
                  data={partner}
                  handleClose={() => setManageAddressesModal(false)}
                />
              )
            } */}
            {/* Disabling deactivate toggle for now
            {partner?.docid && (
              <div className="font-nunito mt-4 flex justify-between gap-4 rounded-md border border-gray-300 bg-gray-50 px-4 py-2 text-base text-gray-500 opacity-50">
                Deactivate
                <Toggle checked={false} onChange={() => 0} disabled={true} />
              </div>
            )} */}
            <div className="mt-4 flex gap-5 py-2">
              <Button
                loading={isLoading}
                kind="primary"
                size="md"
                className="shrink-0 grow"
                onClick={() => {
                  setSubmitAction('save');
                  props.handleSubmit();
                }}
                children="Save"
              />
            </div>
          </Form>
        )}
      </Formik>
      {addressModal && (
        <EditAddAddressModal
          handleClose={() => setAddressModal(undefined)}
          mode={addressModal}
          kind={'partner'}
          address={
            currentAddresses.length > 0
              ? currentAddresses[0]
              : {
                  line1: '',
                  line2: '',
                  town: '',
                  state: '',
                  postcode: '',
                  country: '',
                  isDefault: true,
                }
          }
          handleSubmit={onAddressAdded}
          handleDelete={async (address) =>
            setCurrentAddresses((current: Address[]) =>
              current.filter((i) => i.line1 !== address.line1)
            )
          }
        />
      )}
    </Modal>
  );
}
