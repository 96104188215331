import { useContext, useEffect, useState } from 'react';
import {
  formatUnixTime,
  Button,
  TabsGeneric,
  formatPhoneNumber,
  ButtonIcon,
  Modal,
} from '@rabbit/elements/shared-components';
import { useParams } from 'react-router-dom';
import { useGetSagePartners, useSageAPI } from '@rabbit/bizproc/react';
import {
  getPartnerLabelByValue,
  getUserTypeFromPrincipals,
  useAppInfo,
} from '../utils/helpers';
import Skeleton from 'react-loading-skeleton';
import { InformationCircleIcon, PencilIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { DTBusiness_User_Invite, DTTenant_Public } from '@rabbit/data/types';
import { formatAddress } from '@rabbit/elements/shared-components';
import TabPartnerRegistrations from '../components/organisms/TabPartnerRegistrations/TabPartnerRegistrations';
import TabPartnerPricing from '../components/organisms/TabPartnerPricing/TabPartnerPricing';
import { toast } from 'react-toastify';
import { ModalNewEditPartner } from '../components/organisms/ModalNewEditPartner/ModalNewEditPartner';
import { ConfigContext } from '@rabbit/config/context';

export interface FormValues {
  beginDate?: Date | undefined;
  endDate?: Date | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PartnerDetailViewProps {}

export function PartnerDetailView(props: PartnerDetailViewProps) {
  const { resendBusinessUserInvite } = useSageAPI();
  const { id: partnerId } = useParams<'id'>();
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const tenantLink = t('tenantLink');
  const { config } = useContext(ConfigContext);
  const { getSingleInvite, getSinglePartner } =
    useGetSagePartners(tenantLink) || {};
  const [isNewEditModalOpen, setIsNewEditModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>();

  const [partner, setPartnerDetails] = useState<DTTenant_Public | undefined>(
    undefined
  );
  const [invite, setInviteDetails] = useState<DTBusiness_User_Invite | null>(
    null
  );

  const [holdings, setHoldings] = useState<any[]>([]);
  const [sendingInvitation, setSendingInvitation] = useState(false);

  const addEditModalSettings = {
    title: modalTitle,
    handleClose: () => setIsNewEditModalOpen(false),
  };
  const confirmationModalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: t(
        'You are about to delete this partner. You will lose all information. Are you sure you want to continue?'
      ),
      primaryButtonText: t('Yes'),
      outlineButtonText: t('No, cancel'),
      handleClose: () => setIsConfirmationModalOpen(false),
      handlePrimaryClick: async () =>
        console.log('delete --> selectedRowToDelete'),
      handleOutlineClick: () => setIsConfirmationModalOpen(false),
    },
  };

  const handleEditModal = (
    title: string,
    partnerDetails?: DTBusiness_User_Invite | DTTenant_Public
  ) => {
    setModalTitle(title);
    setPartnerDetails(partnerDetails);
    setIsNewEditModalOpen(true);
  };

  useEffect(() => {
    // tenantLink can apparently sometimes be just "tenantLink" for a moment while veils are loading
    if (tenantLink && tenantLink !== 'tenantLink') {
      if (partnerId) {
        if (partnerId.startsWith(tenantLink + '_')) {
          getSingleInvite(partnerId)
            .then((res) => {
              setInviteDetails(res);
            })
            .catch((err) => {
              console.log('err', err);
            });
        } else {
          getSinglePartner(partnerId)
            .then((res) => {
              setPartnerDetails(res);
            })
            .catch((err) => {
              console.log('err', err);
            });
        }
      }
    }
  }, [tenantLink, partnerId]);

  const getLastHoldingRegistered = () => {
    if (holdings.length) {
      const lastWarranty = holdings.reduce((prev, current) =>
        prev.created_time > current.created_time ? prev : current
      );
      return formatUnixTime(lastWarranty.created_time, 'dd/MM/yyyy');
    }
    return '-';
  };

  const partnerTabs = [
    {
      label: t('Registrations'),
      children: partner ? (
        <TabPartnerRegistrations
          partnerId={partner.docid}
          onHoldingsChange={(holdings) => setHoldings(holdings)}
        />
      ) : (
        <div>{t('Registrations are not available.')}</div>
      ),
    },
  ];

  if (config.WARRANTIES.MANAGEMENT.CAN_EDIT_PRICE_PLANS) {
    partnerTabs.push({
      label: t('Pricing'),
      children: partner ? (
        <TabPartnerPricing
          partnerTenantLink={partner.docid}
          warrantorLink={`W:${t('tenantLink')}`}
        />
      ) : (
        <div>{t('Pricing is not available.')}</div>
      ),
    });
  }

  const getPartnerLabelType = () => {
    if (partner?.principals) {
      return (
        getPartnerLabelByValue(
          getUserTypeFromPrincipals(partner.principals),
          t('tenantLink')
        ) || '-'
      );
    }
    if (invite?.personas[0]) {
      return getPartnerLabelByValue(invite?.personas[0], t('tenantLink'));
    }
    return '-';
  };

  const onResendInvite = async () => {
    if (invite?.docid) {
      setSendingInvitation(true);
      const res = await resendBusinessUserInvite({
        docid: invite?.docid,
        baseUrl: window.location.origin,
        emailParams: {
          from: appInfo.email_sender,
          business_name: appInfo.name ?? '',
          business_logo: appInfo.logo ?? '',
          main_template: appInfo.email_main_template,
          partner_type: getPartnerLabelType(),
        },
        tenantLink: tenantLink,
      });

      setSendingInvitation(false);
      if (res.doc) {
        toast.success(t('Invite resent successfully'));
      } else {
        toast.error(t('Error resending invite'));
      }
    }
  };

  return (
    <div className="flex flex-col gap-5">
      {invite && invite.status === 'pending' && (
        <div className="text-primary-700 overflow-hidden rounded-md bg-green-100 p-4">
          {invite?.expiryDate && new Date().getTime() < invite?.expiryDate ? (
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-1 text-lg font-semibold">
                <InformationCircleIcon className="h-5 w-5" />{' '}
                {t('Invite sent via email.')}
              </div>
              <div className="text-md flex">
                {t('Waiting for partner to accept. The link will expire at')}{' '}
                {formatUnixTime(invite?.expiryDate, 'dd/MM/yyyy hh:mm:ss a')}.
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-1 text-lg font-semibold">
                <InformationCircleIcon className="h-5 w-5" />{' '}
                {t('Invitation expired.')}
              </div>
              <div className="text-md flex">
                {t(
                  'Partner did not accept the invitation. Press the button below to resend.'
                )}
              </div>
              <div>
                <Button
                  kind="primary"
                  onClick={onResendInvite}
                  loading={sendingInvitation}
                >
                  {t('Resend invitation')}
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      {partner && (
        <div className="flex justify-end">
          <div className="inline-flex">
            <ButtonIcon
              type="primary"
              label="Edit partner"
              kind={'bgLightGreen'}
              Icon={PencilIcon}
              onClick={() => handleEditModal('Edit partner', partner)}
              iconLeft={true}
            />
          </div>
        </div>
      )}
      <div className="flex justify-between overflow-hidden rounded-md border border-gray-300 bg-gray-100 p-4">
        {!invite && !partner ? (
          <>
            <div className="flex w-full grow">
              <div className="ml-5 flex w-full flex-col items-center justify-center">
                <div className="w-full">
                  <Skeleton
                    count={3}
                    baseColor="#fff"
                    highlightColor="#f1f1f1"
                  />
                </div>
              </div>
            </div>

            <div className="flex w-full grow items-center gap-[75px] px-4">
              <div className="w-full">
                {' '}
                <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
              </div>
              <div className="w-full">
                {' '}
                <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
              </div>
              <div className="w-full">
                {' '}
                <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex">
              <div className="ml-5 flex flex-col justify-center">
                <p className="font-caudex pb-2.5 text-xl font-normal">
                  {partner?.orgName || invite?.orgName}
                </p>
                <div className="font-nunito flex flex-col gap-1 pb-2.5 text-base font-normal">
                  <p className="opacity-50">
                    {partner?.email || invite?.email}
                  </p>
                  {invite?.phone || partner?.phone ? (
                    <p className="">
                      {formatPhoneNumber(partner?.phone || invite?.phone)}
                    </p>
                  ) : null}
                  <p>
                    {partner?.addresses?.length
                      ? formatAddress(partner.addresses[0])
                      : ''}
                    {invite?.addresses?.length
                      ? formatAddress(invite.addresses[0])
                      : ''}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-start gap-[75px] p-4">
              <div className="flex-col">
                <p className="mb-[10px] text-sm text-gray-500">Type</p>
                <p className="font-caudex text-2xl font-normal">
                  {getPartnerLabelType()}
                </p>
              </div>
              <div className="flex-col">
                <p className="mb-[10px] text-sm text-gray-500">
                  {t('Warranties registered')}
                </p>
                <p className="font-caudex text-2xl font-normal">
                  {holdings?.length ?? '-'}
                </p>
              </div>
              <div className="flex-col">
                <p className="mb-[10px] text-sm text-gray-500">
                  {t('Last warranty registered on')}
                </p>
                <p className="font-caudex text-2xl font-normal">
                  {getLastHoldingRegistered()}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      <TabsGeneric items={partnerTabs} />
      {isNewEditModalOpen && (
        <div>
          <ModalNewEditPartner
            handleClose={() => setIsNewEditModalOpen(false)}
            handleDelete={() => setIsConfirmationModalOpen(true)}
            partner={partner}
            modalSettings={addEditModalSettings}
          />
          {isConfirmationModalOpen && (
            <Modal
              className="h-fit w-[350px] rounded-md bg-white"
              kind={confirmationModalSettings.kind}
              settings={confirmationModalSettings.settings}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default PartnerDetailView;

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

export const productsHeaders = [
  {
    accessorKey: 'productImage',
    header: 'product image',
  },
  {
    accessorKey: 'productName',
    header: 'product name',
  },
  {
    header: 'category',
    accessorKey: 'category',
  },
  {
    header: 'Warranty Status',
    accessorKey: 'warrantyStatus',
  },
  // {
  //   header: 'purchase date',
  //   accessorKey: 'purchaseDate',
  //   Cell: ({ cell }: any) => {
  //     return cell.getValue() ? formatUnixTime(cell.getValue(), 'dd/MM/yyyy') : '-';
  //   },
  // },
  {
    header: 'registration date',
    accessorKey: 'registrationDate',
    Cell: ({ cell }: any) => {
      return formatUnixTime(cell.getValue(), 'dd/MM/yyyy');
    },
  },
  {
    header: 'user manual',
    accessorKey: 'userManual',
  },
  {
    header: 'survey',
    accessorKey: 'survey',
  },
];
