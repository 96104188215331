import { useContext, useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Input,
  PasswordValidationPopover,
  handlePopoverData,
  asyncValidateSchema,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { FIREBASE_AUTH_ERRORS } from '../../../utils/consts';
import { FirebaseError } from 'firebase/app';
import ROUTE_NAME from '../../../utils/url-constants';
import { UserContext } from 'apps/sage/src/context/UserContext';
import { useAppInfo } from '@rabbit/sage/utils/helpers';
import { DTBusiness_User_Invite } from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  UserInvites_Business_DecodeInviteLink,
  UserInvites_Business_Signup,
} from '@rabbit/bizproc/client';

export interface SageAuthFormValuesShape {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  platform_services_terms: boolean;
  rememberMe: boolean;
}

const initialValues: SageAuthFormValuesShape = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  platform_services_terms: false,
  rememberMe: true,
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter your email address'),
  password: Yup.string()
    .min(8, 'Password should be 8 or more characters')
    .matches(/(?=.*[a-z])/, 'Please enter at least 1 lowercase letter')
    .matches(/(?=.*[A-Z])/, 'Please enter at least 1 uppercase letter')
    .matches(/^(?=.*[0-9])/, 'Please enter at least 1 number')
    .required('Please enter your password'),
});

const validationSchemaSignup = Yup.object().shape({
  first_name: Yup.string()
    .required('Please enter a first name')
    .matches(
      /^(?:[A-Z][a-z]*['´`-]?\s?){1,2}|^[A-Z][a-z]*$/,
      'First name must start with a capital letter and contain only letters.'
    ),
  last_name: Yup.string()
    .trim()
    .required('Please enter a last name')
    .matches(
      /^(?:[A-Z][a-z]*['´`-]?\s?){1,2}|^[A-Z][a-z]*$/,
      'Last name must start with a capital letter and contain only letters.'
    ),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter your email address'),
  password: Yup.string()
    .min(8, 'Password should be 8 or more characters')
    .matches(/(?=.*[a-z])/, 'Please enter at least 1 lowercase letter')
    .matches(/(?=.*[A-Z])/, 'Please enter at least 1 uppercase letter')
    .matches(/^(?=.*[0-9])/, 'Please enter at least 1 number')
    .required('Please enter your password'),
  platform_services_terms: Yup.bool().isTrue(),
});

const parseFirebaseAuthErrors = (errorCode: string) => {
  switch (errorCode) {
    case FIREBASE_AUTH_ERRORS.EMAIL_EXISTS:
      return 'Please use a different email address to create your account.';
    case FIREBASE_AUTH_ERRORS.INVALID_PASSWORD:
      return 'Invalid credentials. Please check your email and password and try again.';
    case FIREBASE_AUTH_ERRORS.INVALID_EMAIL:
      return 'Invalid credentials. Please check your email and password and try again.';
    case FIREBASE_AUTH_ERRORS.TOO_MANY_ATTEMPTS_TRY_LATER:
      return 'Too many attempts, please try again later.';
    case FIREBASE_AUTH_ERRORS.USER_DELETED:
      return 'Invalid credentials. Please check your email and password and try again.';
    default:
      return 'Something went wrong...Please try again later.';
  }
};

export interface AuthFormProps {
  kind: 'Login' | 'Signup' | 'Partner-Signup';
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  authErrorMsg: string;
  setAuthErrorMsg: React.Dispatch<React.SetStateAction<string>>;
}

export function AuthForm({
  kind,
  setIsLoading,
  authErrorMsg,
  setAuthErrorMsg,
}: AuthFormProps) {
  const appInfo = useAppInfo();
  const [buttonLoading, setButtonLoading] = useState(false);
  const contextValues = useContext(UserContext);
  const { createSageRepairerUser, sageSignIn } = contextValues || {};
  const queryParams = new URLSearchParams(location.search);
  const formikRef = useRef(null) as any;
  const [inviteData, setInviteData] = useState<DTBusiness_User_Invite>();
  const { t } = useTranslation();

  const navigate = useNavigate();

  if (!createSageRepairerUser || !sageSignIn)
    return <LoadingSpinner size="sm" />;

  const onSubmit = async (values: SageAuthFormValuesShape) => {
    setButtonLoading(true);
    setIsLoading(true);
    if (kind === 'Login') {
      try {
        await sageSignIn(values);
      } catch (err) {
        if (err instanceof FirebaseError) {
          setIsLoading(false);
          setAuthErrorMsg(parseFirebaseAuthErrors(err.code));
        } else console.log(err);
      } finally {
        setButtonLoading(false);
      }
    }
    if (kind === 'Signup' || kind === 'Partner-Signup') {
      if (!values.platform_services_terms) {
        setAuthErrorMsg(
          'Please agree to the Platform Services Terms before creating your account.'
        );
        setIsLoading(false);
        setButtonLoading(false);
        return;
      }
      if (!checkIfAllowedEmailForUser(values.email, appInfo.environment)) {
        setAuthErrorMsg('Something went wrong, please try again later...');
        setIsLoading(false);
        setButtonLoading(false);
        return;
      }
      try {
        if (kind === 'Partner-Signup' && inviteData) {
          const res = await UserInvites_Business_Signup(
            inviteData,
            t('tenantLink'),
            values.password,
            values.first_name,
            values.last_name
          );
          console.log('res', res);
          if (res.ok) {
            toast.success(
              'Account created successfully. Please login to continue.'
            );
            navigate(ROUTE_NAME.LOGIN);
          } else {
            toast.error(
              'Something went wrong, please try again. ' +
                res.error.replace(/^.*Error: /, '') +
                '.'
            );
          }
        } else {
          await createSageRepairerUser(values);
        }
      } catch (err) {
        if (err instanceof FirebaseError) {
          setAuthErrorMsg(parseFirebaseAuthErrors(err.code));
          setIsLoading(false);
        } else console.log(err);
      } finally {
        setIsLoading(false);
        setButtonLoading(false);
      }
    }
  };

  useEffect(() => {
    if (kind === 'Partner-Signup') {
      const getUserDetails = async () => {
        const c = queryParams.get('c') || '',
          iv = queryParams.get('iv') || '';

        const res = await UserInvites_Business_DecodeInviteLink(c, iv);
        if (res.ok && res.data.inviteData) {
          setInviteData(res.data.inviteData as DTBusiness_User_Invite);
        }
      };
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    if (inviteData?.email && kind === 'Partner-Signup') {
      formikRef?.current?.setFieldValue('email', inviteData?.email);
    }
  }, [inviteData]);

  if (kind === 'Partner-Signup' && !inviteData?.docid)
    return <LoadingSpinner size="sm" />;

  return (
    <Formik
      initialValues={initialValues}
      validate={asyncValidateSchema(
        kind === 'Login' ? validationSchema : validationSchemaSignup
      )}
      validateOnBlur
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ errors, values }) => (
        <Form className="font-nunito flex flex-col gap-3">
          {(kind === 'Signup' || kind === 'Partner-Signup') && (
            <>
              <Input
                type="text"
                name="first_name"
                label="First name*"
                settings={{
                  placeholder: 'Please type your first name',
                  forceUpperCaseFirstLetter: true,
                }}
              />
              <Input
                type="text"
                name="last_name"
                label="Last name"
                settings={{
                  placeholder: 'Please type your last name',
                  forceUpperCaseFirstLetter: true,
                }}
              />
            </>
          )}
          <Input
            type="email"
            name="email"
            label="Email*"
            settings={{
              placeholder: 'Please type your email',
              hint: '*required',
              disabled: kind === 'Partner-Signup',
            }}
          />
          <Input
            type="password"
            name="password"
            label="Password*"
            settings={{
              placeholder: 'Please type your password',
              id: 'password',
              popoverTrigger: 'hover',
              popoverPlacement: 'right',
              popoverTarget: 'popover-password',
              hint: '*required',
            }}
          />
          <PasswordValidationPopover
            id="popover-password"
            errors={handlePopoverData(errors.password, values.password)}
          />
          {(kind === 'Signup' || kind === 'Partner-Signup') && (
            <div className="my-2 w-full justify-between">
              <Input
                type="checkbox"
                name="platform_services_terms"
                settings={{
                  checkboxLabel:
                    'By creating an iWarranty account you are agreeing to the <a href="' +
                    ROUTE_NAME.PLATFORM_SERVICES_AGREEMENT +
                    '" target="_blank" />Platform Services Terms</a>.',
                  checkboxLabelStyles: 'text-base text-gray-500',
                }}
              />
            </div>
          )}
          <div className="my-2 flex w-full justify-between">
            <div className="shrink-0">
              <Input
                type="checkbox"
                name="rememberMe"
                settings={{
                  checkboxLabel: 'Remember me',
                  checkboxLabelStyles: 'text-base text-gray-500',
                }}
              />
            </div>
            <div className="shrink-0">
              <NavLink
                to={ROUTE_NAME.FORGOT_PASSWORD}
                className="text-primary-600 text-sm"
              >
                Forgot password?
              </NavLink>
            </div>
          </div>
          <Button
            kind="primary"
            type="submit"
            disabled={
              Object.keys(errors).length > 0 ||
              values.email.length === 0 ||
              buttonLoading
            }
            loading={buttonLoading}
          >
            {kind === 'Login' ? 'Log in' : 'Create account'}
          </Button>
          {kind === 'Login' && (
            <div className="w-full justify-between text-sm">
              By logging into iWarranty you are agreeing to the{' '}
              <a href={ROUTE_NAME.PLATFORM_SERVICES_AGREEMENT} target="_blank">
                Platform Services Terms
              </a>
            </div>
          )}
          {authErrorMsg && (
            <p className="font-nunito text-sm text-red-500">{authErrorMsg}</p>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default AuthForm;

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

const checkIfAllowedEmailForUser = (email: string, firebase_mode: string) => {
  if (
    firebase_mode === 'EMULATOR' ||
    firebase_mode === 'LIVE' ||
    firebase_mode === 'EULIVE' ||
    firebase_mode === 'EUSANDBOX' ||
    firebase_mode === 'EULAUNCHPAD' ||
    firebase_mode === 'USLIVE' ||
    firebase_mode === 'USSANDBOX' ||
    firebase_mode === 'USLAUNCHPAD' ||
    firebase_mode === 'DEMO'
  )
    return true;
  if (
    email.includes('@iwarranty.co') ||
    email.includes('@studiographene.com') ||
    email.includes('@sharklasers.com')
  )
    return true;
  else return false;
};
