import { Form, Formik } from 'formik';
import {
  Button,
  Input,
  LoadingSpinner,
  Modal,
  formatAddress,
} from '@rabbit/elements/shared-components';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { CaseflowContext } from 'apps/sage/src/context/CaseflowContext';
import { toast } from 'react-toastify';
import {
  getBusinessDefaultAddress,
  getConsumerURL,
  useAppInfo,
} from 'apps/sage/src/utils/helpers';
import {
  CaseFlow_Utils_RegisterFileUpload,
  FileStorageContext,
  MANDRILL_TEMPLATES,
} from '@rabbit/bizproc/react';
import { SageFileUploader } from '../../upload-wrapper/SageFileUploader';
import {
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { PersonaTypeSingleLetter } from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';

export interface RequestToSendItemModalProps {}

interface FormValuesShape {
  comment_to_customer: string;
  internal_comment: string;
  shipping_label_checkbox: boolean;
}

const initialValues = {
  comment_to_customer: '',
  internal_comment: '',
  shipping_label_checkbox: false,
};

const validationSchema = Yup.object().shape({
  comment_to_customer: Yup.string()
    .trim()
    .required('Please enter a message')
    .test('remove-html-tags', 'Please insert a message.', (value) => {
      const div = document.createElement('div');
      div.innerHTML = value || '';
      return !value || div.textContent?.trim() !== '';
    }),
  internal_comment: Yup.string().trim(),
});

export function RequestToSendItemModal({}: RequestToSendItemModalProps) {
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shippingLabelCheckbox, setShippingLabelCheckbox] = useState(false);
  const [showInternalComment, setShowInternalComment] = useState(false);
  const {
    caseFlowCase,
    caseFacts,
    alterCaseFacts,
    alterCasePublicEmail,
    executeAction,
    moveSpotlight,
    setShowModal,
    operatingPersona,
    caseId,
  } = useContext(CaseflowContext) || {};

  const { uploadQueueState, moveCompletedUploadsToAttached, setShouldRefetch } =
    useContext(FileStorageContext) || {};

  const { completed } = uploadQueueState || {};

  if (
    !caseFlowCase ||
    !setShouldRefetch ||
    !uploadQueueState ||
    !moveCompletedUploadsToAttached ||
    !setShowModal ||
    !executeAction ||
    !alterCaseFacts ||
    !alterCasePublicEmail ||
    !moveSpotlight ||
    !operatingPersona ||
    !caseFacts ||
    !caseId
  )
    return <LoadingSpinner size={'xs'} />;

  const onSubmit = async (values: FormValuesShape) => {
    const { internal_comment, comment_to_customer } = values;

    setIsSubmitting(true);

    await moveSpotlight(PersonaTypeSingleLetter.Consumer);
    executeAction('request_customer_send_item');

    const factsToAlter = {
      ...(internal_comment && {
        internal_comment: {
          comment: internal_comment,
          author: operatingPersona,
        },
      }),
      comment_to_customer: comment_to_customer,
      item_shipping_label: completed && completed?.[0]?.uploadedFile,
    };

    const to = caseFacts?.consumer_email ?? '',
      from = appInfo.email_sender,
      template = MANDRILL_TEMPLATES.BODY_REQUEST_SEND_ITEM_V2,
      business_name = appInfo.name ?? '',
      claim_id = caseId,
      first_name = caseFacts?.consumer_name ?? '',
      product_name = caseFacts.consumer_holding_name ?? '',
      preliminary_assessment = caseFacts?.preliminary_assessment ?? '',
      subject = t('email.subject.sendItemRequest', { business_name, product_name }),
      link_to_claim = `${getConsumerURL()}/repairs/${caseId}`,
      postage_information = formatAddress(appInfo.address, true) as string;

    try {
      if (Object.keys(factsToAlter).length > 0) {
        await alterCaseFacts(factsToAlter);
        await alterCasePublicEmail({
          context: 'request_send_item',
          from,
          to,
          subject,
          template,
          substitutions: {
            subject,
            business_name,
            claim_id,
            first_name,
            preliminary_assessment,
            postage_information,
            product_name,
            comment_to_customer,
            link_to_claim,
          },
          shallBeSentViaBackend: true
        });
      }
      if (moveCompletedUploadsToAttached && completed && completed.length > 0) {
        moveCompletedUploadsToAttached(completed);
      }
      setShowModal(false);
      toast.success('Request sent successfully.');
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong, please try again');
    }
  };

  return (
    <Modal
      settings={{
        title: 'Request customer to send item',
        headerBackground: true,
        handleClose: () => setShowModal(false),
      }}
      isLoading={isSubmitting}
      kind="generic"
      className="max-h-[768px] w-full max-w-[1024px] overflow-y-auto"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors }) => (
          <Form className="mt-5 flex flex-col gap-3 px-4">
            <Input
              type="rich-text"
              label="Comments to customer*"
              name="comment_to_customer"
              settings={{
                id: 'comment_to_customer',
                hint: '*required',
              }}
            />
            <Input
              type="checkbox"
              name="shipping_label_checkbox"
              settings={{
                checkboxLabel: 'Provide shipping label',
                onChange: () =>
                  setShippingLabelCheckbox(!shippingLabelCheckbox),
              }}
              className={shippingLabelCheckbox ? '-mb-4' : ''}
            />
            {shippingLabelCheckbox && (
              <SageFileUploader
                label="Shipping label*"
                identifiers={{
                  category: UploadedFileCategories.ShippingLabel,
                  docType: { docid: caseId, type: DocTypeShapeTypes.Case },
                  personaId: caseFacts.consumer_persona_id ?? '',
                }}
                alterCaseFacts={alterCaseFacts}
                currentFiles={
                  caseFacts?.item_shipping_label
                    ? caseFacts.item_shipping_label
                    : []
                }
                onUploadCompleted={async () =>
                  await CaseFlow_Utils_RegisterFileUpload(caseFlowCase, {
                    uploadQueueState,
                    moveCompletedUploadsToAttached,
                    setShouldRefetch,
                  })
                }
                accepts={['image/*', '.pdf']}
              />
            )}

            {!showInternalComment && (
              <div className="mt-4">
                <Button
                  kind="outline"
                  type="button"
                  className="w-full"
                  onClick={() => setShowInternalComment(true)}
                >
                  Add internal comment
                </Button>
              </div>
            )}
            {showInternalComment && (
              <div>
                <Input
                  type="rich-text"
                  label="Internal comment"
                  name="internal_comment"
                  settings={{
                    id: 'internal_comment',
                    placeholder: '',
                    allowSpecialCharacter: true,
                  }}
                />
              </div>
            )}
            <div className="mt-8 flex gap-8">
              <Button
                kind="primary"
                type="submit"
                loading={isSubmitting}
                disabled={
                  Object.keys(errors).length > 0 ||
                  isSubmitting ||
                  (shippingLabelCheckbox && completed?.length === 0)
                }
              >
                Send request to customer
              </Button>
              <Button
                kind="outline_red"
                type="submit"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default RequestToSendItemModal;
