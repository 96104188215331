import { ConfigContext } from '@rabbit/config/context';
import { DTHoldingProxy, Warranty } from '@rabbit/data/types';
import { useContext } from 'react';
import RegistrationsWarrantyDetailsSectionDefault from './RegistrationsWarrantyDetailsSectionDefault';
import { RegistrationsWarrantyDetailsSectionStoneWarranty } from './RegistrationsWarrantyDetailsSectionStoneWarranty';
import RegistrationsWarrantyDetailsSectionCarWarranty from './RegistrationsWarrantyDetailsSectionCarWarranty';
import { LoadingCard } from '@rabbit/elements/shared-components';
import { t } from 'i18next';
import { BL_Warranty } from '@rabbit/bizproc/core';

interface RegistrationsWarrantyDetailsSectionProps {
  holding_proxy: DTHoldingProxy;
}

export interface RegistrationsWarrantyDetailsSectionVariationProps
  extends RegistrationsWarrantyDetailsSectionProps {
  title: string;
  warrantyInfo?: Warranty;
}

export function RegistrationsWarrantyDetailsSection(
  props: RegistrationsWarrantyDetailsSectionProps
) {
  const { config } = useContext(ConfigContext);
  const warrantyInfo = BL_Warranty.getLatestWarranty(
    props.holding_proxy.warranties
  );

  function getCardTitle() {
    if (
      config.HOLDINGS.REGISTRATION_DETAILS_VIEW.WARRANTY_DETAILS_SECTION ===
        'CAR_WARRANTY' ||
      config.HOLDINGS.REGISTRATION_DETAILS_VIEW.WARRANTY_DETAILS_SECTION ===
        'STONE_WARRANTY'
    ) {
      return t('Service plan details');
    }
    return t('Warranty details');
  }
  const cardTitle = getCardTitle();

  if (!props.holding_proxy) {
    return <LoadingCard title={getCardTitle()} />;
  }
  if (
    config.HOLDINGS.REGISTRATION_DETAILS_VIEW.WARRANTY_DETAILS_SECTION ===
    'DEFAULT'
  ) {
    return (
      <RegistrationsWarrantyDetailsSectionDefault
        {...props}
        title={cardTitle}
        warrantyInfo={warrantyInfo}
      />
    );
  }

  if (
    config.HOLDINGS.REGISTRATION_DETAILS_VIEW.WARRANTY_DETAILS_SECTION ===
    'STONE_WARRANTY'
  ) {
    return (
      <RegistrationsWarrantyDetailsSectionStoneWarranty
        {...props}
        title={cardTitle}
        warrantyInfo={warrantyInfo}
      />
    );
  }

  if (
    config.HOLDINGS.REGISTRATION_DETAILS_VIEW.WARRANTY_DETAILS_SECTION ===
    'CAR_WARRANTY'
  ) {
    return (
      <RegistrationsWarrantyDetailsSectionCarWarranty
        {...props}
        title={cardTitle}
        warrantyInfo={warrantyInfo}
      />
    );
  }
}

export default RegistrationsWarrantyDetailsSection;
