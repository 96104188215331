import { useContext } from 'react';
import {
  formatUnixTime,
  LabelledItemShape,
} from '@rabbit/elements/shared-components';
import { CLAIM_STATUS_OPTIONS_LITE } from '@rabbit/bizproc/react';
import { CaseflowContext } from '../../../../../context/CaseflowContext';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '@rabbit/config/context';

export interface ClaimDetailsSectionDefaultProps {
  renderFns: {
    renderCaseFiles: () => JSX.Element;
    renderShopifyOrderLink: () => JSX.Element;
    renderConsumerIssueDesc: () => JSX.Element;
    renderClaimDetailItemsLine: (
      claimDetailItems: LabelledItemShape[],
      keys: string[],
      extraClasses?: string
    ) => JSX.Element;
  };
  faultLabels: string[];
}

export function ClaimDetailsSectionDefault(
  props: ClaimDetailsSectionDefaultProps
) {
  const {
    renderCaseFiles,
    renderShopifyOrderLink,
    renderConsumerIssueDesc,
    renderClaimDetailItemsLine,
  } = props.renderFns;
  const { faultLabels } = props;
  const context = useContext(CaseflowContext);
  const { caseFacts, caseFlowCase, holdingData, caseTimeCreated } =
    context || {};
  const { t } = useTranslation();
  const { config } = useContext(ConfigContext);

  const claimDetailItems: LabelledItemShape[] = [
    {
      key: 'claim_status',
      label: t('Claim status'),
      value:
        CLAIM_STATUS_OPTIONS_LITE.find(
          (i) => i.value === caseFlowCase?.GetCaseState()
        )?.label || '-',
    },
    {
      key: 'date_of_claim',
      label: t('Date of claim'),
      value: caseTimeCreated
        ? formatUnixTime(Number(caseTimeCreated), 'dd/MM/yyyy')
        : '-',
    },
    {
      key: 'purchase_date',
      label: t('Purchase date'),
      value:
        holdingData?.purchase_time && holdingData?.purchase_time > 0
          ? formatUnixTime(holdingData?.purchase_time, 'dd/MM/yyyy')
          : '-',
    },
    {
      key: 'customer_issue_type',
      label: t('Customer issue type'),
      value: caseFacts?.consumer_issue_type ?? '-',
    },
    {
      key: 'fault_types',
      label: t('Fault types'),
      value: faultLabels.length > 0 ? faultLabels.join(', ') : '-',
    },
    {
      key: 'warranty_assessment',
      label: t('Warranty assessment'),
      value:
        caseFacts?.preliminary_assessment || caseFacts?.final_assessment || '-',
    },
    {
      key: 'goodwill_warranty',
      label: t('Goodwill'),
      value:
        caseFacts?.goodwill_warranty_initial ||
        caseFacts?.goodwill_warranty_final ||
        '-',
    },
    {
      key: 'uploaded_case_files',
      label: t('Proof of purchase / Supporting materials'),
      value: renderCaseFiles(),
    },
    {
      key: 'shopify_order',
      label: t('Shopify order'),
      value: renderShopifyOrderLink(),
    },
  ];

  /* -------------------------------- Main tsx -------------------------------- */

  return (
    <div>
      {renderClaimDetailItemsLine(claimDetailItems, [
        'claim_status',
        'date_of_claim',
        'purchase_date',
        'customer_issue_type',
      ])}
      {renderConsumerIssueDesc()}
      {renderClaimDetailItemsLine(claimDetailItems, [
        'fault_types',
        'warranty_assessment',
        'goodwill_warranty',
        'uploaded_case_files',
        'shopify_order',
      ])}
      {config.SHOPIFY.ENABLED &&
        renderClaimDetailItemsLine(claimDetailItems, ['shopify_order'], 'mb-0')}
    </div>
  );
}

export default ClaimDetailsSectionDefault;
