import { useContext, useState } from 'react';
import { CaseflowContext } from '../../../context/CaseflowContext';
import {
  ButtonIcon,
  CardWrapperWithHeader,
  LabelledAddressItem,
  LabelledItem,
} from '@rabbit/elements/shared-components';
import ContactCustomerModal from '../../organisms/case-flow-modals/ContactCustomerModal/ContactCustomerModal';
import { EnvelopeIcon, PencilIcon } from '@heroicons/react/24/solid';
import ROUTE_NAME from '../../../utils/url-constants';
import Skeleton from 'react-loading-skeleton';
import { Address } from '@rabbit/data/types';
import { useTranslation } from 'react-i18next';
import { ModalNewEditCustomer } from '../../organisms/ModalNewEditCustomer/ModalNewEditCustomer';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CustomerDetailsCardProps {}

export function CustomerDetailsCard(props: CustomerDetailsCardProps) {
  const context = useContext(CaseflowContext);
  const [editCustomerModal, setEditCustomerModal] = useState(false);
  const { t } = useTranslation();
  const claimsFlowLite = t('CFG_CLAIMS_FLOW') === 'lite';
  const { setModalSettings, setShowModal, consumerPersonaData } = context || {};

  const modalSettingsOptions = {
    contact_customer_generic: {
      kind: 'generic' as const,
      settings: {
        title: t('Contact customer for more info'),
        primaryButtonText: t('Notify customer'),
        secondaryButtonText: t('Cancel'),
      },
      children: <ContactCustomerModal />,
    },
  };

  return (
    <CardWrapperWithHeader
      title={t('Customer details')}
      headerRight={
        <div className="flex gap-4">
          {claimsFlowLite ? (
            <>
              <ButtonIcon
                type="primary"
                label={``}
                Icon={EnvelopeIcon}
                onClick={() => {
                  if (setModalSettings && setShowModal) {
                    setModalSettings(
                      modalSettingsOptions.contact_customer_generic
                    );
                    setShowModal(true);
                  }
                }}
              />
              <ButtonIcon
                type="secondary"
                kind="bgLightGreen"
                label={``}
                Icon={PencilIcon}
                onClick={() => setEditCustomerModal(true)}
              />
            </>
          ) : (
            <ButtonIcon
              type="primary"
              label={`Contact customer`}
              Icon={EnvelopeIcon}
              iconLeft
              onClick={() => {
                if (setModalSettings && setShowModal) {
                  setModalSettings(
                    modalSettingsOptions.contact_customer_generic
                  );
                  setShowModal(true);
                }
              }}
            />
          )}
        </div>
      }
    >
      {!consumerPersonaData ? (
        <div className=" flex flex-col gap-3">
          <Skeleton count={2} containerClassName="max-w-[150px]" />
          <Skeleton count={2} containerClassName="max-w-[200px]" />
          <Skeleton count={2} containerClassName="max-w-[170px]" />
          <Skeleton count={4} containerClassName="max-w-[220px]" />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-3">
            <LabelledItem
              label={t('Name')}
              value={consumerPersonaData?.fullname ?? ''}
              arrowLink={`${ROUTE_NAME.MANAGE}/customer/${consumerPersonaData?.docid}`}
            />
            <LabelledItem
              label={t('Email address')}
              value={consumerPersonaData?.email ?? '-'}
            />
            <LabelledItem
              label={t('Phone number')}
              value={
                consumerPersonaData?.phone
                  ? `+ ${consumerPersonaData?.phone}`
                  : '-'
              }
            />
            <LabelledAddressItem
              addresses={(consumerPersonaData?.address ?? []) as Address[]}
            />
          </div>
          {editCustomerModal && (
            <div>
              <ModalNewEditCustomer
                handleClose={() => setEditCustomerModal(false)}
                handleDelete={() => {}}
                customerDetails={consumerPersonaData}
                modalSettings={{
                  title: 'Edit customer',
                  handleClose: () => setEditCustomerModal(false),
                }}
              />
            </div>
          )}
        </>
      )}
    </CardWrapperWithHeader>
  );
}

export default CustomerDetailsCard;
