import {
  DTVendable,
  DTVendable_History,
  DTVendable_Private,
  FBD_Vendable_History,
  GenerateTenantRootPersonaLink,
  PersonaTypeFullKeyValues,
  TenantLinkFieldName,
} from '@rabbit/data/types';
import {
  GetUniqueCategoryList,
  SearchVendables,
} from '@rabbit/search/cherchons';
import { t } from 'i18next';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useSageAPI } from '../../hooks/api/useSageAPI';
import { useLocation } from 'react-router-dom';

interface FacetFiltersShape {
  name: string;
  value: string;
}

interface CategoryDataShape {
  label: string;
  count: number;
}
interface VendableSearchParamsShape {
  searchQuery: string;
  hitsPerPage: number;
  page: number;
  facetFilters: FacetFiltersShape[];
}

interface VendableEditorContextShape {
  shouldRefreshList: boolean;
  setShouldRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
  vendableList: DTVendable[];
  totalHits: number;
  totalPages: number;
  getSingleVendable: (docid: string) => Promise<DTVendable_History | null>;
  searchParams: VendableSearchParamsShape;
  changeSearchParams: (newParams: Partial<VendableSearchParamsShape>) => void;
  createOrUpdateVendable: (
    publicData: Partial<DTVendable>,
    privateData: Partial<DTVendable_Private>,
    docid?: string
  ) => Promise<string | undefined>;
  categoryList: CategoryDataShape[];
}

const VendableEditorContext = createContext<VendableEditorContextShape>(
  {} as VendableEditorContextShape
);
// const VendableEditorContext = createContext<VendableEditorContextShape | null>(
//   null
// );

type VendableEditorProviderWrapperProps = {
  children: React.ReactNode;
};

/* -------------------------------------------------------------------------- */
/*                              Provider Wrapper                              */
/* -------------------------------------------------------------------------- */

const VendableEditorProviderWrapper = ({
  children,
}: VendableEditorProviderWrapperProps) => {
  const location = useLocation();

  const { createOrUpdateVendable: COUVendable } = useSageAPI();
  const [searchParams, setSearchParams] = useState<VendableSearchParamsShape>({
    hitsPerPage: 10,
    page: 0,
    searchQuery: '',
    facetFilters: [],
  });
  const [shouldRefreshList, setShouldRefreshList] = useState(true);
  const [vendableList, setVendableList] = useState<DTVendable[]>([]);
  const [totalHits, setTotalHits] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [categoryList, setCategoryList] = useState<CategoryDataShape[]>([]);

  const { searchQuery, hitsPerPage, page, facetFilters } = searchParams;

  // For now at least, we only want to search the active premium tenant's vendables, so we always add it
  facetFilters.push({
    name: 'mfr',
    value: GenerateTenantRootPersonaLink(
      PersonaTypeFullKeyValues.manufacturer,
      t(TenantLinkFieldName)
    ),
  });

  useEffect(() => {
    void (async () => {
      // This context should only be called when the user is on the products tab
      // And this is an admittedly hacky way to ensure that it works with the current FE architecture
      // For that portion of the application. Hard, hard pass on doing it right now, so this works
      // TODO: evaluate if we should move the ROUTE_NAME constants from Olive and Sage to a shared location
      // So we can use them below (and possibly in other places too)
      if (
        shouldRefreshList &&
        location.pathname + location.search === '/manage?tab=products'
      ) {
        const res = await SearchVendables(searchQuery, {
          hitsPerPage,
          page,
          facetFilters,
        });

        setVendableList(res.items);
        const categoryList = GetUniqueCategoryList(res.items);

        setCategoryList(categoryList);
        setTotalHits(res.numitems);
        setTotalPages(res.numpages);
        setShouldRefreshList(false);
      }
    })();
  }, [
    shouldRefreshList,
    searchQuery,
    hitsPerPage,
    page,
    facetFilters,
    location,
  ]);

  /* -------------------------------------------------------------------------- */
  /*                                  Functions                                 */
  /* -------------------------------------------------------------------------- */

  /** Fetches a single vendable's data from the Vendable History collection */
  async function getSingleVendable(docid: string) {
    const vendableData = await FBD_Vendable_History.get(docid);

    return vendableData;
  }

  /** Changes the search params used for vendable searches */
  function changeSearchParams(newParams: Partial<VendableSearchParamsShape>) {
    setSearchParams({ ...searchParams, ...newParams });
    setShouldRefreshList(true);
  }

  /** Creates or updates a vendable for the active premium tenant. If no docid is passed, it'll create a new vendable.  */
  async function createOrUpdateVendable(
    publicData: Partial<DTVendable>,
    privateData: Partial<DTVendable_Private>,
    docid?: string
  ) {
    try {
      const res = await COUVendable({
        tenantLink: t('tenantLink'),
        publicData,
        privateData,
        id: docid,
      });
      setShouldRefreshList(true);
      return res.docid;
    } catch (err) {
      console.error('Error creating or updating vendable', err);
      throw new Error('Error creating or updating vendable');
    }
  }
  /* -------------------------------------------------------------------------- */
  /*                               Context return                               */
  /* -------------------------------------------------------------------------- */
  const contextValues = useMemo(
    () => ({
      shouldRefreshList,
      setShouldRefreshList,
      vendableList,
      totalHits,
      totalPages,
      getSingleVendable,
      searchParams,
      changeSearchParams,
      createOrUpdateVendable,
      categoryList,
    }),
    [
      shouldRefreshList,
      vendableList,
      totalHits,
      totalPages,
      getSingleVendable,
      searchParams,
      changeSearchParams,
      createOrUpdateVendable,
      categoryList,
    ]
  );

  return (
    <VendableEditorContext.Provider value={contextValues}>
      {children}
    </VendableEditorContext.Provider>
  );
};

export { VendableEditorContext, VendableEditorProviderWrapper };
