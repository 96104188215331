const ROUTE_NAME = {
  DEFAULT: '/',
  CLAIMS: '/claims',
  DASHBOARD: '/dashboard',
  FORGOT_PASSWORD: '/forgot-password',
  FAULTS: '/faults',
  LOGIN: '/login',
  MANAGE_ACCOUNT: '/manage-account',
  MY_ORGANIZATION: '/my-organization',
  MY_CLAIMS: '/my-claims',
  PLATFORM_SERVICES_AGREEMENT: '/platform-services-agreement',
  PRIVACY_POLICY: '/privacy-policy',
  PRODUCTS: '/products',
  MANAGE: '/manage',
  MANAGE_PRODUCTS: '/manage?tab=products',
  MANAGE_CUSTOMERS: '/manage?tab=customers',
  MANAGE_FAULTS: '/manage?tab=faults',
  PRODUCTS_PARTS: '/products/parts',
  REGISTRATIONS: '/registrations',
  WARRANTY_OVERVIEW: '/warranty-overview',
  SIGNUP: '/signup',
  TERMS_AND_CONDITIONS: '/terms-and-conditions', //todo
  USER_GROUP: '/manage-account/user-group',
  // todo merge these two routes into one
  CONFIRM_EMAIL: '/confirm-email',
  RESET_PASSWORD: '/reset-password',
};
export default ROUTE_NAME;
