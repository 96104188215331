import {
  GEActorChange,
  GEChat,
  GEFactUpdate,
  GEK_ActorChange,
  GEK_Chat,
  GEK_FactUpdate,
  GEK_CaseSpotlightChange,
  GEK_StateChange,
  GECaseSpotlightChange,
  GEStateChange,
  GazetteEntry,
  CFAction,
  OneEachOptionalPersonaLink,
  GEK_Acknowledge,
  GEEmail,
  GEK_Email,
  GazetteEmailParams,
  GELinkCase,
  GEK_LinkCase,
  FBD_CaseFlowActorPrivate,
  RecorderCommitBlock,
  CaseFlowFacts,
  SpotlightAlteration,
} from '@rabbit/data/types';
import {
  BaseCaseFlowCase,
  ActionStepRegistry,
  ActionRuntime,
  CaseAlteringFunctionality,
  CFC_CaseFlowCarryOutRecorderCommit,
} from '@rabbit/bizproc/core';

/** Indicates the degree to which the case can be altered. This is to control what we call "Seismic Changes".
 *
 * A "Seismic Change" is something that would cause a ripple effect either inside or outside of the system.
 * For example:
 * - if you run an action that requires the server to modify the case, then that is a seismic change because it has an effect
 *   inside the system, yet outside of the local view.
 * - if you run an action that sends an email, then that is a seismic change because it has an effect outside of the system.
 *
 * If you are blocked by a seismic change, then you must commit the case before you can continue.
 *
 * Possible values:
 *
 * FreeToAlter - The case is not being altered by any actions and doesn't have any pending actions that cause seismic changes
 *
 * NoFurtherActions - Most alterations are allowed, but further actions are blocked because they may depend on an existing action that
 *                    has been executed locally executing on the server.
 *
 * MustCommit - The has been altered by an action that is a seismic change. You must commit the case before you can perform any other actions.
 *
 * AwaitingUpdate - Nothing is permitted once the case is committed until the updated results have been received by the server since this process
 *                  is asynchronous.
 *
 * PerformingAction - (INTERNAL) The case is being altered by an action, but it is not a seismic change. This is used internally so that action changes
 *                    can be isolated and rolled back if needed.
 */

export enum CaseAlterability {
  FreeToAlter = 'FreeToAlter',
  NoFurtherActions = 'NoFurtherActions',
  MustCommit = 'MustCommit',
  AwaitingUpdate = 'AwaitingUpdate',
  PerformingAction = 'PerformingAction',
}

/** Callback for subscription to case updates. Triggered whenever the case is updated. */
export type CaseFlowSubscriptionCallback = (cfc: ClientCaseFlowCase) => void;
let subscriptionNumber = 0;

/** Encapsulate the view of a CaseFlowCase. You can query it and you can alter it.
 * Alterations must be committed to become fact.
 *
 * The default initialisation is as an "empty case" which can be worked on immediately.
 * Alternatively you can load a case after construction.
 */

export type CFCCommitParams = {
  debug_force_time?: number;
};

export class ClientCaseFlowCase
  extends BaseCaseFlowCase
  implements CaseAlteringFunctionality
{
  private _updateSubscriptions: {
    [key: number]: CaseFlowSubscriptionCallback;
  } = {};

  protected alterability: CaseAlterability = CaseAlterability.FreeToAlter;

  protected _CheckBasicAlterability() {
    if (this.alterability === CaseAlterability.MustCommit)
      throw new Error(
        'CaseFlowCase: You cannot alter the case at this time. You must commit the case first.'
      );
    if (this.alterability === CaseAlterability.AwaitingUpdate)
      throw new Error(
        'CaseFlowCase: You cannot alter the case at this time. Waiting for current Commit to complete.'
      );
  }

  protected commitParent: ClientCaseFlowCase | null = null;
  protected commitChildren: ClientCaseFlowCase[] = [];

  GetAlterability() {
    return this.alterability;
  }
  /* -------------------------------------------------------------------------- */
  /*                        Update subscription handling                        */
  /* -------------------------------------------------------------------------- */

  /** Subscribe for updates. A very rudimentary system which will just trigger every time that there is an update.
   * Updates are: Data load completes, or a new write is made to the gazette.
   */
  Subscribe(callback: CaseFlowSubscriptionCallback) {
    const id = subscriptionNumber++;
    this._updateSubscriptions[id] = callback;

    return () => {
      delete this._updateSubscriptions[id];
    };
  }

  /** Call this when the case has changed to inform all subscribers to update their details */
  protected _informSubscribers() {
    // TODO: Debounce this SOB
    for (const key in this._updateSubscriptions) {
      this._updateSubscriptions[key](this);
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                            Server Communications                           */
  /* -------------------------------------------------------------------------- */
  protected GetRecorderCommitBlock() {
    {
      if (!this.operatingPersona)
        throw new Error(
          'Case has no operating persona - you need to load the case as the persona we are operating on behalf of.'
        );
      if (!this.principalPersona)
        throw new Error(
          'Case has no principal persona - you need to load the case as the persona we are operating on behalf of.'
        );
    }
    const rcb: RecorderCommitBlock = {
      operating_persona: this.operatingPersona,
      principal_persona: this.principalPersona,
      cases: {
        [this._case_id]: {
          casetype: this._case.casetype,
          writes: this.writes,
        },
      },
    };
    return rcb;
  }

  protected async CommitWasASuccess(case_id: string) {
    // We are now free to alter the case again
    this.alterability = CaseAlterability.FreeToAlter;

    // Flush out existing writes, they should now all be committed
    this.writes = [];

    // Load up the case again. This should rebuild the gazette too.
    await this.LoadCase(case_id);
  }

  async CommitToServer(params?: CFCCommitParams) {
    if (this.commitParent)
      throw new Error(
        'CommitToServer: Unable to commit this case directly - it must be committed by its related case first.'
      );

    const initialCaseId = this._case_id;
    // Save alterability state in case we have to roll back
    const currentAlterability = this.alterability;

    // Mark alterability as committing to server, to prevent any writes
    this.alterability = CaseAlterability.AwaitingUpdate;

    // Make Recorder Commit Block
    const rcb: RecorderCommitBlock = {
      ...this.GetRecorderCommitBlock(),
    };

    // has to be passed separately, otherwise zod will coerce its undefined value into null and break the CF
    if (params?.debug_force_time)
      rcb.debug_force_time = params.debug_force_time;

    for (let n = 0; n < this.commitChildren.length; n++) {
      const child = this.commitChildren[n];
      const kidRcb = child.GetRecorderCommitBlock();
      if (kidRcb.operating_persona !== rcb.operating_persona)
        throw new Error(
          'CommitToServer: Child case operating persona does not match parent'
        );
      if (kidRcb.principal_persona !== rcb.principal_persona)
        throw new Error(
          'CommitToServer: Child case principal persona does not match parent'
        );
      rcb.cases[child.GetUnfetteredCaseId()] =
        kidRcb.cases[child.GetUnfetteredCaseId()];
    }

    // Fire it off to the server

    const result = await CFC_CaseFlowCarryOutRecorderCommit.call({
      block: rcb,
    });

    if (!result.ok) {
      this.alterability = currentAlterability;
      throw new Error('CommitToServer failed - ' + result.error);
    }
    const outcome = result.data;

    if (outcome.success) {
      await this.CommitWasASuccess(outcome.map[initialCaseId]);

      // All the commit children
      for (let n = 0; n < this.commitChildren.length; n++) {
        const child = this.commitChildren[n];
        await child.CommitWasASuccess(outcome.map[child.GetUnfetteredCaseId()]);
        child.commitParent = null;
      }
      this.commitChildren = [];

      return outcome.map[initialCaseId];
    }

    this.alterability = currentAlterability;
    throw new Error('CommitToServer failed - ' + result.data.error);
  }

  AddCommitChild(child: ClientCaseFlowCase) {
    this.commitChildren.push(child);
    child.commitParent = this;
  }

  /* -------------------------------------------------------------------------- */
  /*                             Alteration Section                             */
  /*                                                                            */
  /*      These are the only functions that cause alterations to the case.      */
  /*                      They are all prefixed with Alter_                     */
  /* -------------------------------------------------------------------------- */

  /** Alter an actor (persona that is involved in the case) */
  Alter_Actor(actorRole: string, actorPersonaID: string) {
    this._CheckBasicAlterability();

    // make sure they are mentioned in the config
    if (!this.configuration.actors[actorRole]) {
      throw new Error(
        `Actor role "${actorRole}" is not registered in the case configuration.`
      );
    }

    const entry: GEActorChange = {
      k: GEK_ActorChange,
      a: '',
      t: 0,
      role: actorRole,
      link: actorPersonaID,
    };
    this.WriteToGazette(entry);

    // If we made ourselves the actor (which happens at case start) we need to update this object so that it knows
    // we have assumed that role.
    if (this.principalPersona === actorPersonaID) {
      this.actorContext = {
        role: actorRole,
        persona: this.principalPersona,
      };

      // Create a placeholder document for the actor case
      this._actorCases[actorRole] = FBD_CaseFlowActorPrivate.empty();
    }

    // TODO: We now probably have to subscribe to that actor so we can get their data streaming in
  }

  /** Alter one or more facts (long term details) */
  Alter_Facts(facts: CaseFlowFacts) {
    this._CheckBasicAlterability();

    const currentFacts = this.GetAllFacts();

    const alterations: CaseFlowFacts = {};

    Object.keys(facts).forEach((key) => {
      // Check if the fact is valid
      if (!this.configuration.facts[key])
        throw new Error(
          `Fact "${key}" is not registered in the case configuration.`
        );
      // TODO: Check if we are allowed to edit it

      // Check if it has already been altered
      if (currentFacts[key] !== facts[key]) {
        alterations[key] = facts[key];
      }
    });

    if (Object.keys(alterations).length === 0) return; // No alterations

    const entry: GEFactUpdate = {
      k: GEK_FactUpdate,
      a: '',
      t: 0,
      kv: alterations,
    };
    this.WriteToGazette(entry);
  }

  FindActionByName(actionName: string): CFAction | null {
    const localAction =
      this.configuration.stations[this.currentCaseState].actions[actionName];
    if (localAction) return localAction;

    const globalActions = this.configuration.global_actions;
    if (globalActions) return globalActions[actionName] || null;

    return null;
  }

  /** Alter the case with the given action. */
  Alter_WithAction(actionName: string, params?: { [key: string]: any }) {
    // Preflight - make sure we are allowed to run the action
    this._CheckBasicAlterability();
    if (this.alterability === CaseAlterability.NoFurtherActions)
      throw new Error(
        'CaseFlowCase: You cannot run actions on the case at this time. You must commit the case first.'
      );
    if (this.alterability === CaseAlterability.PerformingAction)
      throw new Error(
        'CaseFlowCase: You cannot run an action while running an action.'
      );

    // Preflight - get the action we intend to execute
    const action = this.FindActionByName(actionName);
    if (!action)
      throw new Error(
        `CaseFlowCase: Action "${actionName}" does not exist in the current state "${this.currentCaseState}"`
      );

    // Preflight - set up the Action Runtime and validate incoming parameters
    const art = new ActionRuntime(action, this);
    art.CheckAndFillParams(params || {}); // Must be called even if no params passed in so that we can check for missing required params

    // Save various things about the case that the action might change so we can roll back if needed
    const oldAlterability = this.alterability;
    this.alterability = CaseAlterability.PerformingAction;
    // TODO:

    // Make the Alterer. This seems dumb but can't find a nice way to do it without opening up class protections and things like that.

    // Run all the actions!
    try {
      console.log("CaseFlowCase: Running action '" + actionName);
      for (let n = 0; n < action.steps.length; n++) {
        const step = action.steps[n];
        console.log('CaseFlowCase: Running step', step);
        if (step.type === 'go_station') {
          this.Alter_CaseState(step.station);
          continue;
        }

        const asi = ActionStepRegistry.Get(step.type);
        asi.interior(this, step, art);
      }
    } catch (e) {
      // Roll back the changes to the case
      this.alterability = oldAlterability;

      console.log('ROLLBACK');
      throw e;
    }
    this.alterability = oldAlterability;
  }

  /** Alter the case by adding a chat message */
  Alter_Chat(message: string, attachment?: string) {
    if (!this.operatingPersona)
      throw new Error(
        'Case has no operating persona - you need to load the case as the persona we are operating on behalf of.'
      );
    const entry: GEChat = {
      k: GEK_Chat,
      a: this.operatingPersona,
      t: 1684247213,
      body: message,
      attachment: attachment ?? '',
    };
    this.WriteToGazette(entry);
  }

  /** Alter the case by adding an email message */
  Alter_PublicEmail(params: GazetteEmailParams) {
    if (!this.operatingPersona)
      throw new Error(
        'Case has no operating persona - you need to load the case as the persona we are operating on behalf of.'
      );
    const entry: GEEmail = {
      k: GEK_Email,
      a: this.operatingPersona,
      t: 1684247213,
      ...params,
    };
    this.WriteToGazette(entry);
  }

  Alter_CaseSpotlight(alteration: SpotlightAlteration) {
    const currentSpotlight = this.GetCaseSpotlight();
    let newSpotlight = [...currentSpotlight];

    if (alteration.clear) {
      newSpotlight = [];
    }

    if (alteration.add) {
      const toAdd = Array.isArray(alteration.add)
        ? alteration.add
        : [alteration.add];
      toAdd.forEach((p) => {
        if (!newSpotlight.includes(p)) newSpotlight.push(p);
      });
    }

    if (alteration.remove) {
      const toRemove = Array.isArray(alteration.remove)
        ? alteration.remove
        : [alteration.remove];
      toRemove.forEach((p) => {
        if (newSpotlight.includes(p))
          newSpotlight = newSpotlight.filter((x) => x !== p);
      });
    }

    newSpotlight.sort();

    // Check if spotlight is actually different
    if (JSON.stringify(currentSpotlight) === JSON.stringify(newSpotlight)) {
      return;
    }

    // Work out what items have been added and removed
    const added = newSpotlight.filter((p) => !currentSpotlight.includes(p));
    const removed = currentSpotlight.filter((p) => !newSpotlight.includes(p));

    const entry: GECaseSpotlightChange = {
      k: GEK_CaseSpotlightChange,
      a: '',
      t: 0,
      spotlight: newSpotlight,
    };
    if (added.length > 0) entry.add = added;
    if (removed.length > 0) entry.del = removed;
    this.WriteToGazette(entry);
  }

  // TODO: This function to become obsolete (use Alter_CaseSpotlight instead)
  Alter_CaseSpotlight_Add(persona: string) {
    this.Alter_CaseSpotlight({ add: persona });
    // const currentSpotlight = this.GetCaseSpotlight();
    // const entry: GECaseSpotlightChange = {
    //   k: GEK_CaseSpotlightChange,
    //   a: '',
    //   t: 0,
    //   add: [persona],
    //   spotlight: [...currentSpotlight, persona],
    // };
    // this.WriteToGazette(entry);
  }

  // TODO: This function to become obsolete (use Alter_CaseSpotlight instead)
  Alter_CaseSpotlight_Remove(persona: string) {
    this.Alter_CaseSpotlight({ remove: persona });
    // const currentSpotlight = this.GetCaseSpotlight();
    // if (!currentSpotlight.includes(persona)) {
    //   throw new Error(
    //     `Cannot remove ${persona} from the case spotlight as they are not in it.`
    //   );
    // }
    // // remove from spotlight
    // const newSpotlight = currentSpotlight.filter((p) => p !== persona);

    // const entry: GECaseSpotlightChange = {
    //   k: GEK_CaseSpotlightChange,
    //   a: '',
    //   t: 0,
    //   del: [persona],
    //   spotlight: newSpotlight,
    // };
    // this.WriteToGazette(entry);
  }

  // TODO: This function to become obsolete (use Alter_CaseSpotlight instead)
  Alter_CaseSpotlight_Clear() {
    this.Alter_CaseSpotlight({ clear: true });
    // Just delete everyone
    // const currentSpotlight = this.GetCaseSpotlight();
    // const entry: GECaseSpotlightChange = {
    //   k: GEK_CaseSpotlightChange,
    //   a: '',
    //   t: 0,
    //   del: currentSpotlight,
    //   spotlight: [],
    // };
    // this.WriteToGazette(entry);
  }

  async Alter_Create_SubCase(subCaseType: string, role: string) {
    // Create the subcase and hold onto it - we will be responsible for commiting it in the first instance.
    if (this.operatingPersona === null)
      throw new Error('Alter_Create_SubCase: Operating persona is required');
    if (this.principalPersona === null)
      throw new Error('Alter_Create_SubCase: Principal persona is required');
    const subCase = new ClientCaseFlowCase(
      this.operatingPersona,
      this.principalPersona
    );

    // Link this case to the sub case
    const MasterEntry: GELinkCase = {
      k: GEK_LinkCase,
      a: '',
      t: 0,
      case: subCase.GetTemporaryCaseId(),
      is_my: 'sub',
    };
    const MasterActorCase = this._actorCases[this.actorContext?.role || ''];
    if (!MasterActorCase)
      throw new Error('Alter_Create_SubCase: Master actor case not found');

    this.WriteToActorGazette(MasterEntry);

    // Set subcase type
    await subCase.SetCaseType(subCaseType);

    // Become the correct role in the subcase
    subCase.Alter_Actor(role, this.principalPersona);
    const SubActorCase = subCase._actorCases[subCase.actorContext?.role || ''];
    if (!SubActorCase)
      throw new Error('Alter_Create_SubCase: Sub actor case not found');

    // Link subcase back to the master
    const SubEntry: GELinkCase = {
      k: GEK_LinkCase,
      a: '',
      t: 0,
      case: this._case_id,
      is_my: 'master',
    };
    subCase.WriteToActorGazette(SubEntry);

    // Make subcase a commit child of this case
    this.AddCommitChild(subCase);

    return subCase;
  }

  /** (INTERNAL ONLY) Write the entry to the gazette, informing subscribers so as to trigger any necessary updates */
  protected WriteToGazette(entry: GazetteEntry) {
    this.writes.push(entry);
    this.merged_gazette.push(entry);
    this._informSubscribers();
  }

  protected WriteToActorGazette(entry: GazetteEntry) {
    // This is for entries that are private.
    // Since we actually rely on the server to sort private and public, we don't actually do anything
    // different to WriteToGazette. However the distinction is made in case this changes in the future.
    this.writes.push(entry);
    this.merged_gazette.push(entry);
    this._informSubscribers();
  }

  /* ----------------- Protected alterations (used internally) ---------------- */

  /** (INTERNAL ONLY) Changes the case state.
   * This may only be called internally since all alterations to state must be done through an action. */
  protected Alter_CaseState(newState: string) {
    const entry: GEStateChange = {
      k: GEK_StateChange,
      a: '',
      t: 0,
      who: 'CASE',
      newState,
      oldState: this._case.state,
    };
    this.WriteToGazette(entry);
    this.currentCaseState = newState;
  }

  /* -------------------------------------------------------------------------- */
  /** Marks the chat as last seen "just now".
   *
   * How this small thing is done is complicated because we have to fit into the security requirements and subscription
   * update flow, so even though this is a tiny thing, we run the system as if it is any other update. The alternative
   * is to make and maintain a separate smaller system just for this kind of meta data, or put it into another
   * separate document. They are just more complexity.
   *
   * The demands of this update are asynchronous to whatever else may be going on. The case may be being altered and not
   * committed, for example. We don't want to have to alter our case and force a commit, because it is outside of what
   * we are doing. Once the chat is read we just want to fire-and-forget a ping to say that it has been read.
   *
   * So we actually pretend that this is a second viewer editing the case. We will fire a "commit" to the server but it
   * will only instruct the server to update the chat last seen time. The server will then update the case and synchronisation
   * will bring the data back to the client. Simple.
   *
   * We bypass all checks on whether we are ready to commit because it is async to any other editing that may be going on.
   */
  async UpdateChatLastSeen(actor: keyof OneEachOptionalPersonaLink) {
    // Make Recorder Commit Block
    const rcb: RecorderCommitBlock = this.GetRecorderCommitBlock();

    // Push was not allowed by typescript here, I am not sure why.
    rcb.cases[this._case_id].writes = [
      ...rcb.cases[this._case_id].writes,
      {
        k: GEK_Acknowledge,
        a: '',
        t: 0,
        actor,
        time: Date.now(),
      },
    ];

    // Fire it off to the server
    await CFC_CaseFlowCarryOutRecorderCommit.call({ block: rcb });
  }
}
