import {
  DTHoldingProxy,
  TableCover,
  Warranty,
  WarrantyStatus,
} from '@rabbit/data/types';
import {
  getProductTitleFromHoldingData,
  getProductCategoryFromHoldingData,
  getExpiryDate,
  getStoneImages,
  formatDate,
  renderWarrantyRemaining,
} from '../../../../utils/helpers';
import placeholderImg from '../../../../assets/images/placeholder-image.png';
import { calcWarrantyTimeLeft } from '@rabbit/bizproc/react';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { TenantType } from '@rabbit/sage/views/RegistrationsView';
import { BL_Warranty } from '@rabbit/bizproc/core';
import { ShieldCheckIcon, UserIcon } from '@heroicons/react/24/solid';
import { OurDateTime } from '@rabbit/utils/ts';
import { getWarrantyTerm } from '@rabbit/elements/shared-components';

interface RegistrationsHoldingSummarySectionProps {
  allHoldingsList?: DTHoldingProxy[];
  holding_proxy?: DTHoldingProxy;
  holdingId: string;
  storeAddress: string;
}

export default function RegistrationsHoldingSummarySection({
  allHoldingsList,
  holding_proxy,
  holdingId,
  storeAddress,
}: RegistrationsHoldingSummarySectionProps) {
  const { t } = useTranslation();
  let tenantType: TenantType = 'defaultTenants';
  if (t('tenantLink') === 'NUCOVER') tenantType = 'tableTenants';

  const renderHoldingSummary = () => {
    if (holding_proxy && allHoldingsList) {
      switch (t('tenantLink')) {
        case 'NUCOVER':
          return renderNuCoverHoldingSummary(
            allHoldingsList,
            holding_proxy,
            holdingId
          );
        default:
          const warranty = BL_Warranty.getLatestWarranty(
            holding_proxy.warranties
          );
          return renderDefaultHoldingSummary(
            storeAddress,
            t,
            tenantType,
            allHoldingsList,
            holding_proxy,
            holdingId,
            warranty
          );
      }
    }
  };

  return <div className="font-nunito">{renderHoldingSummary()}</div>;
}

const renderNuCoverHoldingSummary = (
  allHoldingsList?: DTHoldingProxy[],
  holding_proxy?: DTHoldingProxy,
  holdingId?: string
) => {
  const latestWarranty = BL_Warranty.getLatestWarranty(
    holding_proxy?.warranties
  );
  return (
    <div className="flex justify-between overflow-hidden rounded-md border border-gray-300 bg-gray-100 p-4">
      {!allHoldingsList ||
      !holdingId ||
      !holding_proxy ||
      holding_proxy.purchase_time === undefined ||
      !holding_proxy.self_registration?.srvInfo ? (
        <>
          <div className="flex w-full grow">
            <div>
              <div className="h-[138px] w-[188px]">
                {' '}
                <Skeleton
                  count={1}
                  baseColor="#fff"
                  highlightColor="#f1f1f1"
                  className="h-[138px] w-[188px]"
                />
              </div>
            </div>
            <div className="ml-5 flex w-full flex-col items-center justify-center">
              <div className="w-full">
                <Skeleton count={3} baseColor="#fff" highlightColor="#f1f1f1" />
              </div>
            </div>
          </div>

          <div className="flex w-full grow items-center gap-[75px] px-4">
            <div className="w-full">
              {' '}
              <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
            </div>
            <div className="w-full">
              {' '}
              <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
            </div>
          </div>
        </>
      ) : (
        <div className="flex w-full items-start justify-between gap-4">
          <div className="flex w-1/3 grow items-start gap-3">
            <div className="flex h-[84px] w-[84px] shrink-0 items-center justify-center rounded-md border border-gray-300 bg-white">
              <UserIcon className="h-8 w-8 text-gray-500" />
            </div>
            <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
              <p className="w-full overflow-hidden text-ellipsis text-xl">
                {holding_proxy.consumer_name}
              </p>
              <p className="w-full overflow-hidden text-ellipsis text-base opacity-50">
                {holding_proxy.consumer_email}
              </p>
            </div>
          </div>
          <div className="flex w-1/3 grow items-start gap-3">
            <img
              src={
                getStoneImages(
                  (
                    holding_proxy?.self_registration?.srvInfo
                      ?.productInfo as TableCover
                  ).typeofStone
                ) || placeholderImg
              }
              alt={'product '}
              className="h-[84px] w-[84px] shrink-0 rounded-md border border-gray-300 object-cover"
            ></img>
            <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
              <p className="w-full overflow-hidden text-ellipsis text-xl font-normal">
                {
                  (
                    holding_proxy.self_registration?.srvInfo
                      ?.productInfo as TableCover
                  ).typeofStone
                }
              </p>
              <p className="w-full overflow-hidden text-ellipsis text-lg text-gray-600">
                Area:{' '}
                {(
                  holding_proxy.self_registration?.srvInfo
                    ?.productInfo as TableCover
                ).surfaceAreaValue + 'm²'}
              </p>
            </div>
          </div>
          <div className="flex w-1/3 grow items-start gap-3">
            <div className="flex h-[84px] w-[84px] shrink-0 items-center justify-center rounded-md border border-gray-300 bg-white">
              <ShieldCheckIcon className="h-8 w-8 text-gray-500" />
            </div>
            <div className="font-caudex flex flex-col items-start justify-center gap-2 overflow-hidden py-2 font-normal">
              <p className="w-full overflow-hidden text-ellipsis text-xl">
                {latestWarranty?.status === WarrantyStatus.VOIDED
                  ? 'Voided'
                  : renderWarrantyRemaining(holding_proxy)}
              </p>
              <p className="w-full overflow-hidden text-ellipsis text-base opacity-50">
                Service plan term: {getWarrantyTerm(holding_proxy)}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const renderDefaultHoldingSummary = (
  storeAddress: string,
  t: any,
  tenantType: TenantType,
  allHoldingsList?: DTHoldingProxy[],
  holding_proxy?: DTHoldingProxy,
  holdingId?: string,
  warranty?: Warranty
) => (
  <div className="flex justify-between overflow-hidden rounded-md border border-gray-300 bg-gray-100 p-4">
    {!allHoldingsList || !holdingId || !holding_proxy ? (
      <>
        <div className="flex w-full grow">
          <div>
            <div className="h-[138px] w-[188px]">
              {' '}
              <Skeleton
                count={1}
                baseColor="#fff"
                highlightColor="#f1f1f1"
                className="h-[138px] w-[188px]"
              />
            </div>
          </div>
          <div className="ml-5 flex w-full flex-col items-center justify-center">
            <div className="w-full">
              <Skeleton count={3} baseColor="#fff" highlightColor="#f1f1f1" />
            </div>
          </div>
        </div>

        <div className="flex w-full grow items-center gap-[75px] px-4">
          <div className="w-full">
            {' '}
            <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
          </div>
          <div className="w-full">
            {' '}
            <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
          </div>
          <div className="w-full">
            {' '}
            <Skeleton count={2} baseColor="#fff" highlightColor="#f1f1f1" />
          </div>
        </div>
      </>
    ) : (
      <>
        <div className="flex">
          <div className="h-[138px] w-[138px]">
            <img
              src={
                holding_proxy?.img?.[0] ??
                holding_proxy?.self_registration?.img?.[0] ??
                placeholderImg
              }
              alt={'product '}
              className="object-fit h-full w-full rounded-md border border-gray-300"
            ></img>
          </div>
          <div className="ml-5 flex flex-col justify-center">
            <p className="font-caudex pb-2.5 text-xl font-normal">
              {getProductTitleFromHoldingData(holding_proxy)}
            </p>
            <p className="font-nunito pb-2.5 text-base font-normal capitalize opacity-50">
              {getProductCategoryFromHoldingData(holding_proxy)}
            </p>
            <p className="font-nunito text-base font-normal">{storeAddress}</p>
          </div>
        </div>
        <div className="flex items-center justify-around gap-5 p-4">
          {t('tenantLink') !== 'SHELTA' && warranty?.templateTitle && (
            <div className="shrink-0 grow flex-col">
              <p className="mb-[10px] text-sm text-gray-500">
                {tenantType === 'tableTenants'
                  ? 'Service plan type'
                  : 'Warranty type'}
              </p>
              <p className="font-caudex text-2xl font-normal">
                {warranty?.templateTitle}
              </p>
            </div>
          )}
          <div className="shrink-0 grow flex-col">
            <p className="mb-[10px] text-sm text-gray-500">
              {tenantType === 'tableTenants'
                ? 'Service plan term'
                : 'Warranty term'}
            </p>
            <p className="font-caudex text-2xl font-normal">
              {getWarrantyTerm(holding_proxy)}
            </p>
          </div>
          <div className="shrink-0 grow flex-col">
            <p className="mb-[10px] text-sm text-gray-500">Expiry date</p>
            <p className="font-caudex text-2xl font-normal">
              {warranty?.endDate
                ? formatDate(new Date(warranty?.endDate))
                : (getExpiryDate(
                    'string',
                    holdingId,
                    allHoldingsList
                  ) as string) ?? ''}
            </p>
          </div>
          <div className="shrink-0 grow flex-col">
            <p className="mb-[10px] text-sm text-gray-500">
              {tenantType === 'tableTenants'
                ? 'Term remaining'
                : 'Warranty remaining'}
            </p>
            <p className="font-caudex text-2xl font-normal">
              {holding_proxy
                ? renderWarrantyRemaining(holding_proxy, false)
                : getWarrantyRemaining(holdingId, allHoldingsList)}
            </p>
          </div>
        </div>
      </>
    )}
  </div>
);

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

const getWarrantyRemaining = (
  holdingId: string,
  holdingsList: DTHoldingProxy[]
) => {
  const holding = holdingsList.filter(
    (holding: any) => holding.docid === holdingId
  )[0];
  const expiryDate = getExpiryDate('number', holdingId, holdingsList);
  if (!expiryDate || typeof expiryDate !== 'number' || expiryDate <= 0)
    return '-';
  const warranty = BL_Warranty.getLatestWarranty(holding?.warranties);
  const monthsLeft = calcWarrantyTimeLeft(
    expiryDate,
    warranty?.startDate ?? OurDateTime.nowUTCTimestamp()
  );

  if (monthsLeft && monthsLeft?.amount && monthsLeft?.amount > 0) {
    return monthsLeft?.amount + ' ' + monthsLeft?.unit;
  } else {
    return 'Warranty expired';
  }
};
