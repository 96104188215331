import Skeleton from 'react-loading-skeleton';
import CardWrapperWithHeader from '../../../molecules/CardWrapperWithHeader/CardWrapperWithHeader';
import { useEffect, useState } from 'react';

export interface LoadingCardProps {
  title: string;
  rows?: number;
  skeletonRows?: number;
}

export function LoadingCard({
  title,
  rows = 2,
  skeletonRows = 2,
}: LoadingCardProps) {
  // detect window size
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <CardWrapperWithHeader title={title}>
      <div>
        <div className="font-nunito mb-[30px] grid grid-cols-2 gap-8 md:grid-cols-5 lg:grid-cols-5">
          {width < 768
            ? Array.from(Array(rows * 2)).map((row) => (
                <div className="mb-10">
                  <Skeleton count={skeletonRows} />
                </div>
              ))
            : Array.from(Array(rows * 5)).map((row) => (
                <div className="mb-10">
                  <Skeleton count={skeletonRows} />
                </div>
              ))}
        </div>
      </div>
    </CardWrapperWithHeader>
  );
}

export default LoadingCard;
