/* A list of types and interfaces for caseflow facts, stations, etc.
 *
 * Not currently used for the most part, but listing them here both provides documentation for the
 * actual shape of the data used in facts and allows them to be used right away once support for them is added.
 *
 * The CFCF prefix stands for CaseFlow Case Fact.
 * So things don't end up all over the place when it comes to casing and naming conventions again, I'm now deciding that all CFCF types
 * will have their object keys using snake_case, while the interfaces themselves are in CamelCase save for the prefix.
 *
 * A WIP. Some of these might end up being common to all caseflow configurations, and should be moved to a shared location.
 */

/* -------------------------------------------------------------------------- */
/*                                  Stations                                  */
/* -------------------------------------------------------------------------- */

export enum CaseFlowStations_Shelta {
  BIRTH = '_birth',
  INITIATE = 'initiate',
  PRELIMINARY_ASSESSMENT = 'preliminary_assessment',
  INITIALLY_ASSESSED = 'initially_assessed',
  INBOUND_POST = 'inbound_post',
  CONFIRM_QUOTATION_RESPONSE = 'confirm_quotation_response',
  FINAL_ASSESSMENT = 'final_assessment',
  REPAIR = 'repair',
  THIRD_PARTY_REPAIR = 'third_party_repair',
  PENDING_OUTBOUND = 'pending_outbound',
  OUTBOUND = 'outbound',
  CLOSED = 'closed',
}

/* -------------------------------------------------------------------------- */
/*                                    Facts                                   */
/* -------------------------------------------------------------------------- */
export interface CFCF_RepairerContactDetails {
  contact_number: string;
  repairer_email: string;
}
