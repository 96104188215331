import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  Button,
  Input,
  LoadingSpinner,
  mockVendableCategoryList,
  UploadFilesForm,
} from '@rabbit/elements/shared-components';
import {
  LIST_COUNTRIES,
  VendableEditorContext,
  useSageAPI,
} from '@rabbit/bizproc/react';
import { useGetMySagePersonas } from '@rabbit/data/portal';
import { useAppInfo } from 'apps/sage/src/utils/helpers';
import { AddEditVendableFormValuesShape } from '@rabbit/elements/shared-types';
import {
  DTVendable,
  DTVendable_History,
  DTVendable_Private,
} from '@rabbit/data/types';

export interface ModalAddEditVendableProps {
  handleClose: () => void;
  handleDelete?: () => void;
  vendableId?: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export function ModalAddEditVendable({
  handleClose,
  handleDelete,
  vendableId,
  setLoading,
}: ModalAddEditVendableProps) {
  const appInfo = useAppInfo();
  const persona = useGetMySagePersonas();
  const formikRef = useRef<FormikProps<AddEditVendableFormValuesShape> | null>(
    null
  );
  const isNewVendable = !vendableId;
  const contextValues = useContext(VendableEditorContext);
  const { createOrUpdateVendable, getSingleVendable } = contextValues;
  // TODO: Vendable history data has arrays of private and public data, look for the ones with the highest version number and use those the values in this component
  const [vendableHistoryData, setVendableHistoryData] =
    useState<DTVendable_History | null>(null);

  useEffect(() => {
    if (!vendableId) return;
    getSingleVendable(vendableId).then((data) => {
      setVendableHistoryData(data);
    });
  }, [vendableId]);

  const onSubmit = async (values: AddEditVendableFormValuesShape) => {
    console.log('values', values);
    const pubVendable: Partial<DTVendable> = {
      title: values.title,
      full: values.titleFull,
      brand: values.brand,
      //img: values.vendableImages, // just the array of URLS
      //images: values.vendableImages, // array of UploadedImages
      upc: values.upc,
      mpn: values.mpn,
      mfr: 'M:SHELTA', // TODO: Add manufacturer link
      category: values.categories, // TODO: integrate category system
      keywords: values.keywords,
      defaultTemplate: 'SHELTA_DEFAULT',
      // defaultTemplate: values.warrantyTemplate,
      // manual: [values.manual], array of urls
      deprecated: values.active, // TODO review key name (active is probably better than deprecated)
    };

    const privVendable: Partial<DTVendable_Private> = {
      mfr: 'M:SHELTA', // TODO: Add manufacturer link
      rrp: values.rrp,
      deprecated: values.active, // TODO review key name (active is probably better than deprecated)
    };

    if (createOrUpdateVendable)
      try {
        const res = await createOrUpdateVendable(pubVendable, privVendable);
        console.log('res', res);
      } catch (err) {
        console.error(err);
        toast.error(
          `Something went wrong while ${
            isNewVendable ? 'creating' : 'editing'
          } the product! Please try again later.`
        );
        setLoading(false);
      }
  };
  /* -------------------------------------------------------------------------- */
  /*                                 Form setup                                 */
  /* -------------------------------------------------------------------------- */

  // These are the fields we will be going with for the editor. For non-Shopify based tenants, they will all be editable
  // For Shopify based ones, however, some will be read-only, and some will be editable

  const schema = Yup.object({
    title: Yup.string().required('Please enter a title'),
    titleFull: Yup.string(),
    brand: Yup.string().required('Please enter a brand'),
    upc: Yup.string().required('Please enter upc'),
    mpn: Yup.string().required('Required'),
    categories: Yup.array().required('Required'),
    keywords: Yup.array(),
    warrantyTemplate: Yup.string().required('Required'),
    rrp: Yup.object({
      amount: Yup.number().required('Required'),
      currency: Yup.string().required('Required'),
    }),
    manual: Yup.array(),
    vendableImages: Yup.array().required('Required').min(1),
    active: Yup.boolean(),
  });

  // TODO: Fill in with initial values from vendable_history
  const initialValues: AddEditVendableFormValuesShape = {
    title: '',
    titleFull: '',
    brand: '',
    upc: '',
    mpn: '',
    categories: [],
    keywords: [],
    warrantyTemplate: '',
    rrp: { amount: 0, currency: 'AUD' },
    manual: [],
    vendableImages: [],
    active: false,
  };

  // TODO: Add in remaining inputs
  return (
    <div>
      <Formik
        innerRef={formikRef}
        //todo: reenable validation
        // validationSchema={schema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props) => (
          <Form>
            <div className="mt-4 flex gap-4 px-5 pb-2">
              <div className="flex-1">
                <Input
                  type="text"
                  label="Product name*"
                  name="title"
                  settings={{
                    hint: '*required',
                  }}
                />
              </div>
              <div className="flex-1">
                <Input
                  type="select"
                  label="Display category"
                  name="categories"
                  settings={{
                    isMulti: true,
                    options: mockVendableCategoryList,
                    placeholder: 'Select',
                    id: 'categories',
                    errors: props.errors,
                  }}
                />
              </div>
            </div>
            <div className="py-2 px-5">
              <Input
                type="select"
                label="Search keywords"
                name="keywords"
                settings={{
                  options: options,
                  placeholder: 'Select',
                  id: 'parts',
                  isMulti: true,
                  errors: props.errors,
                }}
              />
            </div>
            <div className="flex gap-4 py-2 px-5">
              <div className="flex-1">
                <Input
                  type="text"
                  label="Brand*"
                  name="brand"
                  settings={{
                    hint: '*required',
                  }}
                />
              </div>
              <div className="flex-1">
                <Input
                  type="currency"
                  label={'Price'}
                  name="rrp"
                  settings={{
                    id: 'rrp',
                    currency: props?.values?.rrp?.currency ?? appInfo.currency,
                  }}
                />
              </div>
            </div>

            <div className="py-2 px-5">
              <Input
                type="text"
                label="UPC*"
                name="upc"
                settings={{
                  hint: '*required',
                }}
              />
            </div>
            {/* Reject divception, return to className */}
            <Input
              className="flex w-full flex-col py-2 px-5"
              type="text"
              label="MPN / SKU*"
              name="mpn"
              settings={{
                hint: '*required',
              }}
            />

            <div className="px-5 pb-2">
              {/* this won't work right now */}
              {/* <UploadFilesForm
                category="vendable_images"
                label="Product image"
                type="vendable"
                docId={vendableId ?? ''}
                personaId={persona.manufacturerPersona?.personaId ?? ''}
                currentFiles={body?.public?.manual ?? []}
                //@ts-ignore review to remove this ts-ignore
                onFilesUploaded={onUserManualsUpdated}
              />
            </div>
            <div className="px-5 pb-2">
              <UploadFilesForm
                category="vendable_manuals"
                label="User Manuals"
                type="vendable"
                docId={vendableId ?? ''}
                personaId={persona.manufacturerPersona?.personaId ?? ''}
                currentFiles={body?.public?.manual ?? []}
                //@ts-ignore review to remove this ts-ignore
                onFilesUploaded={onUserManualsUpdated}
              /> */}
            </div>
            <div className="py-2 px-5">
              <Input
                type="textarea"
                label="Details"
                name="detail"
                settings={{}}
              />
            </div>

            <div className="flex gap-5 py-4 px-5">
              <div>
                <Button
                  kind="primary"
                  type="submit"
                  size="sm"
                  children="Save all"
                  disabled={Object.keys(props.errors).length > 0}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );

  /* -------------------------------------------------------------------------- */
  /*                          OLD COMPONENT STARTS HERE                         */
  /* -------------------------------------------------------------------------- */

  // Remove when no longer useful as a reference

  // useEffect(() => {
  //   if (!body?.public?.manual) return;
  //   if (formikRef)
  //     formikRef.current?.setFieldValue(
  //       'vendable_manuals',
  //       body?.public?.manual
  //     );
  // }, [body?.public?.manual]);

  // useEffect(() => {
  //   if (!body?.public?.images) return;
  //   if (formikRef)
  //     formikRef.current?.setFieldValue('vendable_images', body?.public?.images);
  // }, [body?.public?.images]);

  // if (!body?.private || !body?.public)
  //   return (
  //     <div className="py-4">
  //       <LoadingSpinner size={'xs'} />
  //     </div>
  // //   );

  // // Form setup
  // const schema = Yup.object({
  //   title: Yup.string().required('Please enter a title'),
  //   brand: Yup.string().required('Please enter a brand'),
  //   upc: Yup.string().required('Please enter upc'),
  //   mpn: Yup.string().required('Required'),
  //   categories: Yup.array().required('Required'),
  //   keywords: Yup.array(),
  //   manufactured_country: Yup.string(),
  //   // parts: import.meta.env.VITE_DEMO
  //   //   ? Yup.array().required('Required')
  //   //   : Yup.array(),
  //   rrp: Yup.object({
  //     amount: Yup.number().required('Required'),
  //     currency: Yup.string().required('Required'),
  //   }),
  //   // faults: import.meta.env.VITE_DEMO
  //   //   ? Yup.array().required('Required')
  //   //   : Yup.array(),
  //   manual: Yup.array(),
  //   detail: Yup.string(),
  //   // vendable_images: Yup.array().required('Required').min(1),
  // });

  // const initialValues: VendableFormDataShape = {
  //   title: body?.public && body?.public.title ? body?.public.title : '',
  //   // categories:
  //   //   body?.public && body.public.categories
  //   //     ? handleStringArrAsObjArr(body?.public.categories)
  //   //     : [],
  //   // keywords:
  //   //   body?.public && body.public.keywords
  //   //     ? handleStringArrAsObjArr(body.public.keywords)
  //   //     : [],
  //   brand: body?.public && body.public.brand ? body.public.brand : '',
  //   // rrp: body?.private.rrp
  //   //   ? {
  //   //       amount: body.private.rrp.amount,
  //   //       currency: import.meta.env.VITE_DEFAULT_COUNTRY ?? 'AUD',
  //   //     }
  //   //   : {
  //   //       amount: 0,
  //   //       currency: import.meta.env.VITE_DEFAULT_COUNTRY ?? 'AUD',
  //   //     },
  //   manufactured_country:
  //     body?.private && body?.private.manufactured_country
  //       ? body?.private.manufactured_country
  //       : '',
  //   manual: body?.public && body.public.manual ? body.public.manual : [],
  //   // parts:
  //   //   body?.private && body.private.parts
  //   //     ? handleStringArrAsObjArr(body.private.parts)
  //   //     : [],
  //   // faults:
  //   //   body?.private && body.private.faults
  //   //     ? handleStringArrAsObjArr(body.private.faults)
  //   //     : [],
  //   detail: body?.public && body.public.detail ? body.public.detail : '',
  //   vendable_images:
  //     body?.public && body.public.images ? body.public.images : [],
  //   upc: body?.public && body.public.upc ? body.public.upc : '',
  // };

  // const onUserManualsUpdated = async (files: UserUploadedDocument) => {
  //   //console.log(files);
  // };

  // const onImagesUpdated = async (files: UserUploadedDocument) => {
  //   //console.log(files);
  // };

  // const onSubmit = async (values: VendableFormDataShape) => {
  //   setLoading(true);
  //   try {
  //     await commitVendable(values);
  //     toast.success(
  //       `Product ${isNewVendable ? 'registered' : 'edited'} successfully.`
  //     );

  //     handleClose();
  //     setLoading(false);
  //   } catch (err) {
  //     console.log('err', err);
  //     toast.error(
  //       `Something went wrong while ${
  //         isNewVendable ? 'creating' : 'editing'
  //       } the product! Please try again later.`
  //     );
  //     setLoading(false);
  //   }
  // };

  // return (
  //   <div>
  //     <Formik
  //       innerRef={formikRef}
  //       validationSchema={schema}
  //       initialValues={initialValues}
  //       onSubmit={onSubmit}
  //       validateOnChange={false}
  //       validateOnBlur={false}
  //     >
  //       {(props) => (
  //         <Form>
  //           <div className="mt-4 flex gap-4 px-5 pb-2">
  //             <div className="flex-1">
  //               <Input
  //                 type="text"
  //                 label="Product name*"
  //                 name="title"
  //                 settings={{
  //                   hint: '*required',
  //                 }}
  //               />
  //             </div>
  //             <div className="flex-1">
  //               <Input
  //                 type="select"
  //                 label="Display category"
  //                 name="categories"
  //                 settings={{
  //                   isMulti: true,
  //                   options: mockVendableCategoryList,
  //                   placeholder: 'Select',
  //                   id: 'categories',
  //                   errors: props.errors,
  //                 }}
  //               />
  //             </div>
  //           </div>
  //           <div className="py-2 px-5">
  //             <Input
  //               type="select"
  //               label="Search keywords"
  //               name="keywords"
  //               settings={{
  //                 options: options,
  //                 placeholder: 'Select',
  //                 id: 'parts',
  //                 isMulti: true,
  //                 errors: props.errors,
  //               }}
  //             />
  //           </div>
  //           <div className="flex gap-4 py-2 px-5">
  //             <div className="flex-1">
  //               <Input
  //                 type="text"
  //                 label="Brand*"
  //                 name="brand"
  //                 settings={{
  //                   hint: '*required',
  //                 }}
  //               />
  //             </div>
  //             <div className="flex-1">
  //               <Input
  //                 type="currency"
  //                 label={'Price'}
  //                 name="rrp"
  //                 settings={{
  //                   id: 'rrp',
  //                   currency: props?.values?.rrp?.currency ?? appInfo.currency,
  //                 }}
  //               />
  //             </div>
  //           </div>
  //           <div className="py-2 px-5">
  //             <Input
  //               type="select"
  //               label="Manufactured in"
  //               name="manufactured_country"
  //               settings={{
  //                 options: LIST_COUNTRIES,
  //                 placeholder: 'Select',
  //                 id: 'country',
  //                 errors: props.errors,
  //               }}
  //             />
  //           </div>
  //           {isDemoEnv ? (
  //             <>
  //               <div className="py-2 px-5">
  //                 <Input
  //                   type="select"
  //                   label="Parts"
  //                   name="parts"
  //                   settings={{
  //                     options: options,
  //                     placeholder: 'Select',
  //                     id: 'parts',
  //                     isMulti: true,
  //                     errors: props.errors,
  //                   }}
  //                 />
  //               </div>
  //               <div className="py-2 px-5">
  //                 <Input
  //                   type="select"
  //                   label="Faults"
  //                   name="faults"
  //                   settings={{
  //                     options: options,
  //                     placeholder: 'Select',
  //                     id: 'parts',
  //                     isMulti: true,
  //                     errors: props.errors,
  //                   }}
  //                 />
  //               </div>
  //             </>
  //           ) : null}
  //           <div className="py-2 px-5">
  //             <Input
  //               type="text"
  //               label="UPC*"
  //               name="upc"
  //               settings={{
  //                 hint: '*required',
  //               }}
  //             />
  //           </div>
  //           {/* Reject divception, return to className */}
  //           <Input
  //             className="flex w-full flex-col py-2 px-5"
  //             type="text"
  //             label="MPN / SKU*"
  //             name="mpn"
  //             settings={{
  //               hint: '*required',
  //             }}
  //           />
  //           <Input
  //             className="flex w-full flex-col py-2 px-5"
  //             type="text"
  //             label="Dimensions (in mm)"
  //             name="dimensions"
  //             settings={{}}
  //           />
  //           <Input
  //             className="flex w-full flex-col py-2 px-5"
  //             type="text"
  //             label="Weight (in g)"
  //             name="weight"
  //             settings={{}}
  //           />
  //           <div className="px-5 pb-2">
  //             {/* this won't work right now */}
  //             <UploadFilesForm
  //               category="vendable_images"
  //               label="Product image"
  //               type="vendable"
  //               docId={vendableId ?? ''}
  //               personaId={persona.manufacturerPersona?.personaId ?? ''}
  //               currentFiles={body?.public?.manual ?? []}
  //               //@ts-ignore review to remove this ts-ignore
  //               onFilesUploaded={onUserManualsUpdated}
  //             />
  //           </div>
  //           <div className="px-5 pb-2">
  //             <UploadFilesForm
  //               category="vendable_manuals"
  //               label="User Manuals"
  //               type="vendable"
  //               docId={vendableId ?? ''}
  //               personaId={persona.manufacturerPersona?.personaId ?? ''}
  //               currentFiles={body?.public?.manual ?? []}
  //               //@ts-ignore review to remove this ts-ignore
  //               onFilesUploaded={onUserManualsUpdated}
  //             />
  //           </div>
  //           <div className="py-2 px-5">
  //             <Input
  //               type="textarea"
  //               label="Details"
  //               name="detail"
  //               settings={{}}
  //             />
  //           </div>

  //           <div className="flex gap-5 py-4 px-5">
  //             <div>
  //               <Button
  //                 kind="primary"
  //                 type="submit"
  //                 size="sm"
  //                 children="Save all"
  //                 disabled={Object.keys(props.errors).length > 0}
  //               />
  //             </div>
  //           </div>
  //         </Form>
  //       )}
  //     </Formik>
  //   </div>
  // );
}

const options = [
  {
    id: '1',
    value: 'Option 1',
    label: 'Option 1',
  },
  {
    id: '2',
    value: 'Option 2',
    label: 'Option 2',
  },
  {
    id: '3',
    value: 'Option 3',
    label: 'Option 3',
  },
  {
    id: '4',
    value: 'Option 4',
    label: 'Option 4',
  },
];
